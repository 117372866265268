import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FormApi } from "final-form";
import { useAppDispatch } from "../../app/hooks";
import { IUserDto, RoutesEnum, userRoles } from "../shared";
import { showError, showSuccessAlert } from "../store/reducers";
import { userApi } from "../store/services";
import { useNavigate } from "react-router-dom";

export const useHandleRegisterUser = (
  setIsRegistrationFormOpen: (isOpen: boolean) => void,
  useRouting: boolean = false
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [handleCreateUser] = userApi.useCreateUserMutation();
  const [handleCreatePatient] = userApi.useCreatePatientMutation();

  return useCallback(
    async (value: IUserDto, form: FormApi<IUserDto>) => {
      try {
        const formData = new FormData();

        const fields = [
          "firstName",
          "lastName",
          "phoneNumber",
          "idCode",
          "city",
          "street",
          "role",
          "language",
        ];
        fields.forEach((field) =>
          formData.append(field, String(value[field as keyof IUserDto]) || "")
        );
        if (value.email) {
          formData.append("email", value.email);
        }
        if (value.image) {
          formData.append("photo", (value.image as File[])[0]);
        }

        if (value.role === userRoles.patient) {
          await handleCreatePatient(formData).unwrap();
        } else {
          await handleCreateUser(formData).unwrap();
        }

        setIsRegistrationFormOpen(false);
        form.restart();

        if (useRouting) {
          const roleRoutes = {
            [userRoles.doctor]: `${RoutesEnum.admin}${RoutesEnum.doctors}`,
            [userRoles.patient]: `${RoutesEnum.admin}${RoutesEnum.patients}`,
            [userRoles.rehabilitator]: `${RoutesEnum.admin}${RoutesEnum.rehabilitators}`,
          };

          if (roleRoutes[value.role as string]) {
            navigate(roleRoutes[value.role as string]);
          }
          dispatch(showSuccessAlert(t("alert.successfulRegisterUser")));
        }
      } catch (error: any) {
        console.log(error);

        dispatch(
          showError(
            t([
              `alert.errors.${error.data.message}`,
              `alert.errors.${error.data.response?.message}`,
              `alert.errors.${error.data.response?.response?.message}`,
              `alert.somethingWentWrong`,
            ])
          )
        );
      }
    },
    [
      dispatch,
      handleCreateUser,
      navigate,
      setIsRegistrationFormOpen,
      t,
      useRouting,
    ]
  );
};

import { edit_pencil } from "@jat-med/assets";
import React, { FC } from "react";
import styles from "./editButton.module.scss";
import { IEditButton } from "./editButton.interface";
import { useTranslation } from "react-i18next";

export const EditButton: FC<IEditButton> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <button
      title={t("buttons.edit")}
      className={styles.edit_button}
      onClick={onClick}
    >
      <img src={edit_pencil} alt="edit_btn" />
    </button>
  );
};

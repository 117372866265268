import { useDebounceCallback } from "@react-hook/debounce";
import { FC, useEffect, useState } from "react";
import styles from "./scrollToTop.module.scss";

export const ScrollToTop: FC = () => {
  const circumference = 16 * 2 * Math.PI;
  const [strokeDashoffset, setStrokeDashoffset] = useState(0);
  const [show, setShow] = useState(false);

  const onScroll = () => {
    let winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    let height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    winScroll > document.documentElement.clientHeight * 2
      ? setShow(true)
      : setShow(false);

    const percent = Math.round((winScroll / height) * 100);
    setStrokeDashoffset(circumference - (percent / 100) * circumference);
  };

  const debouncedOnScroll = useDebounceCallback(onScroll, 100);

  useEffect(() => {
    window.addEventListener("scroll", debouncedOnScroll);
    return () => window.removeEventListener("scroll", debouncedOnScroll);
  }, []);

  const onClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <button
      onClick={onClick}
      className={`${styles.container} ${show ? "" : styles.hidden}`}
    >
      <svg
        fill="#fff"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 330 330"
        xmlSpace="preserve"
        transform="rotate(270)"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {" "}
          <path
            id="XMLID_27_"
            d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255 s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0 c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
          ></path>{" "}
        </g>
      </svg>
    </button>
  );
};

import React from "react";
import styles from "./styles.module.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
export const PersonalDataProcessing = () => {
  const { t } = useTranslation();

  return (
    <article className={styles.article}>
      <h1>{t("PersonalDataProcessing.title")}</h1>
      <p>{t("PersonalDataProcessing.paragraph1")}</p>
      {i18next.resolvedLanguage === "pl" && (
        <ol>
          <li>
            Kto przetwarza Twoje dane osobowe?
            <ul>
              <li>
                Zgodnie z art. 13 ust. 1 oraz art. 14 ust. 1 Rozporządzenia
                Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
                2016 roku w sprawie ochrony osób fizycznych w związku z
                przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
                takich danych oraz uchylenia dyrektywy 95/46/WE (zwane dalej:
                RODO) informujemy, że:
              </li>
              <li>
                Administratorem Twoich danych osobowych jest Jat-med sp. z o.o.
                z siedzibą w Bydgoszczy przy ulicy Kruszyńska 17, wpisanej do
                rejestru przedsiębiorców Krajowego Rejestru Sądowego
                prowadzonego przez Sąd Rejonowy W Bydgoszczy, XIII Wydział
                Gospodarczy Krajowego Rejestru Sądowego, pod numerem KRS
                0001150821, NIP 9671483929, REGON 540725908 (zwana dalej
                Administratorem).
              </li>
            </ul>
          </li>
          <li>
            Jakie dane przetwarza Administrator?
            <br />W jakim celu Administrator przetwarza Twoje dane osobowe?
            <ul>
              <li>Twoje dane osobowe przetwarzamy:</li>
              <li>
                Jeśli jesteś Konsumentem albo Przedsiębiorcą indywidualnym,
                który zawiera umowę z Administratorem we własnym imieniu: na
                podstawie art. 6 ust. 1 lit. b) RODO – w celu zawarcia i
                wykonania umowy pomiędzy Tobą a Administratorem dotyczącej
                Produktów oferowanych przez Administratora na stronie serwisu{" "}
                <a href="https://jat-med.com">jat-med.com</a> i sklepu
                internetowego <a href="https://jat-med.com">jat-med.com</a>.
                Przetwarzanie danych w tym celu dotyczy też obsługi reklamacji i
                innych zgłoszeń dotyczących zawartych umów. Informacje o
                Produktach Administratora możesz znaleźć także w mediach
                społecznościowych Administratora, ale umowę możesz zawrzeć tylko
                przez stronę sklepu internetowego zgodnie z jej regulaminem;
              </li>
              <li>
                Jeśli umowy z Administratorem nie zawierasz dla siebie, lecz
                zawierasz umowę w imieniu innego Konsumenta albo Przedsiębiorcy
                indywidualnego albo Przedsiębiorcy, który jest osobą prawną bądź
                inną jednostką organizacyjną, której przepisy przyznają zdolność
                prawną: na podstawie art. 6 ust. 1 lit. f) RODO – w celu
                zawarcia i wykonania pomiędzy tym innym Konsumentem albo
                Przedsiębiorcą a Administratorem umowy dotyczącej Produktów
                oferowanych przez Administratora na stronie sklepu internetowego{" "}
                <a href="https//jat-med.com">jat-med.com</a>. Przetwarzanie
                danych w tym celu dotyczy też obsługi reklamacji i innych
                zgłoszeń dotyczących zawartych umów; prawnie usprawiedliwiony
                interes Administratora polega w tym przypadku na umożliwieniu
                zawarcia oraz należytego wykonania umowy, którą zawarł;
              </li>
              <li>
                Na podstawie art. 6 ust. 1 pkt c) RODO w związku z art. 74 ust.
                2 pkt 4 ustawy o rachunkowości – w celu realizacji przez
                Administratora obowiązku prowadzenia i przechowywania dokumentów
                księgowych;
              </li>
              <li>
                Na podstawie art. 6 ust. 1 pkt f) RODO – w prawnie
                usprawiedliwionym interesie Administratora polegającym na
                dochodzeniu przez Administratora roszczeń przeciwko Konsumentowi
                lub Przedsiębiorcy lub obrony przed roszczeniami;
              </li>
              <li>
                Na podstawie art. 6 ust. 1 pkt f) RODO – w prawnie
                usprawiedliwionym interesie Administratora polegającym na
                marketingu bezpośrednim własnych Produktów Administratora;
                marketing bezpośredni na tej podstawie Administrator będzie
                prowadził tylko drogą tradycyjną, czyli za pośrednictwem poczty
                papierowej lub wręczając Tobie osobiście materiały marketingowe;
              </li>
              <li>
                Na podstawie art. 6 ust. 1 lit. a) czyli na podstawie Twojej
                zgody – w celu marketingowym Administratora, jeżeli wyraziłeś/aś
                odrębną zgodę na przetwarzanie Twoich danych osobowych w celu
                marketingowym.
              </li>
            </ul>
          </li>
          <li>
            Jak długo Administrator będzie przechowywał Twoje dane osobowe?
            <ul>
              <li>Dane osobowe będą przechowywane:</li>
              <li>
                W celu zawarcia i wykonania umowy przez Administratora – do
                czasu jej wykonania, rozwiązania lub zakończenia w inny sposób;
              </li>
              <li>
                W celu realizacji obowiązku prowadzenia i przechowywania
                dokumentów księgowych – przez okres 5 lat.
                <br />
                <br />
                <span>Wyjaśnianie:</span> 5 lat liczone jest od początku roku
                następującego po roku obrotowym, w którym operacje, transakcje i
                postępowanie zostały ostatecznie zakończone, spłacone,
                rozliczone lub przedawnione;
              </li>

              <li>
                W celu dochodzenia roszczeń lub obrony przed nimi – do czasu, aż
                te roszczenia się przedawnią;
              </li>
              <li>
                W celu prowadzenia marketingu bezpośredniego w ramach prawnie
                usprawiedliwionego interesu – do czasu aż wniesiesz sprzeciw
                wobec przetwarzania Twoich danych w tym celu;
              </li>
              <li>
                W celu marketingowym Administratora, na podstawie Twojej
                odrębnej zgody – do czasu aż cofniesz swoją zgodę lub
                Administrator (bądź jego następcy prawni) zakończy wykonywanie
                działalności gospodarczej (likwidacja).
              </li>
            </ul>
          </li>
          <li>
            Komu Administrator może przekazać Twoje dane osobowe (kto może być
            odbiorcą Twoich danych)?
            <ul>
              <li>
                Administrator w celu zawarcia, realizacji lub pozostałej obsługi
                umowy zawartej pomiędzy Tobą a Administratorem przekazuje Twoje
                dane firmom kurierskim, firmie obsługującej oprogramowanie
                sklepu internetowego, firmie księgowej obsługującej
                Administratora, hurtowniom, magazynom, centrom logistycznym
                realizującym w imieniu Administratora czynności niezbędne do
                obsługi zamówień w sklepie internetowym Administratora, firmom
                realizującym na zlecenie Administratora czynności marketingowe
                (gdy dotyczy). Ponadto Administrator ma obowiązek przekazywania
                Twoich danych osobowych na podstawie bezwzględnie obowiązujących
                przepisów prawa, w tym na żądanie uprawnionych sądów, organów
                lub innych instytucji.
              </li>
            </ul>
          </li>
          <li>
            Jakie przysługują Tobie prawa w związku z Twoimi danymi osobowymi?
            <ul>
              <li>
                Z zastrzeżeniem ograniczeń wynikających z RODO i innych
                przepisów prawa, posiadasz prawo:
              </li>
              <li>Dostępu do treści Twoich danych;</li>
              <li>
                Do uzyskania następujących informacji: o celach przetwarzania
                Twoich danych osobowych; o kategoriach danych osobowych
                przetwarzanych przez Administratora; o odbiorcach lub
                kategoriach odbiorców, którym Administrator ujawnił Twoje dane
                osobowe lub którym ma zamiar ujawnić te dane; o możliwości
                skorzystania przez Ciebie z praw w zakresie ochrony danych
                osobowych, a także sposobu w jaki może nastąpić realizacja
                takich praw; o prawie do wniesienia skargi do organu
                nadzorczego;
              </li>
              <li>
                Żądania sprostowania danych osobowych które są nieprawidłowe
                oraz do żądania uzupełnienia niekompletnych danych osobowych;
              </li>
              <li>
                Żądania usunięcia danych osobowych: jeżeli dane osobowe nie są
                już niezbędne do celów, dla których zostały zebrane lub są
                przetwarzane albo cofnąłeś/aś zgodę, na której opiera się
                przetwarzanie i Administrator nie ma innej podstawy prawnej
                przetwarzania Twoich danych osobowych; dane osobowe były
                przetwarzane niezgodnie z prawem; dane osobowe muszą zostać
                usunięte w celu wywiązania się z obowiązku prawnego. Nie jest
                możliwa realizacja prawa do usunięcia danych osobowych, w
                przypadku gdy Administrator jest zobowiązany na podstawie
                przepisów prawa do dalszego przetwarzania danych osobowych w
                zakresie określonym przez odpowiednie przepisy prawa lub w
                celach niezbędnych do ustalenia, dochodzenia lub obrony roszczeń
                przez Administratora;
              </li>
              <li>
                Żądania ograniczenia przetwarzania danych osobowych jeśli
                kwestionujesz prawidłowość danych osobowych przetwarzanych przez
                Administratora – na okres umożliwiający sprawdzenie przez
                Administratora prawidłowości tych danych; przetwarzanie Twoich
                danych osobowych jest niezgodne z prawem, a sprzeciwiłaś/eś się
                usunięciu Twoich danych osobowych, żądając w zamian ograniczenia
                ich wykorzystania; gdy Administrator nie potrzebuje już Twoich
                danych osobowych do celów przetwarzania, ale są one Tobie
                potrzebne do ustalenia, dochodzenia lub obrony roszczeń. W
                przypadku realizacji prawa do ograniczenia przetwarzania danych
                osobowych, Administrator może przetwarzać Twoje dane osobowe, z
                wyjątkiem ich przechowywania, wyłącznie za Twoją zgodą lub w
                celu ustalenia, dochodzenia lub obrony roszczeń lub w celu
                ochrony praw innej osoby fizycznej lub prawnej lub z uwagi na
                ważne względy interesu publicznego Unii Europejskiej lub państwa
                członkowskiego;
              </li>
              <li>
                Do przenoszenia danych, które polega na tym, że właściciel
                danych jest uprawniony do otrzymania w ustrukturyzowanym,
                powszechnie używanym formacie nadającym się do odczytu
                maszynowego danych osobowych dostarczonych Administratorowi oraz
                przesłania tych danych osobowych innemu administratorowi danych,
                jeśli jest to technicznie możliwe;
              </li>
              <li>
                Wniesienia sprzeciwu od przetwarzania danych w prawnie
                uzasadnionym interesie Administratora. Administrator nie może
                dalej przetwarzać Twoich danych osobowych po zgłoszeniu
                sprzeciwu, chyba że istnieją ważne prawnie uzasadnione podstawy
                do przetwarzania danych osobowych, które są nadrzędne wobec
                Twoich interesów, praw i wolności lub w celu ustalenia,
                dochodzenia lub obrony roszczeń. Jeśli złożysz sprzeciw wobec
                przetwarzania Twoich danych w prawnie usprawiedliwionym
                interesie polegającym na prowadzeniu marketingu bezpośredniego,
                to Administrator ma obowiązek zaprzestania przetwarzania Twoich
                danych w tym celu. Prawo do zgłoszenia sprzeciwu wobec
                przetwarzania danych nie przysługuje w przypadku, gdy:
                przetwarzanie danych osobowych odbywa się na podstawie zgody
                albo przetwarzanie danych jest niezbędne do wykonania umowy,
                której jesteś stroną lub do podjęcia działań na Twoje żądanie
                przed zawarciem umowy z Administratorem, albo jeśli
                przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego
                ciążącego na Administratorze.
              </li>
              <li>
                W przypadku, gdy przetwarzanie danych osobowych odbywa się na
                podstawie Twojej zgody, to masz prawo do cofnięcia zgody na
                przetwarzanie danych osobowych w dowolnym momencie, co pozostaje
                bez wpływu na zgodność z prawem przetwarzania, którego dokonano
                na podstawie zgody przed jej cofnięciem.
              </li>
              <li>
                Wniesienia skargi do organu nadzorczego, w szczególności w
                państwie członkowskim swojego zwykłego pobytu, swojego miejsca
                pracy lub miejsca popełnienia domniemanego naruszenia, gdy
                uznasz że przetwarzanie Twoich danych osobowych narusza przepisy
                RODO. Organem nadzorczym w Polsce jest Prezes Urzędu Ochrony
                Danych Osobowych z siedzibą w Warszawie.
              </li>
            </ul>
          </li>
          <li>
            Jak możesz skontaktować się z Administratorem?
            <ul>
              <li>
                W sprawach związanych z Twoimi danymi osobowymi, w tym w celu
                wykonania swoich praw związanych z Twoimi danymi osobowymi lub
                wycofania zgody możesz skontaktować się z Administratorem w
                następujący sposób:
              </li>
              <li>
                Pisemnie na adres:
                <ul>
                  <li>JAT-MED. Sp z o.o.</li>
                  <li>85-448 Bydgoszcz ul. Kruszyńska 17</li>
                </ul>
              </li>
              <li>
                Telefonicznie pod numerem telefonu –{" "}
                <a href="tel:+48518468678">+48 518 468 678</a>
              </li>
              <li>
                Drogą elektroniczną na adres:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                Jeśli jesteś Konsumentem albo Przedsiębiorcą indywidualnym,
                który zawiera umowę z Administratorem we własnym imieniu to
                samodzielnie podałeś/aś Administratorowi swoje dane osobowe.
              </li>
              <br />
              <span>
                Jeśli umowy z Administratorem nie zawierasz dla siebie, lecz
                zawierasz umowę w imieniu innego Konsumenta albo Przedsiębiorcy
                indywidualnego albo Przedsiębiorcy, który jest osobą prawną bądź
                inną jednostką organizacyjną, której przepisy przyznają zdolność
                prawną, to prawdopodobnie dane osobowe podałeś samodzielnie przy
                składaniu zamówienia. Może się jednak zdarzyć, że Twoje dane
                podał Administratorowi Konsument lub Przedsiębiorca w imieniu,
                którego działasz w związku z umową.
              </span>
            </ul>
          </li>
          <li>
            Czy podanie przez Ciebie danych jest konieczne?
            <ul>
              <li>
                Podanie przez Ciebie danych osobowych jest dobrowolne, jednakże
                ich niepodanie uniemożliwi Administratorowi zawarcie lub
                wykonanie umowy dotyczącej Produktów.
              </li>
              <br />
              <span>
                W przypadku przetwarzania Twoich danych osobowych w celu
                marketingowym na podstawie Twojej odrębnej zgody podanie tych
                danych przez Ciebie jest także całkowicie dobrowolne, ale jeśli
                nie podasz Administratorowi tych danych i nie wyrazisz zgody na
                ich przetwarzanie w celu marketingowym, to nie będziesz
                otrzymywać od Administratora wiadomości o promocjach, nowych
                produktach oraz innych akcjach reklamowych Administratora.
              </span>
            </ul>
          </li>
        </ol>
      )}
      {i18next.resolvedLanguage === "en" && (
        <ol>
          <li>
            Who processes your persxonal data?
            <ul>
              <li>
                In accordance with art. 13 sec. 1 and art. 14 sec. 1 of
                Regulation (EU) 2016/679 of the European Parliament and of the
                Council of 27 April 2016 on the protection of natural persons
                with regard to the processing of personal data and on the free
                movement of such data, and repealing Directive 95/46/EC
                (hereinafter referred to as: GDPR), we inform you that:
              </li>
              <li>
                The administrator of your personal data is Jat-med sp. z o.o.
                with its registered office in Bydgoszcz at 17 Kruszyńska Street,
                entered into the register of entrepreneurs of the National Court
                Register maintained by the District Court in BYDGOSZCZ, XIII
                COMMERCIAL DIVISION OF THE NATIONAL COURT REGISTER, under KRS
                number 0001150821, NIP 9671483929, REGON 540725908 (hereinafter
                referred to as the Administrator).
              </li>
            </ul>
          </li>
          <li>
            What data does the Administrator process?
            <br />
            For what purpose does the Administrator process your personal data?
            <ul>
              <li>We process your personal data:</li>
              <li>
                If you are a Consumer or an individual Entrepreneur who
                concludes an agreement with the Administrator on their own
                behalf: pursuant to art. 6 sec. 1 lit. b) GDPR – in order to
                conclude and perform a contract between you and the
                Administrator concerning Products offered by the Administrator
                on the website <a href="https://jat-med.com">jat-med.com</a> and
                the online store <a href="https://jat-med.com">jat-med.com</a>.
                Data processing for this purpose also applies to handling
                complaints and other notifications concerning concluded
                contracts. You can also find information about the
                Administrator's Products on the Administrator's social media,
                but you can only conclude a contract through the online store
                website in accordance with its regulations;
              </li>
              <li>
                If you do not conclude a contract with the Administrator for
                yourself, but you conclude a contract on behalf of another
                Consumer or an individual Entrepreneur or an Entrepreneur who is
                a legal person or other organizational unit to which the
                provisions grant legal capacity: on the basis of art. 6 sec. 1
                letter f) GDPR – in order to conclude and perform a contract
                between this other Consumer or Entrepreneur and the
                Administrator concerning Products offered by the Administrator
                on the website <a href="https://jat-med.com">jat-med.com</a>.
                Data processing for this purpose also applies to handling
                complaints and other notifications concerning concluded
                contracts; the legally justified interest of the Administrator
                in this case consists in enabling the conclusion and proper
                performance of the contract concluded by it;
              </li>
              <li>
                pursuant to art. 6 sec. 1 point c) of the GDPR in connection
                with art. 74 sec. 2 point 4 of the Accounting Act – in order for
                the Administrator to fulfil the obligation to keep and store
                accounting documents;
              </li>
              <li>
                pursuant to art. 6 sec. 1 point f) of the GDPR – in the legally
                justified interest of the Administrator consisting in the
                Administrator pursuing claims against the Consumer or
                Entrepreneur or defending against claims;
              </li>
              <li>
                pursuant to art. 6 sec. 1 point f) of the GDPR – in the legally
                justified interest of the Administrator consisting in direct
                marketing of its own Products Administrator; direct marketing on
                this basis will be conducted by the Administrator only in the
                traditional way, i.e. by paper mail or by delivering marketing
                materials to you in person;
              </li>
              <li>
                on the basis of art. 6 sec. 1 letter a), i.e. on the basis of
                your consent - for the marketing purposes of the Administrator,
                if you have given separate consent to the processing of your
                personal data for marketing purposes.
              </li>
            </ul>
          </li>
          <li>
            How long will the Administrator store your personal data?
            <ul>
              <li>Personal data will be stored:</li>
              <li>
                for the purpose of concluding and performing a contract by the
                Administrator - until it is performed, terminated or otherwise
                completed;
              </li>
              <li>
                for the purpose of fulfilling the obligation to maintain and
                store accounting documents - for a period of 5 years.
              </li>
              <li>
                Explanation: 5 years is counted from the beginning of the year
                following the financial year in which the operations,
                transactions and proceedings were finally completed, paid off,
                settled or time-barred;
              </li>
              <li>
                for the purpose of pursuing claims or defending against them -
                until such claims become time-barred;
              </li>
              <li>
                for the purpose of conducting direct marketing within the
                framework of legally justified interest – until you object to
                the processing of your data for this purpose;
              </li>
              <li>
                for the marketing purposes of the Administrator, based on your
                separate consent – ​​until you withdraw your consent or the
                Administrator (or its legal successors) terminates its business
                activity (liquidation).
              </li>
            </ul>
          </li>
          <li>
            To whom may the Administrator transfer your personal data (who may
            be the recipient of your data)?
            <ul>
              <li>
                In order to conclude, implement or otherwise service the
                contract concluded between you and the Administrator, the
                Administrator transfers your data to courier companies, a
                company servicing the software of the online store, an
                accounting firm servicing the Administrator, wholesalers,
                warehouses, logistics centers performing on behalf of the
                Administrator the activities necessary to process orders in the
                Administrator's online store, companies performing marketing
                activities on behalf of the Administrator (where applicable). In
                addition, the Administrator is obliged to transfer your personal
                data on the basis of mandatory provisions of law, including at
                the request of authorized courts, authorities or other
                institutions.
              </li>
            </ul>
          </li>
          <li>
            What rights do you have in connection with your personal data?
            <ul>
              <li>
                Subject to the limitations resulting from the GDPR and other
                legal provisions, you have the right to:
              </li>
              <li>access the content of your data;</li>
              <li>
                obtain the following information: about the purposes of
                processing your personal data; about the categories of personal
                data processed by the Administrator; about the recipients or
                categories of recipients to whom the Administrator has disclosed
                your personal data or to whom it intends to disclose this data;
                about the possibility of exercising your rights in the field of
                personal data protection, as well as the manner in which such
                rights may be exercised; about the right to lodge a complaint
                with a supervisory authority;
              </li>
              <li>
                request the rectification of personal data that is incorrect and
                to request the completion of incomplete personal data;
              </li>
              <li>
                request the deletion of personal data: if the personal data are
                no longer necessary for the purposes for which they were
                collected or are processed or you have withdrawn the consent on
                which the processing is based and the Administrator has no other
                legal basis for processing your personal data; the personal data
                were processed unlawfully; personal data must be deleted in
                order to fulfill a legal obligation. It is not possible to
                exercise the right to delete personal data if the Administrator
                is obliged by law to continue processing personal data to the
                extent specified by relevant law or for the purposes necessary
                to establish, pursue or defend claims by the Administrator;
              </li>
              <li>
                request to limit the processing of personal data if you question
                the accuracy of the personal data processed by the Administrator
                - for a period enabling the Administrator to verify the accuracy
                of this data; the processing of your personal data is unlawful
                and you have objected to the deletion of your personal data,
                requesting instead to limit their use; when the Administrator no
                longer needs your personal data for the purposes of processing,
                but you need them to establish, pursue or defend claims. In the
                case of exercising the right to limit the processing of personal
                data, the Administrator may process your personal data, with the
                exception of their storage, only with your consent or for the
                purpose of establishing, pursuing or defending claims or for the
                protection of the rights of another natural or legal person or
                for important reasons of public interest of the European Union
                or a Member State;
              </li>
              <li>
                to transfer data, which means that the data owner is entitled to
                receive the personal data provided to the Administrator in a
                structured, commonly used, machine-readable format and to
                transmit this personal data to another data controller, if
                technically possible;
              </li>
              <li>
                to object to the processing of data in the legitimate interest
                of the Administrator. The Administrator may not continue to
                process your personal data after the objection has been filed,
                unless there are valid legitimate grounds for the processing of
                personal data that override your interests, rights and freedoms
                or for the purpose of establishing, pursuing or defending
                claims. If you file an objection to the processing of your data
                in the legitimate interest of conducting direct marketing, the
                Administrator is obliged to cease processing your data for this
                purpose. The right to object to the processing of data does not
                apply if: the processing of personal data takes place on the
                basis of consent or the processing of data is necessary for the
                performance of a contract to which you are a party or to take
                action at your request before entering into a contract with the
                Administrator, or if the processing is necessary to fulfill a
                legal obligation incumbent on the Administrator.
              </li>
              <li>
                in the event that the processing of personal data is based on
                your consent, you have the right to withdraw your consent to the
                processing of personal data at any time, which shall not affect
                the lawfulness of the processing carried out on the basis of
                consent before its withdrawal.
              </li>
              <li>
                to lodge a complaint with a supervisory authority, in particular
                in the Member State of your habitual residence, place of work or
                place of alleged infringement, if you consider that the
                processing of your personal data violates the provisions of the
                GDPR. The supervisory authority in Poland is the President of
                the Personal Data Protection Office based in Warsaw.
              </li>
            </ul>
          </li>
          <li>
            How can you contact the Administrator?
            <ul>
              <li>
                In matters related to your personal data, including the exercise
                of your rights related to your personal data or withdrawal of
                consent, you can contact the Administrator in the following
                manner:
              </li>
              <li>
                in writing to the following address:
                <ul>
                  <li>JAT-MED. Sp z o.o.</li>
                  <li>85-448 Bydgoszcz ul. Kruszyńska 17</li>
                </ul>
              </li>
              <li>
                by phone at the telephone number -{" "}
                <a href="tel:+48518468678">+48 518 468 678</a>
              </li>
              <li>
                electronically to the following address:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                If you are a Consumer or an Individual Entrepreneur who enters
                into an agreement with the Administrator on your own behalf, you
                have provided the Administrator with your personal data
                yourself.
              </li>
              <br />
              <span>
                If you do not enter into an agreement with the Administrator for
                yourself, but enter into an agreement on behalf of another
                Consumer or Individual Entrepreneur or Entrepreneur who is a
                legal person or other organizational unit to which the
                provisions grant legal capacity, you probably provided the
                personal data yourself when placing the order. However, it may
                happen that your data has been provided to the Administrator by
                a Consumer or an Entrepreneur on whose behalf you are acting in
                connection with the agreement.
              </span>
            </ul>
          </li>
          <li>
            Is it necessary for you to provide your data?
            <ul>
              <li>
                Providing your personal data is voluntary, however, failure to
                provide it will prevent the Administrator from concluding or
                performing the agreement concerning the Products.
              </li>
              <br />
              <span>
                In the case of processing your personal data for marketing
                purposes based on your separate consent, providing this data by
                you is also completely voluntary, but if you do not provide the
                Administrator with this data and do not consent to its
                processing for marketing purposes, you will not receive
                information from the Administrator about promotions, new
                products and other advertising campaigns of the Administrator.
              </span>
            </ul>
          </li>
        </ol>
      )}
      {i18next.resolvedLanguage === "uk" && (
        <ol>
          <li>
            Хто обробляє ваші персональні дані?
            <ul>
              <li>
                Відповідно до ст. 13 сек. 1 та ст. 14 сек. 1 Регламенту (ЄС)
                2016/679 Європейського Парламенту та Ради від 27 квітня 2016
                року про захист фізичних осіб у зв’язку з обробкою персональних
                даних і вільного переміщення таких даних, а також про скасування
                Директиви 95/46/ЄС (далі – GDPR), повідомляємо, що:
              </li>
              <li>
                Адміністратором ваших персональних даних є Jat-med sp. з
                зареєстрованим офісом у м. Бидгощ на вул. Крушинська 17,
                внесений до реєстру підприємців Національного судового реєстру,
                який веде Окружний суд у БИДГОЩІ, XIII КОМЕРЦІЙНИЙ ВІДДІЛ
                НАЦІОНАЛЬНОГО СУДОВОГО РЕЄСТРУ, під номером KRS 0001150821, NIP
                9671483929, REGON 540725908 (далі – Адміністратор).
              </li>
            </ul>
          </li>
          <li>
            Які дані обробляє Адміністратор?
            <br />
            Для яких цілей Адміністратор обробляє ваші персональні дані?
            <ul>
              <li>Ми обробляємо ваші персональні дані:</li>
              <li>
                Якщо Ви є Споживачем або Фізичною особою-підприємцем, яка від
                свого імені укладає договір з Адміністратором: відповідно до ст.
                6 абзац 1 л. b) GDPR – для укладення та виконання договору між
                вами та Адміністратором щодо Продуктів, які Адміністратор
                пропонує на веб-сайті{" "}
                <a href="https://jat-med.com">jat-med.com</a> та в
                Інтернет-магазині <a href="https://jat-med.com">jat-med.com</a>,
                обробка даних для цієї мети також включає обробку скарг та інших
                повідомлень щодо укладених договорів. Інформацію про Продукти
                Адміністратора також можна знайти в соціальних мережах
                Адміністратора, але укласти договір можна лише через сайт
                Інтернет-магазину відповідно до його регламенту;
              </li>
              <li>
                Якщо Ви укладаєте договір з Адміністратором не від себе, а
                укладаєте договір від імені іншого Споживача або ФОП чи ФОП,
                який є юридичною особою чи іншою організаційною одиницею, якій
                нормативними актами надано дієздатність: відповідно до ст. 6
                абзац 1 л. f) GDPR – для укладення та виконання угоди між цим
                іншим Споживачем або Підприємцем і Адміністратором щодо
                Продуктів, які пропонує Адміністратор на веб-сайті
                інтернет-магазину <a href="https://jat-med.com">jat-med.com</a>.
                Обробка даних для цієї мети також включає розгляд скарг та інших
                повідомлень щодо укладених договорів; юридично обґрунтований
                інтерес Адміністратора в даному випадку полягає в тому, щоб
                уможливити укладення та належне виконання укладеного ним
                договору;
              </li>
              <li>
                відповідно до ст. 6 абзац 1 пункт c) GDPR у зв’язку зі ст. 74
                розділ 2 п. 4 Закону про бухоблік – для виконання
                Адміністратором обов’язку щодо ведення та зберігання
                бухгалтерських документів;
              </li>
              <li>
                відповідно до ст. 6 абзац 1 пункт f) GDPR – в законодавчо
                обґрунтованому інтересі Адміністратора, що полягає в розгляді
                Адміністратором претензій проти Споживача чи Підприємця або
                захисті від претензій;
              </li>
              <li>
                відповідно до ст. 6 абзац 1 пункт f) GDPR – в юридично
                обґрунтованому інтересі Адміністратора, що полягає в прямому
                маркетингу власних Продуктів Адміністратора; Адміністратор
                проводитиме прямий маркетинг на цій основі лише традиційним
                способом, тобто паперовою поштою або особисто доставляючи
                маркетингові матеріали;
              </li>
              <li>
                відповідно до ст. 6 абзац 1 л. а) тобто на основі вашої згоди –
                ​​для маркетингових цілей Адміністратора, якщо ви дали окрему
                згоду на обробку ваших персональних даних для маркетингових
                цілей.
              </li>
            </ul>
          </li>
          <li>
            Як довго Адміністратор зберігатиме ваші персональні дані?
            <ul>
              <li>Персональні дані будуть зберігатися:</li>
              <li>
                з метою укладення та виконання договору Адміністратором – до
                його виконання, розірвання або завершення в інший спосіб;
              </li>
              <li>
                на виконання обов'язку щодо ведення та зберігання бухгалтерської
                документації – строком на 5 років.
              </li>
              <li>
                Пояснення: 5 років відраховуються з початку року, наступного за
                фінансовим роком, у якому операції, транзакції та процедури були
                остаточно завершені, погашені, врегульовані або настали строки
                давності;
              </li>
              <li>
                для подання або захисту від позовів – до тих пір, поки такі
                позови не закінчаться позовом;
              </li>
              <li>
                з метою прямого маркетингу в рамках законодавчо обґрунтованих
                інтересів – поки ви не заперечуєте проти обробки ваших даних для
                цієї мети;
              </li>
              <li>
                для маркетингових цілей Адміністратора, на підставі вашої
                окремої згоди – поки ви не відкличете свою згоду або
                Адміністратор (або його правонаступники) не припинить свою
                господарську діяльність (ліквідація).
              </li>
            </ul>
          </li>
          <li>
            Кому Адміністратор може передати ваші персональні дані (хто може
            бути одержувачем ваших даних)?
            <ul>
              <li>
                Для укладення, виконання або іншого обслуговування договору,
                укладеного між вами та Адміністратором, Адміністратор передає
                ваші дані кур’єрським компаніям, компанії, що обслуговує
                програмне забезпечення інтернет-магазину, бухгалтерській фірмі,
                яка обслуговує Адміністратора, оптовикам, складам, логістичним
                центрам, які виконують від імені Адміністратора діяльність,
                необхідну для обробки замовлень в Інтернет-магазині
                Адміністратора, компаніям, які здійснюють маркетингову
                діяльність від імені Адміністратора (де це можливо). Крім того,
                Адміністратор зобов’язаний передавати ваші персональні дані на
                підставі обов’язкових положень законодавства, у тому числі на
                вимогу уповноважених судів, органів влади чи інших установ.
              </li>
            </ul>
          </li>
          <li>
            Які права ви маєте щодо своїх персональних даних?
            <ul>
              <li>
                Відповідно до обмежень, що випливають із GDPR та інших правових
                положень, ви маєте право:
              </li>
              <li>доступ до вмісту ваших даних;</li>
              <li>
                отримати таку інформацію: про цілі обробки ваших персональних
                даних; про категорії персональних даних, які обробляє
                Адміністратор; про одержувачів або категорії одержувачів, яким
                Контролер розкрив ваші персональні дані або яким він має намір
                розкрити такі дані; про можливість реалізації своїх прав щодо
                захисту персональних даних, а також спосіб реалізації таких
                прав; про право звернення зі скаргою до контролюючого органу;
              </li>
              <li>
                вимагати виправлення персональних даних, які є невірними, і
                вимагати доповнення неповних персональних даних;
              </li>
              <li>
                вимагати видалення персональних даних: якщо персональні дані
                більше не потрібні для цілей, для яких вони були зібрані або
                оброблені, або ви відкликали згоду, на якій ґрунтується обробка,
                і Адміністратор не має інших правових підстав для обробки ваших
                персональних даних; персональні дані оброблялися неправомірно;
                персональні дані повинні бути видалені для дотримання юридичних
                зобов’язань. Неможливо скористатися правом на видалення
                персональних даних, якщо Адміністратор зобов’язаний відповідно
                до положень закону здійснювати подальшу обробку персональних
                даних в обсязі, визначеному відповідними положеннями закону, або
                для цілей, необхідних для встановлення, розгляду або захисту
                позовів Адміністратора;
              </li>
              <li>
                вимагати обмеження обробки персональних даних, якщо ви
                сумніваєтеся в достовірності персональних даних, які
                обробляються Адміністратором, – на строк, що дозволяє
                Адміністратору перевірити достовірність цих даних; обробка ваших
                персональних даних є незаконною, і ви виступили проти видалення
                ваших персональних даних, вимагаючи натомість обмеження їх
                використання; коли Адміністратору більше не потрібні ваші
                персональні дані для цілей обробки, але вони потрібні вам для
                встановлення, подання або захисту правових претензій. У разі
                реалізації права на обмеження обробки персональних даних
                Адміністратор може обробляти ваші персональні дані, за винятком
                їх зберігання, лише за вашою згодою або з метою встановлення,
                подання чи захисту претензій або з метою захисту прав іншої
                фізичної чи юридичної особи або з важливих причин суспільного
                інтересу Європейського Союзу чи держави-члена;
              </li>
              <li>
                передавати дані, що означає, що власник даних має право
                отримувати персональні дані, надані Контролеру, у
                структурованому, широко використовуваному та машиночитаному
                форматі та передавати ці персональні дані іншому контролеру,
                якщо це технічно можливо;
              </li>
              <li>
                заперечувати проти обробки даних в законних інтересах
                Адміністратора. Адміністратор не може продовжувати обробку ваших
                персональних даних після того, як ви заперечуєте, якщо немає
                переконливих законних підстав для обробки персональних даних,
                які переважають ваші інтереси, права та свободи, або з метою
                встановлення, подання чи захисту позовів. Якщо ви заперечуєте
                проти обробки ваших даних для законно обґрунтованих інтересів
                проведення прямого маркетингу, Адміністратор зобов’язаний
                припинити обробку ваших даних для цієї мети. Право на
                заперечення проти обробки даних не застосовується, якщо: обробка
                персональних даних ґрунтується на згоді або обробка даних
                необхідна для виконання договору, стороною якого ви є, або для
                здійснення дій на ваш запит до укладення договору з
                Адміністратором, або якщо обробка необхідна для виконання
                юридичного зобов’язання, покладеного на Адміністратора.
              </li>
              <li>
                якщо обробка персональних даних відбувається на підставі вашої
                згоди, ви маєте право в будь-який час відкликати свою згоду на
                обробку персональних даних, що не впливає на законність обробки,
                яка здійснювалася на підставі згоди до її відкликання.
              </li>
              <li>
                подати скаргу до наглядового органу, зокрема в державі-члені
                вашого постійного проживання, місця роботи або місця ймовірного
                порушення, якщо ви вважаєте, що обробка ваших персональних даних
                порушує положення GDPR. Контрольним органом у Польщі є президент
                Управління захисту персональних даних із зареєстрованим офісом у
                Варшаві.
              </li>
            </ul>
          </li>
          <li>
            Як можна зв'язатися з адміністратором?
            <ul>
              <li>
                З питань, пов’язаних з вашими персональними даними, в тому числі
                здійснення ваших прав щодо ваших персональних даних або
                відкликання згоди, ви можете зв’язатися з Адміністратором у
                такий спосіб:
              </li>
              <li>
                письмово на адресу:
                <ul>
                  <li>JAT-MED. Sp z o.o.</li>
                  <li>85-448 Бидгощ вул. Крушинська 17</li>
                </ul>
              </li>
              <li>
                по телефону за номером –{" "}
                <a href="tel:+48518468678">+48 518 468 678</a>
              </li>
              <li>
                електронною поштою на адресу:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                Якщо Ви є Споживачем або Фізичною особою-підприємцем, який
                укладає договір з Адміністратором від свого імені, Ви самостійно
                надали Адміністратору свої персональні дані.
              </li>
              <br />
              <span>
                Якщо Ви не укладаєте договір з Адміністратором від свого імені,
                а укладаєте договір від імені іншого Споживача або фізичної
                особи-підприємця чи підприємця, який є юридичною особою чи іншою
                організаційною одиницею, якій нормативно надано правоздатність,
                то, ймовірно, ви самі надали персональні дані при оформленні
                замовлення. Однак може статися, що ваші дані були надані
                Адміністратору Споживачем або Підприємцем, від імені якого ви
                дієте у зв’язку з договором.
              </span>
            </ul>
          </li>
          <li>
            Чи потрібно вам надавати свої дані?
            <ul>
              <li>
                Надання ваших персональних даних є добровільним, однак їх
                ненадання призведе до того, що Адміністратор не зможе укласти
                або виконати договір щодо Продуктів.
              </li>
              <br />
              <span>
                У разі обробки ваших персональних даних у маркетингових цілях на
                підставі вашої окремої згоди, надання вами цих даних також є
                абсолютно добровільним, але якщо ви не надасте Адміністратору ці
                дані та не дасте згоди на їх обробку в маркетингових цілях, ви
                не отримуватимете від Адміністратора повідомлення про акції,
                нові продукти та інші рекламні кампанії Адміністратора.
              </span>
            </ul>
          </li>
        </ol>
      )}
    </article>
  );
};

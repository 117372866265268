import styles from "./styles.module.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
export const WebsiteTerms = () => {
  const { t } = useTranslation();
  return (
    <article className={styles.article}>
      <h1>{t("WebsiteTerms.title")}</h1>

      {i18next.resolvedLanguage === "pl" && (
        <ol>
          <li>
            Postanowienia wstępne
            <ul>
              <li>
                Regulamin określa zasady funkcjonowania serwisu jat-med.com,
                dostępnego pod adresem internetowym{" "}
                <a href="https://jat-med.com">jat-med.com</a>, oraz dokonywania
                zakupów w sklepie internetowym jat-med.com, dostępnym pod
                adresem internetowym{" "}
                <a href="https://jat-med.com">jat-med.com</a>
              </li>
              <li>
                Serwis prowadzony jest przez Jat-med sp. z o.o. z siedzibą w
                Bydgoszczy przy ulicy Kruszyńska 17, wpisanej do rejestru
                przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez
                Sąd Rejonowy W BYDGOSZCZY, XIII WYDZIAŁ GOSPODARCZY KRAJOWEGO
                REJESTRU SĄDOWEGO, pod numerem KRS 0001150821, NIP 9671483929,
                REGON 540725908.
              </li>
            </ul>
          </li>
          <li>
            Definicje
            <ul>
              <li>
                <b>Administrator</b> – Jat-med sp. z o.o. z siedzibą w
                Bydgoszczy ulicy Kruszyńska 17, wpisanej do rejestru
                przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez
                Sąd Rejonowy W BYDGOSZCZY, XIII WYDZIAŁ GOSPODARCZY KRAJOWEGO
                REJESTRU SĄDOWEGO, pod numerem KRS 0001150821, NIP 9671483929,
                REGON 540725908.
              </li>
              <li>
                <b>Serwis</b> – serwis internetowy prowadzony przez
                Administratora pod adresem jat-med.com, dokonuje sprzedaży
                Produktów, udostępnia Pliki zawierające materiały do leczenia i
                profilaktyki schorzeń układu ruchu, a także świadczy inne usługi
                określone w Regulaminie, w tym możliwość zawarcia Umowy na
                Konsultacje On-line, oraz Konsultacje Stacjonarne.
              </li>
              <li>
                <b>Konsument</b> – Użytkownik, będący osobą fizyczna zawierająca
                z Administratorem Umowę w ramach Serwisu, której przedmiot nie
                jest związany bezpośrednio z działalnością gospodarczą lub
                zawodową Użytkownika.
              </li>
              <li>
                <b>Przedsiębiorca</b> – Użytkownik niebędący Konsumentem.
              </li>
              <li>
                <b>Sklep</b> – wyodrębniona część Serwis sklep internetowy
                prowadzony przez Administratora pod adresem internetowym{" "}
                <a href="https://jat-med.com" aria-label="Sklep">
                  jat-med.com
                </a>
              </li>
              <li>
                <b>Umowa zawarta na odległość</b> – umowa zawarta z Klientem w
                ramach zorganizowanego systemu zawierania umów na odległość (w
                ramach Serwisu), bez jednoczesnej fizycznej obecności stron, z
                wyłącznym wykorzystaniem jednego lub większej liczby środków
                porozumiewania się na odległość do chwili zawarcia umowy
                włącznie.
              </li>
              <li>
                <b>Regulamin</b> – regulamin Serwisu Jat-med.com
              </li>
              <li>
                <b>Zamówienie</b> – oświadczenie woli Użytkownika składane za
                pomocą Formularza Zamówienia i zmierzające bezpośrednio do
                zawarcia Umowy z Administratorem.
              </li>
              <li>
                <b>Konto</b> – konto Użytkownika w Serwisie, są w nim gromadzone
                dane podane przez Klienta oraz informacje o złożonych przez
                niego Zamówieniach w Serwisie.
              </li>
              <li>
                <b>Formularz rejestracji</b> – formularz dostępny w Serwisie,
                umożliwiający utworzenie Konta.
              </li>
              <li>
                <b>Formularz zamówienia</b> – interaktywny formularz dostępny w
                Serwisie umożliwiający złożenie Zamówienia.
              </li>
              <li>
                <b>Koszyk</b> – element oprogramowania Sklepu, w którym widoczne
                są wybrane przez Użytkownika Produkty do zakupu, a także
                istnieje możliwość ustalenia i modyfikacji danych Zamówienia, w
                szczególności ilości produktów.
              </li>
              <li>
                <b>Konsultacja on-line</b> – usługa świadczona przez
                Administratora (zapewnienie konsultacji ze Specjalistą) drogą
                elektroniczną na zasadach określonych w regulaminie usługi
                świadczonej na odległość „Konsultacja On-Line” dostępnym pod
                adresem:{" "}
                <a
                  href="https://jat-med.com/policy/online-consultations"
                  aria-label="Konsultacja on-line"
                >
                  https://jat-med.com/policy/online-consultations
                </a>
              </li>
              <li>
                <b>Konsultacja Stacjonarna</b> – Usługa świadczona przez
                Administratora (zapewnienie konsultacji ze Specjalistą) w
                gabinecie stacjonarnym na zasadach określonych w regulaminie
                usługi świadczonej stacjonarnie dostępnym pod adresem:{" "}
                <a
                  href="https://jat-med.com/policy/in-person-consultations"
                  aria-label="Konsultacja Stacjonarna"
                >
                  https://jat-med.com/policy/in-person-consultations
                </a>{" "}
              </li>
              <li>
                <b>Operator płatności:</b>{" "}
                <a
                  href="http://Stripe.com"
                  target="_blank"
                  aria-label="Stripe"
                  rel="noreferrer"
                >
                  Stripe.com
                </a>
              </li>
              <li>
                <b>Produkt</b> – dostępna w Sklepie rzecz ruchoma (w tym Plik)
                lub usługa będąca przedmiotem Umowy Sprzedaży między
                Użytkownikiem a Administratorem.
              </li>
              <li>
                <b>Umowa Sprzedaży</b> – umowa sprzedaży Produktu zawierana albo
                zawarta między Użytkownikiem a Administratorem za pośrednictwem
                Serwisu. Przez Umowę Sprzedaży rozumie się też – stosowanie do
                cech Produktu – umowę o świadczenie usług.
              </li>
              <li>
                <b>Umowa na Konsultacje On-line</b> – umowa o świadczenie usług
                w postaci Konsultacji On-line zawierana albo zawarta między
                Użytkownikiem a Administratorem za pośrednictwem Serwisu i
                świadczona na zasadach określonych w regulaminie usługi
                świadczonej na odległość „Konsultacja On-Line” dostępnym pod
                adresem:{" "}
                <a
                  href="https://jat-med.com/policy/online-consultations"
                  aria-label="Konsultacja on-line"
                >
                  https://jat-med.com/policy/online-consultations
                </a>{" "}
              </li>
              <li>
                <b>Umowa na Konsultacje Stacjonarne</b> – umowa o świadczenie
                usług w postaci stacjonarnej zawierana albo zawarta między
                Użytkownikiem a Administratorem za pośrednictwem Serwisu i
                świadczona na zasadach określonych w regulaminie Usługi
                Stacjonarnej dostępnym pod adresem:{" "}
                <a href="https://jat-med.com/policy/in-person-consultations">
                  https://jat-med.com/policy/in-person-consultations
                </a>
              </li>
              <li>
                <b>Umowa</b> – Umowa Sprzedaży, Umowa Konsultacji On-line, Umowa
                Usług Stacjonarnych.
              </li>
              <li>
                <b>Urządzenie Elektroniczne</b> – Urządzenie przeznaczone m.in.
                do pobierania oraz utrwalania plików cyfrowych (np. komputer
                osobisty, smartphone, tablet, czytnik książek elektronicznych).
              </li>
              <li>
                <b>Usługi dodatkowe</b> – usługi świadczone przez Administratora
                na rzecz Użytkowników zarejestrowanych w Serwisie (tj.
                posiadających Konto).
              </li>
              <li>
                <b>Użytkownik</b> – osoba fizyczna, osoba prawna oraz jednostka
                organizacyjna nieposiadająca osobowości prawnej, odwiedzająca
                Serwis lub korzystająca z usług Serwisu.
              </li>
            </ul>
          </li>
          <li>
            Kontakt z Serwisem
            <ul>
              <li>
                Adres wykonywania działalności gospodarczej przez
                Administratora: 85-448 Bydgoszcz ul. Kruszyńska 17
              </li>
              <li>
                Adres e-mail Administratora:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                Numer telefonu Administratora:
                <a href="tel:+48518648678">+48 518 648 678</a>
              </li>
              <li>
                Numer rachunku bankowego Administratora: 17 1240 1183 1111 0011
                5020 1417
              </li>
              <li>
                Użytkownik może porozumiewać się z Administratorem za pomocą
                adresów i numerów telefonów podanych w tym punkcie.
              </li>
              <li>
                Użytkownik może porozumieć się telefonicznie z Administratorem w
                godzinach: 9 – 15.
              </li>
            </ul>
          </li>
          <li>
            Wymagania techniczne dla korzystania z Serwisu i oferowanych w jego
            ramach usług
            <ul>
              <li>
                Do korzystania z Serwisu, w tym przeglądania asortymentu Sklepu
                oraz składania Zamówień, niezbędne są:
                <ol>
                  <li>
                    Urządzenie Elektroniczne z dostępem do sieci Internet i
                    przeglądarką internetową.
                  </li>
                  <li>aktywne konto poczty elektronicznej (e-mail),</li>
                  <li>włączona obsługa plików cookies,</li>
                  <li>zainstalowany program FlashPlayer.</li>
                </ol>
              </li>
              <li>
                Umowa Sprzedaży Pliku może zostać skutecznie zrealizowana
                wyłącznie przy wykorzystaniu Urządzeń Elektronicznych
                spełniających wyszczególnione poniżej wymagania techniczne.
              </li>
              <li>
                Dla plików zapisanych w formatach ePUB, mobi i PDF:
                <ol>
                  <li>
                    komputer klasy PC:
                    <ul>
                      <li>
                        minimalne wymagania – zainstalowany system operacyjny
                        Microsoft® Windows® XP z dodatkiem Service Pack 3, z
                        procesorem Intel® Pentium® 500 MHz, 128 MB pamięci RAM i
                        monitorem o minimalnej rozdzielczości 800 x 600 pikseli.
                      </li>
                      <li>
                        na komputerze musi być dodatkowo zainstalowane
                        oprogramowanie umożliwiające otwieranie plików typu
                        ePub, mobi, PDF, np. Adobe Reader;
                      </li>
                    </ul>
                  </li>
                  <li>
                    komputer klasy MAC
                    <ul>
                      <li>
                        procesor PowerPC®: zainstalowany system operacyjny Mac
                        OS X v 10.4.10 lub wyższy z procesorem PowerPC® G4 lub
                        G5 500 MHz, 128 MB pamięci operacyjnej RAM lub procesor
                        Intel®;
                      </li>
                    </ul>
                  </li>
                  <li>czytnik książek elektronicznych;</li>
                  <li>
                    tablet lub smartphone z zainstalowanym systemem iOS,
                    Android, Windows 8 (lub wyższy) i aplikacją obsługującą
                    pliki epub, mobi, pdf np.: BlueFire Reader;
                  </li>
                </ol>
              </li>
              <li>
                Dla produktów zapisanych w formacie MP3:
                <ol>
                  <li>
                    komputer wyposażony w urządzenia umożliwiające emisję
                    dźwięku za pomocą głośników/słuchawek lub innych podobnych
                    urządzeń, z zainstalowanym oprogramowaniem do odczytu plików
                    w formacie MP3, np. Winamp, Windows Media Player;
                  </li>
                  <li>
                    dowolny czytnik plików muzycznych umożliwiający odtwarzanie
                    plików zapisanych w formacie MP3;
                  </li>
                  <li>
                    tablet lub smartphone z zainstalowaną aplikacją
                    umożliwiającą odtwarzanie plików zapisanych w formacie MP3
                  </li>
                </ol>
              </li>
              <li>
                Dla produktów zapisanych w formacie MP4 komputer z
                zainstalowanym oprogramowaniem do odtwarzania plików filmowych,
                np. Windows Media Player, lub dowolne urządzenie przenośne
                umożliwiające odtwarzanie plików wideo w formacie MP4
              </li>
              <li>
                Przy każdym z Plików oferowanym w Sklepie zamieszczona jest
                informacja zawierająca charakterystykę Pliku (ePUB, PDF, Mobi,
                MP3, MP4), objętość Pliku oraz jego format. Przed dokonaniem
                Zamówienia Użytkownik powinien sprawdzić, czy Urządzenie
                Elektroniczne przeznaczone do pobrania danego Pliku spełnia
                wymagania sprzętowe określone w pkt. 4.1-4.5 oraz posiada
                wystarczającą pamięć pozwalającą na utrwalenie Pliku na
                Urządzeniu Elektronicznym.
              </li>
              <li>
                Wymagania techniczne niezbędne do korzystania z Usług
                dodatkowych:
                <ol>
                  <li>
                    komputer z procesorem – Intel® Pentium® 500 MHz (lub AMD),
                    wyposażony w pamięć operacyjną min. 128 MB, z zainstalowanym
                    systemem operacyjnym Windows® XP lub wyższym, Mac OS X
                    (zaktualizowany system operacyjny z możliwością pobierania i
                    instalowania oprogramowania), zaktualizowana wersja
                    przeglądarki internetowej oraz sprawne łącze internetowe o
                    minimalnej prędkości 1Mb/s.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Zakładanie Konta w Serwisie
            <ul>
              <li>
                Aby zarejestrować Konto w Serwisie, należy wypełnić Formularz
                rejestracji. Niezbędne jest podanie następujących danych: imię,
                nazwisko, kraj, ulica, numer domu i mieszkania, kod pocztowy,
                miasto, numer telefonu, adres e-mail. Możliwa jest również
                rejestracja za pomocą serwisu Facebook lub Google.
              </li>
              <li>Rejestracja Konta w Serwisie jest darmowa.</li>
              <li>
                Logowanie się na Konto odbywa się poprzez podanie loginu i hasła
                ustanowionych w Formularzu rejestracji.
              </li>
              <li>
                Klient ma możliwość w każdej chwili, bez podania przyczyny i bez
                ponoszenia z tego tytułu jakichkolwiek opłat usunąć Konto
                poprzez wysłanie stosownego żądania do Administratora, w
                szczególności za pośrednictwem poczty elektronicznej lub
                pisemnie na adresy podane w pkt. 3.
              </li>
            </ul>
          </li>
          <li>
            Zasady korzystania ze Sklepu
            <ul>
              <li>
                Przeglądanie asortymentu Sklepu nie wymaga zakładania Konta.
                Składanie zamówień przez Użytkownika na Produkty znajdujące się
                w asortymencie Sklepu możliwe jest albo po założeniu Konta
                zgodnie z postanowieniami pkt 5 Regulaminu albo przez podanie
                niezbędnych danych osobowych i adresowych umożliwiających
                realizację Zamówienia bez zakładania Konta.
              </li>
              <li>Umową Sprzedaży objęte są nowe Produkty.</li>
              <li>
                Na końcową (ostateczną) kwotę do zapłaty przez Użytkownika
                składa się cena za Produkt oraz koszt dostawy (w tym opłaty za
                transport, dostarczenie i usługi pocztowe), o której Użytkownik
                jest informowany na stronach Sklepu w trakcie składania
                Zamówienia, w tym także w chwili wyrażenia woli związania się
                Umową Sprzedaży.
              </li>
            </ul>
          </li>
          <li>
            Zasady składania Zamówienia
            <ul>
              <li>
                Użytkownik może składać Zamówienia na stronie Serwisu, 24
                godziny na dobę, przez 7 dni w tygodniu. Zamówienia są
                realizowane w dni robocze, w godzinach 8-16.
              </li>
              <li>
                W celu złożenia Zamówienia na Produkt należy:
                <ol>
                  <li>zalogować się do Serwisu (opcjonalnie);</li>
                  <li>
                    wybrać Produkt będący przedmiotem Zamówienia, a następnie
                    kliknąć przycisk „Do koszyka” (lub równoznaczny);
                  </li>
                  <li>
                    zalogować się lub skorzystać z możliwości złożenia
                    Zamówienia bez rejestracji;
                  </li>
                  <li>
                    jeżeli wybrano możliwość złożenia Zamówienia bez rejestracji
                    – wypełnić Formularz zamówienia poprzez wpisanie danych
                    odbiorcy Zamówienia oraz adresu, na który ma nastąpić
                    dostawa Produktu, wybrać rodzaj przesyłki (sposób
                    dostarczenia Produktu), wpisać dane do faktury, jeśli są
                    inne niż dane odbiorcy Zamówienia,
                  </li>
                  <li>
                    kliknąć przycisk “Zamawiam i płacę” oraz potwierdzić
                    zamówienie, klikając w link przesłany w wiadomości e-mail.
                  </li>
                </ol>
              </li>
              <li>
                W celu złożenia Zamówienia na Konsultacje On-line i Usługi
                Stacjonarne należy:
                <ol>
                  <li>
                    zalogować się lub skorzystać z możliwości złożenia
                    Zamówienia bez rejestracji;
                  </li>
                  <li>
                    wypełnić Formularz zamówienia poprzez wpisanie danych
                    odbiorcy Zamówienia: imię i nazwisko, numer telefonu
                    komórkowego, adres email oraz cel konsultacji; niezależnie
                    od powyższego na późniejszym etapie Użytkownik zobowiązany
                    będzie do podania jeszcze innych danych potrzebnych do
                    zrealizowania konsultacji i usług.
                  </li>
                  <li>
                    kliknąć przycisk “Zamawiam i płacę” oraz potwierdzić
                    zamówienie, klikając w link przesłany w wiadomości e-mail,
                  </li>
                </ol>
              </li>
              <li>
                Finalizacja Zamówienia wymaga wyboru jednego z dostępnych
                sposobów płatności i w zależności od sposobu płatności, opłacić
                zamówienie w określonym terminie, z zastrzeżeniem pkt. 8 i 9.
              </li>
              <li>
                Administrator zastrzega sobie prawo odstąpienia od realizacji
                Zamówienia Użytkownika, który nie podał wszystkich wymaganych
                danych, w tym danych wymaganych na podstawie ustawy o prawach
                pacjenta i rzeczniku praw pacjenta w celu prowadzenia
                dokumentacji medycznej w związku ze świadczeniem zdrowotnym, lub
                podane dane budzą wątpliwości, co do ich poprawności.
                Zamówienie, którego nie uda się potwierdzić w ciągu 10 dni od
                jego złożenia, może nie zostać zrealizowane.
              </li>
              <li>
                Składając zamówienie, Klient:
                <ol>
                  <li>
                    wyraża zgodę na wystawienie faktury VAT bez podpisu
                    odbiorcy,
                  </li>
                  <li>
                    oświadcza, iż zapoznał się z Regulaminem oraz innym
                    regulaminami dostępnymi na stronie Serwisu, w tym Polityką
                    Prywatności i akceptuje ich postanowienia.
                  </li>
                </ol>
              </li>
              <li>
                Wszystkie ceny podane w Serwisie są podane w polskich złotych i
                są cenami brutto.
              </li>
            </ul>
          </li>
          <li>
            Oferowane metody dostawy oraz płatności
            <ul>
              <li>
                Klient może skorzystać z następujących metod dostawy lub odbioru
                zamówionego Produktu:
                <ol>
                  <li>przesyłka kurierska.</li>
                  <li>
                    odbiór osobisty dostępny pod adresem: 85-448 Bydgoszcz ul.
                    Kruszyńska 17
                  </li>
                </ol>
              </li>
              <li>
                Odbiór osobisty możliwy jest po wcześniejszym ustaleniu terminu:
                dnia i godziny odbioru przesyłki. W tym celu Klient każdorazowo
                musi ustalić szczegóły odbioru osobistego poprzez bezpośredni
                kontakt ze Sprzedającym.
              </li>
              <li>
                Klient może skorzystać z następujących metod płatności:
                <ol>
                  <li>płatność przelewem na konto Administratora;</li>
                  <li>
                    płatność dokonywana elektronicznie za pośrednictwem usługi
                    świadczonej przez Operatora płatności (na warunkach
                    określonych przez Operatora płatności) w formie: przelewu
                    elektronicznego lub obciążenia karty płatniczej Użytkownika
                    (lista akceptowanych kart płatniczych: Visa/Visa Electron,
                    MasterCard/MasterCard Electronic, Maestro, American
                    Express).
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Wykonanie Umowy Sprzedaży, Konsultacji On-line, Usługi Stacjonarnej
            <ul>
              <li>
                Zawarcie Umowy Sprzedaży i Umowy Konsultacji On-line między
                Użytkownikiem a Administratorem następuje po uprzednim złożeniu
                przez Użytkownika Zamówienia zgodnie z pkt. 7
              </li>
              <li>
                Po złożeniu Zamówienia Administrator niezwłocznie potwierdza
                jego otrzymanie oraz jednocześnie przyjmuje Zamówienie do
                realizacji.
              </li>
              <li>
                Potwierdzenie otrzymania Zamówienia i jego przyjęcie do
                realizacji następuje poprzez przesłanie przez Administratora
                stosownej wiadomości e-mail Użytkownikowi na podany w trakcie
                składania Zamówienia adres poczty elektronicznej Użytkownika.
                Wiadomość zawiera co najmniej oświadczenia Administratora o
                otrzymaniu Zamówienia i o jego przyjęciu do realizacji oraz
                potwierdzenie zawarcia Umowy Sprzedaży. Z chwilą otrzymania
                przez Klienta powyższej wiadomości e-mail zostaje zawarta Umowa
                między Użytkownikiem a Administratorem.
              </li>
              <li>
                W przypadku wyboru przez Klienta:
                <ol>
                  <li>
                    płatności elektronicznych albo płatności kartą płatniczą,
                    Użytkownik obowiązany jest do dokonania płatności w dniu
                    zawarcia Umowy Sprzedaży – w przeciwnym razie zamówienie
                    zostanie anulowane;
                  </li>
                  <li>
                    płatności za pobraniem przy odbiorze przesyłki, Użytkownik
                    obowiązany jest do dokonania płatności przy odbiorze
                    przesyłki – w przypadku niedokonania płatności przesyłka nie
                    zostanie Użytkownikowi wydana;
                  </li>
                  <li>
                    płatności gotówką przy odbiorze osobistym przesyłki,
                    Użytkownik obowiązany jest dokonać płatności przy odbiorze
                    przesyłki w punkcie odbioru w dniu odbioru przesyłki – w
                    przypadku niedokonania płatności nie zostanie Użytkownikowi
                    wydana.
                  </li>
                </ol>
              </li>
              <li>
                W przypadku wyboru płatności przelewem, zastosowanie ma
                następująca procedura:
                <ol>
                  <li>
                    po dokonaniu zamówienia, Użytkownik otrzymuje potwierdzenie
                    Zamówienia, na podstawie którego Użytkownik dokonuje wpłaty
                    na konto Administratora w ciągu dwóch dni od jego
                    otrzymania,
                  </li>
                  <li>
                    potwierdzenie Zamówienia zawiera numer zamówienia, jego
                    wartość oraz numer rachunku bankowego Administratora.
                  </li>
                  <li>
                    Użytkownik zobowiązany jest podać na formularzu przelewu
                    następującą treść: „Imię i Nazwisko Użytkownika, numer
                    Zamówienia […[”.
                  </li>
                </ol>
              </li>
              <li>
                Jeżeli Użytkownik wybrał sposób dostawy inny niż odbiór
                osobisty, Produkt zostanie wysłany przez Administratora w
                terminie wskazanym w jego opisie (z zastrzeżeniem pkt. 6), w
                sposób wybrany przez Użytkownika podczas składania Zamówienia.
                <ol>
                  <li>
                    w przypadku zamówienia Produktów o różnych terminach
                    dostawy, terminem dostawy jest najdłuższy podany termin.
                  </li>
                  <li>
                    w przypadku zamówienia Produktów o różnych terminach
                    dostawy, Klient ma możliwość żądania dostarczenia Produktów
                    częściami lub też dostarczenia wszystkich Produktów po
                    skompletowaniu całego zamówienia.
                  </li>
                </ol>
              </li>
              <li>
                Początek biegu terminu dostawy Produktu do Użytkownika liczy się
                w następujący sposób:
                <ol>
                  <li>
                    w przypadku wyboru przez Użytkownika sposobu płatności
                    przelewem, płatności elektroniczne lub kartą płatniczą – od
                    dnia uznania rachunku bankowego Administratora.
                  </li>
                  <li>
                    w przypadku wyboru przez Użytkownika sposobu płatności za
                    pobraniem – od dnia zawarcia Umowy Sprzedaży,
                  </li>
                  <li>
                    w przypadku wyboru przez Użytkownika odbioru osobistego
                    Produktu, Produkt będzie gotowy do odbioru przez Użytkownika
                    w terminie wskazanym w opisie Produktu. O gotowości Produktu
                    do odbioru Użytkownik zostanie dodatkowo poinformowany przez
                    Administratora poprzez przesłanie stosownej wiadomości
                    e-mail na podany w trakcie składania Zamówienia adres poczty
                    elektronicznej Użytkownika.
                  </li>
                </ol>
              </li>
              <li>
                Początek biegu terminu gotowości Produktu do odbioru przez
                Użytkownika liczy się w następujący sposób:
                <ol>
                  <li>
                    w przypadku wyboru przez Użytkownika sposobu płatności
                    przelewem, płatności elektroniczne lub kartą płatniczą – od
                    dnia uznania rachunku bankowego Administratora;
                  </li>
                  <li>
                    w przypadku wyboru przez Użytkownika sposobu gotówką przy
                    odbiorze osobistym – od dnia zawarcia Umowy Sprzedaży.
                  </li>
                </ol>
              </li>
              <li>
                W przypadku Produktów będących Plikami:
                <ol>
                  <li>
                    opłacenie Zamówienia przez Użytkownika powoduje automatyczne
                    (nie później niż 15 minut od chwili otrzymania potwierdzenia
                    płatności od Operatora Płatności lub uznania rachunku
                    bankowego Administratora) udostępnienie zamówionego Pliku,
                    poprzez przesłanie Użytkownikowi, na podany przez niego
                    adres email, wiadomości e-mail z odnośnikiem do strony
                    internetowej, z której możliwe będzie pobranie Pliku, lub
                    bezpośrednie wysłanie na podany w trakcie zakupu adres
                    e-mail. W szczególnych przypadkach (np. na skutek awarii
                    serwera) Plik może zostać udostępniony później niż 15 minut
                    od chwili Opłacenia Zamówienia;
                  </li>
                  <li>
                    od chwili udostępnienia Plik może zostać pobrany przez
                    Użytkownika, przy czym pobranie tego Pliku wyłącza możliwość
                    odstąpienia od umowy dotyczącej tego Pliku – na zasadach
                    określonych w pkt. 10 i 11 .
                  </li>
                </ol>
              </li>
              <li>
                Dostawa Produktu (niebędącego Plikiem) odbywa się wyłącznie na
                terenie
              </li>
              <li>
                Dostawa Produktu niebędącego Plikiem do Klienta jest odpłatna,
                chyba że Umowa Sprzedaży stanowi inaczej. Koszty dostawy
                Produktu (w tym opłaty za transport, dostarczenie i usługi
                pocztowe) są wskazywane Klientowi na stronach Sklepu
                internetowego w zakładce „Koszty dostawy” oraz w trakcie
                składania Zamówienia, oraz na wybranych stronach produktu.
              </li>
              <li>Odbiór osobisty Produktu przez Klienta jest bezpłatny.</li>
              <li>
                Realizacja Umowy Konsultacji On-line określona jest w
                regulaminie usługi świadczonej drogą elektroniczną „Konsultacja
                On-Line” dostępnym pod adresem:{" "}
                <a
                  href="https://jat-med.com/policy/online-consultations"
                  aria-label="Konsultacja on-line"
                >
                  https://jat-med.com/policy/online-consultations
                </a>{" "}
              </li>
              <li>
                Za zamówione Produkty (inne niż Pliki pobierane przez Internet)
                zostanie wystawiona i wysłana razem z nimi faktura VAT. W
                przypadku Plików oraz Konsultacji On-line faktura VAT zostanie
                wystawiona i wysłana Użytkownikowi na podany przez niego adres
                email w ciągu 7 dni od dnia uznania rachunku bankowego
                Administratora.
              </li>
            </ul>
          </li>
          <li>
            Prawo odstąpienia od Umowy przez Konsumenta
            <ul>
              <li>
                Zgodnie z brzmieniem art. 27 ustawy z dnia 30 maja 2014 r. o
                prawach Konsumenta, każdemu Użytkownikowi będącemu jednocześnie
                Konsumentem przysługuje prawo odstąpienia od umowy zawartej na
                odległość w terminie 14 dni, bez podania przyczyny.
              </li>
              <li>
                Bieg terminu określonego w pkt. 1 rozpoczyna się od dostarczenia
                Produktu Konsumentowi lub wskazanej przez niego osobie innej niż
                przewoźnik.
              </li>
              <li>
                W przypadku Umowy, która obejmuje wiele Produktów, które są
                dostarczane osobno, partiami lub w częściach, termin wskazany w
                ust. 1 biegnie od dostawy ostatniej rzeczy, partii lub części.
              </li>
              <li>
                Konsument może odstąpić od Umowy, składając Administratorowi
                jednoznaczne oświadczenie woli odstąpienia od Umowy. Do
                zachowania terminu odstąpienia od Umowy wystarczy wysłanie przez
                Konsumenta oświadczenia przed upływem tego terminu.
              </li>
              <li>
                Oświadczenie może być wysłane za pomocą tradycyjnej poczty, bądź
                drogą elektroniczną poprzez przesłanie oświadczenia na adres
                e-mail Administratora określony w pkt. 2. Oświadczenie można
                złożyć również na formularzu, którego wzór stanowi załącznik nr
                1 do Regulaminu lub załącznik do ustawy z dnia 30 maja 2014 roku
                o prawach konsumenta, jednak nie jest to obowiązkowe.
              </li>
              <li>
                W przypadku przesłania oświadczenia przez Konsumenta drogą
                elektroniczną, Administratora niezwłocznie prześle Konsumentowi
                na podany przez Konsumenta adres e-mail potwierdzenie otrzymania
                oświadczenia o odstąpieniu od Umowy.
              </li>
              <li>
                Prawo do odstąpienia od Umowy zawartej na odległość nie
                przysługuje Konsumentowi w odniesieniu do m.in. Umowy:
                <ol>
                  <li>
                    o dostarczanie treści cyfrowych, w tym Plików, które nie są
                    zapisane na nośniku materialnym, jeżeli spełnianie
                    świadczenia rozpoczęło się za wyraźną zgodą konsumenta przed
                    upływem terminu do odstąpienia od umowy i po poinformowaniu
                    go przez przedsiębiorcę o utracie prawa odstąpienia od
                    Umowy;
                  </li>
                  <li>
                    świadczenie usług, jeżeli Administrator wykonał w pełni
                    usługę za wyraźną zgodą Konsumenta, który został
                    poinformowany przez rozpoczęciem świadczenia, że po
                    spełnieniu świadczenia przez Administratora utraci prawo
                    odstąpienia od Umowy;
                  </li>
                  <li>
                    w której przedmiotem świadczenia jest rzecz dostarczana w
                    zapieczętowanym opakowaniu, której po otwarciu opakowania
                    nie można zwrócić ze względu na ochronę zdrowia lub ze
                    względów higienicznych, jeżeli opakowanie zostało otwarte po
                    dostarczeniu;
                  </li>
                  <li>
                    w której przedmiotem świadczenia jest rzecz
                    nieprefabrykowana, wyprodukowana według specyfikacji
                    Konsumenta lub służąca zaspokojeniu jego
                    zindywidualizowanych potrzeb.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Skutki odstąpienia od Umowy przez Konsumenta
            <ul>
              <li>
                W przypadku odstąpienia od Umowy zawartej na odległość Umowę
                uważa się za niezawartą.
              </li>
              <li>
                W przypadku odstąpienia od Umowy Administrator zwraca
                Konsumentowi niezwłocznie, nie później niż w terminie 14 dni od
                dnia otrzymania oświadczenia Konsumenta o odstąpieniu od Umowy,
                wszystkie dokonane przez niego płatności, w tym koszty
                dostarczenia Produktu, z wyjątkiem dodatkowych kosztów
                wynikających z wybranego przez Konsumenta sposobu dostarczenia
                innego niż najtańszy zwykły sposób dostarczenia oferowany przez
                Administratora.
              </li>
              <li>
                Zwrotu płatności Administratora dokona przy użyciu takich samych
                metod płatności, jakie zostały przez Konsumenta użyte w
                pierwotnej transakcji, chyba że Konsument wyraźnie zgodził się
                na inne rozwiązanie, które nie będzie się wiązało dla niego z
                żadnymi kosztami.
              </li>
              <li>
                Administrator może wstrzymać się ze zwrotem płatności do czasu
                otrzymania Produktu z powrotem lub do czasu dostarczenia mu
                dowodu jego odesłania, w zależności od tego, które zdarzenie
                nastąpi wcześniej.
              </li>
              <li>
                Konsument powinien odesłać Produkt na adres Administratora
                podany w Regulaminie niezwłocznie, nie później niż 14 dni od
                dnia, w którym poinformował Administratora o odstąpieniu od
                Umowy. Termin zostanie zachowany, jeśli Konsument odeśle Produkt
                przed upływem terminu 14 dni.
              </li>
              <li>
                Konsument ponosi bezpośrednie koszty zwrotu Produktu, także
                koszty zwrotu Produktu, jeśli ze względu na swój charakter
                Produkt ten nie mógł zostać w zwykłym trybie odesłany pocztą.
              </li>
              <li>
                Konsument odpowiada tylko za zmniejszenie wartości Produktu
                wynikające z korzystania z niego w sposób inny niż było to
                konieczne do stwierdzenia charakteru, cech i funkcjonowania
                Produktu.
              </li>
              <li>
                W przypadku gdy ze względu na charakter Produktu nie może on
                zostać odesłany w zwykłym trybie pocztą, informacja o tym, a
                także o kosztach zwrotu Produktu, będzie się znajdować w opisie
                Produktu w Sklepie.
              </li>
            </ul>
          </li>
          <li>
            Reklamacja i gwarancja
            <ul>
              <li>
                Każdy Użytkownik może zgłaszać reklamacje dotyczące wszelkich
                usług wymienionych w Regulaminie (w tym wad i usterek Plików) w
                oparciu o przepisy dotyczące rękojmi w kodeksie cywilnym. Jeżeli
                Klientem jest Przedsiębiorca, strony wyłączają odpowiedzialność
                z tytułu rękojmi.
              </li>
              <li>
                Reklamację należy zgłosić pisemnie lub drogą elektroniczną na
                podane w Regulaminie adresy Administratora.
              </li>
              <li>
                Zaleca się, aby w reklamacji zawrzeć m.in. zwięzły opis wady,
                okoliczności (w tym datę) jej wystąpienia, dane Użytkownika
                składającego reklamację, oraz żądanie Klienta w związku z wadą
                towaru.
              </li>
              <li>
                Administrator ustosunkuje się do żądania reklamacyjnego
                niezwłocznie, nie później niż w terminie 14 dni, a jeśli nie
                zrobi tego w tym terminie, uważa się, że żądanie Klienta uznał
                za uzasadnione.
              </li>
              <li>
                Produkty odsyłane w ramach procedury reklamacyjnej należy
                wysyłać na adres podany w pkt. 3 Regulaminu.
              </li>
            </ul>
          </li>
          <li>
            Przetwarzanie danych osobowych
            <ul>
              <li>
                W związku z korzystaniem przez Użytkownika z Serwisu
                Administrator przetwarza dane w zakresie niezbędnym do
                świadczenia poszczególnych usług oferowanych w Serwisie, a także
                informacje o aktywności Użytkownika w Serwisie. Szczegółowe
                zasady oraz cele przetwarzania danych osobowych gromadzonych
                podczas korzystania przez Użytkownika z Serwisu zostały
                określone w Klauzuli informacyjnej dotyczącej przetwarzania
                danych osobowych w serwisie i sklepie internetowym Jat-med. sp.
                z o.o. z siedzibą w Bydgoszczy dostępnej pod adresem
                <a
                  href="https://jat-med.com/policy/personal-data-processing"
                  aria-label="Klauzula informacyjna"
                >
                  https://jat-med.com/policy/personal-data-processing
                </a>
              </li>
            </ul>
          </li>
          <li>
            Prawa autorskie i licencja
            <ul>
              <li>
                Administrator oświadcza, że wszystkie materiały dostępne w
                Serwisie, w tym informacje, grafiki, artykuły, zdjęcia, Pliki,
                są objęte prawną ochroną przewidzianą w szczególności w ustawie
                z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych.
              </li>
              <li>
                Uzyskanie dostępu do Serwisu w ramach Konta oznacza udzielenie
                przez Administratora (Licencjodawca) Użytkownikowi
                (Licencjobiorca) niewyłącznej ograniczonej czasowo licencji bez
                prawa udzielania sublicencji, na korzystanie z materiałów
                zawartych w Serwisie internetowym wyłącznie dla własnych potrzeb
                Użytkownika, a także w przypadkach wyraźnie dozwolonych przez
                prawo oraz zgodnie z zasadami i warunkami określonymi w
                Regulaminie, za pośrednictwem sieci Internet.
              </li>
              <li>
                Licencja udzielona zostaje na czas określony, odpowiadający
                okresowi dostępu do danych treści w Serwisie.
              </li>
              <li>
                Licencja nie uprawnia do:
                <ol>
                  <li>
                    rozpowszechniania informacji pobranych z Serwisu, w tym w
                    szczególności na zasadach użyczenia, najmu, dzierżawy lub
                    sprzedaży,
                  </li>
                  <li>
                    udzielania dostępu do Serwisu osobom trzecim, w tym do
                    udostępniania hasła osobom trzecim, poza osobami wyraźnie
                    upoważnionymi przez Użytkownika,
                  </li>
                  <li>
                    utrwalania i zwielokrotniania danych pobranych z Serwisu, za
                    wyjątkiem sporządzania wydruków wyłącznie na własne
                    potrzeby,
                  </li>
                  <li>
                    przeniesienia praw wynikających z umowy na osobę trzecią.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Odpowiedzialność
            <ul>
              <li>
                Administrator dopełni wszelkiej należytej staranności, aby
                korzystanie z Serwisu przebiegało bez zakłóceń, bez usterek i
                niedogodności dla Użytkowników.
              </li>
              <li>
                Administrator nie ponosi jednak odpowiedzialności za zakłócenia
                w tym przerwy w funkcjonowaniu Serwisu spowodowane siłą wyższą,
                niedozwolonym działaniem osób trzecich lub niekompatybilnością
                Sklepu z infrastrukturą techniczną Użytkownika.
              </li>
              <li>
                Administrator zastrzega sobie możliwość wystąpienia
                krótkotrwałych przerw w funkcjonowaniu Serwisu, wynikających z
                niezbędnych napraw, konserwacji, tworzenia kopii zapasowych. W
                miarę możliwości, jeśli wymienione powyżej czynności nie
                wynikają z usterek, a z planowanych działań, Usługodawca
                zobowiązuje się informować Klientów o tym fakcie z podaniem
                przewidywanego czasu trwania braku możliwości korzystania z
                Serwisu.
              </li>
              <li>
                Użytkownik jest odpowiedzialny za bezpieczeństwo i zachowanie
                poufności swojego hasła do Konta. Administrator nie ponosi
                odpowiedzialności za nieuprawniony dostęp do Konta na skutek
                uzyskania dostępu do hasła przez osoby trzecie. W przypadku
                podejrzenia możliwości ujawnienia hasła osobom trzecim,
                Użytkownik zobowiązuje się niezwłocznie zmienić hasło lub
                skontaktować się w tym celu z Administratorem.
              </li>
              <li>
                Użytkownik ponosi pełną odpowiedzialność za złamanie prawa bądź
                szkodę wywołaną swoimi działaniami w Serwisie, w szczególności
                podaniem nieprawdziwych danych, ujawnieniem tajemnicy zawodowej,
                innej informacji poufnej lub tajemnicy przedsiębiorstwa,
                naruszeniem dóbr osobistych lub praw autorskich oraz praw
                pokrewnych.
              </li>
            </ul>
          </li>
          <li>
            Postanowienia końcowe
            <ul>
              <li>
                Regulamin jest dostępny na stronie Serwisu oraz w siedzibie
                Administratora. Regulamin – na żądanie Użytkownika przesłane
                pocztą elektroniczną na adres e-mail:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>{" "}
                – zostanie przekazany Użytkownikowi nieodpłatnie w wersji
                elektronicznej na podany przez Użytkownika adres e-mail.
              </li>
              <li>
                Administrator zastrzega sobie prawo do dokonywania zmian
                Regulaminu z ważnych przyczyn to jest: zmiany przepisów prawa,
                zmiany sposobów płatności i dostaw – w zakresie, w jakim te
                zmiany wpływają na realizację postanowień Regulaminu.
              </li>
              <li>
                O każdej zmianie Administratora poinformuje Użytkownika z co
                najmniej 14 dniowym wyprzedzeniem. W terminie 14 dni od dnia
                otrzymania informacji o zmianie Użytkownik może odmówić jej
                akceptacji – e-mailem przesłanym na adres{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>{" "}
                W takim przypadku Konto Użytkownika zostanie zlikwidowane w dniu
                wejścia w życie zmiany.
              </li>
              <li>
                Zakupy dokonane w Sklepie, które nastąpiły przed dniem wejścia w
                życie zmian podlegają zasadom dotychczasowym.
              </li>
              <li>
                Administrator zastrzega sobie prawo umieszczania treści
                reklamowych w witrynie, w formach zwyczajowo stosowanych w
                Internecie.
              </li>
              <li>
                W celu skorzystania z pozasądowych sposobów rozpatrywania
                reklamacji i dochodzenia roszczeń, Użytkownik jest uprawniony do
                zwrócenia się do właściwego ze względu na jego miejsce
                zamieszkania Wojewódzkiego Inspektoratu Inspekcji Handlowej z
                wnioskiem:
                <ol>
                  <li>
                    a) o wszczęcie postępowania mediacyjnego w sprawie
                    polubownego zakończenia sporu między Administratorem a
                    Użytkownikiem,
                  </li>
                  <li>
                    b) o rozstrzygnięcie sporu wynikłego z zawartej umowy
                    sprzedaży przed Stałym Polubownym Sądem Konsumenckim
                    działającym przy Wojewódzkim Inspektoracie Inspekcji
                    Handlowej.
                  </li>
                </ol>
              </li>
              <li>
                Szczegółowe informacje o pozasądowych sposobach rozpatrywania
                reklamacji i dochodzenia roszczeń, a także zasady dostępu do
                tych procedur dostępne są w siedzibach oraz na stronach
                internetowych powiatowych (miejskich) rzeczników konsumentów,
                Wojewódzkich Inspektoratów Inspekcji Handlowej oraz pod adresem
                strony internetowej Urzędu Ochrony Konkurencji i Konsumentów,
                tj. www.uokik.gov.pl/spory_konsumenckie.php .
              </li>
              <li>
                Użytkownik może również skorzystać z pozasądowych sposobów
                rozpatrywania reklamacji i dochodzenia roszczeń za pomocą
                unijnej platformy internetowej ODR (Online Dispute Resolution).
                Platforma dostępna jest pod adresem:{" "}
                <a href="http://ec.europa.eu/consumers/odr/">
                  http://ec.europa.eu/consumers/odr/
                </a>
                . W przypadku chęci skorzystania z wskazanego sposobu
                rozpatrzenia sprawy, prosimy o kontakt z Administratorem.
              </li>
              <li>
                W sprawach nieuregulowanych w Regulaminie mają zastosowanie
                powszechnie obowiązujące przepisy prawa polskiego, w
                szczególności: Kodeksu cywilnego; ustawy o świadczeniu usług
                drogą elektroniczną; ustawy o prawach konsumenta, ustawy o
                ochronie danych osobowych, RODO oraz inne właściwe przepisy
                prawa.
              </li>
              <li className={styles.notDecorated}>
                Załącznik Nr 1 Wzór formularza odstąpienia od umowy
                <ul>
                  <li className={styles.notDecorated}>
                    Adres gabinetu:
                    <ul>
                      <li>85-448 Bydgoszcz</li>
                      <li>Ul. Kruszyńska 17</li>
                      <li>Telefon 518 648 678</li>
                      <li>
                        <a href="mailto:jat-medcom2018@gmail.com">
                          jat-medcom2018@gmail.com
                        </a>
                      </li>
                      <li>Nr rachunku: 17 1240 1183 1111 0011 5020 1417</li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
      )}
      {i18next.resolvedLanguage === "en" && (
        <ol>
          <li>
            Introductory Provisions
            <ul>
              <li>
                The Terms and Conditions define the rules for the operation of
                the jat-med.com service, available at the Internet address{" "}
                <a href="https://jat-med.com">jat-med.com</a>, and for making
                purchases in the jat-med.com online store, available at the
                Internet address <a href="https://jat-med.com">jat-med.com</a>
              </li>
              <li>
                The service is operated by Jat-med sp. z o.o. with its
                registered office in Bydgoszcz at ul. Kruszyńska 17, entered
                into the register of entrepreneurs of the National Court
                Register maintained by the District Court in BYDGOSZCZ, XIII
                COMMERCIAL DIVISION OF THE NATIONAL COURT REGISTER, under KRS
                number 0001150821, NIP 9671483929, REGON 540725908.
              </li>
            </ul>
          </li>
          <li>
            Definitions
            <ul>
              <li>
                <b>Administrator</b> – Jat-med sp. z o.o. with its registered
                office in Bydgoszcz at 17 Kruszyńska Street, entered into the
                register of entrepreneurs of the National Court Register
                maintained by the District Court in BYDGOSZCZ, XIII COMMERCIAL
                DIVISION OF THE NATIONAL COURT REGISTER, under KRS number
                0001150821, NIP 9671483929, REGON 540725908.
              </li>
              <li>
                <b>Service</b> – an internet service operated by the
                Administrator at jat-med.com, sells Products, provides Files
                containing materials for the treatment and prevention of
                musculoskeletal disorders, and provides other services specified
                in the Regulations, including the possibility of concluding an
                Agreement for Online Consultations and Stationary Consultations.
              </li>
              <li>
                <b>Consumer</b> – a User who is a natural person concluding an
                Agreement with the Administrator within the Service, the subject
                of which is not directly related to the User's business or
                professional activity.
              </li>
              <li>
                <b>Entrepreneur</b> – a User who is not a Consumer.
              </li>
              <li>
                <b>Shop</b> – a separate part of the Service, an online store
                run by the Administrator at the Internet address{" "}
                <a href="https://jat-med.com">jat-med.com</a>
              </li>
              <li>
                <b>Distance contract</b> – a contract concluded with the Client
                as part of an organized system of concluding distance contracts
                (within the Service), without the simultaneous physical presence
                of the parties, with the exclusive use of one or more means of
                distance communication until the conclusion of the contract
                inclusive.
              </li>
              <li>
                <b>Regulations</b> – the regulations of the Jat-med.com Service
              </li>
              <li>
                <b>Order</b> – a declaration of will of the User submitted using
                the Order Form and aimed directly at concluding an Agreement
                with the Administrator.
              </li>
              <li>
                <b>Account</b> – the User's account in the Service, it collects
                data provided by the Client and information about the Orders
                placed by him in the Service.
              </li>
              <li>
                <b>Registration form</b> – a form available in the Service,
                enabling the creation of an Account.
              </li>
              <li>
                <b>Order from</b> – an interactive form available in the Service
                enabling the placement of an Order.
              </li>
              <li>
                <b>Basket</b> – an element of the Shop software, in which the
                Products selected by the User for purchase are visible, and it
                is also possible to determine and modify the Order data, in
                particular the quantity of products.
              </li>
              <li>
                <b>Online Consultation</b> – a service provided by the
                Administrator (providing consultation with a Specialist)
                electronically on the principles specified in the regulations of
                the service provided remotely “On-Line Consultation” available
                at:{" "}
                <a href="https://jat-med.com/policy/online-consultations">
                  https://jat-med.com/policy/online-consultations
                </a>
              </li>
              <li>
                <b>Stationary Consultation</b> – a service provided by the
                Administrator (providing consultation with a Specialist) in a
                stationary office on the principles specified in the regulations
                of the service provided stationary available at:{" "}
                <a href="https://jat-med.com/policy/in-person-consultations">
                  https://jat-med.com/policy/in-person-consultations
                </a>
              </li>
              <li>
                <b>Payment operator:</b>{" "}
                <a href="https://stripe.com">stripe.com</a>
              </li>
              <li>
                <b>Product</b> – a movable item (including a File) or service
                available in the Store that is the subject of the Sales
                Agreement between the User and the Administrator.
              </li>
              <li>
                <b>Sales Agreement</b> – a Product sales agreement concluded or
                entered into between the User and the Administrator via the
                Website. The Sales Agreement is also understood as – in relation
                to the features of the Product – a service provision agreement.
              </li>
              <li>
                <b>Online Consultation Agreement</b> – an agreement for the
                provision of services in the form of Online Consultation
                concluded or entered into between the User and the Administrator
                via the Website and provided under the terms and conditions
                specified in the regulations of the remote service “Online
                Consultation” available at:{" "}
                <a href="https://jat-med.com/policy/online-consultations">
                  https://jat-med.com/policy/online-consultations
                </a>
              </li>
              <li>
                Stationary Consultation Agreement – ​​an agreement for the
                provision of stationary services concluded or entered into
                between the User and the Administrator via the Website and
                provided under the terms and conditions specified in the
                Stationary Service regulations available at:{" "}
                <a href="https://jat-med.com/policy/in-person-consultations">
                  https://jat-med.com/policy/in-person-consultations
                </a>
              </li>
              <li>
                <b>Agreement</b> – ​​Sales Agreement, Online Consultation
                Agreement, Stationary Services Agreement.
              </li>
              <li>
                <b>Electronic Device</b> – Device intended, among others, for
                downloading and saving digital files (e.g. personal computer,
                smartphone, tablet, e-book reader).
              </li>
              <li>
                <b>Additional Services</b> – services provided by the
                Administrator to Users registered on the Website (i.e. having an
                Account).
              </li>
              <li>
                <b>User</b> – a natural person, legal person and organizational
                unit without legal personality, visiting the Website or using
                the services of the Website.
              </li>
            </ul>
          </li>
          <li>
            Contact with the Website
            <ul>
              <li>
                Address of the Administrator's business activity:
                <ul>
                  <li>85-448 Bydgoszcz ul. Kruszyńska 17</li>
                </ul>
              </li>
              <li>
                Administrator's e-mail address:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                Administrator's telephone number:{" "}
                <a href="tel:+48518468678">+48 518 648 678</a>
              </li>
              <li>
                Administrator's bank account number: 17 1240 1183 1111 0011 5020
                1417
              </li>
              <li>
                The User can communicate with the Administrator using the
                addresses and telephone numbers provided in this point.
              </li>
              <li>
                The User may communicate with the Administrator by phone between
                the hours: 9 a.m. and 3 p.m.
              </li>
            </ul>
          </li>
          <li>
            Technical requirements for using the Service and the services
            offered within it
            <ul>
              <li>
                To use the Service, including browsing the Store's assortment
                and placing Orders, the following are required:
              </li>
              <li>
                Electronic Device with access to the Internet and a web browser.
              </li>
              <li>active e-mail account,</li>
              <li>cookies enabled,</li>
              <li>FlashPlayer installed.</li>
              <li>
                The File Sale Agreement may be effectively implemented only
                using Electronic Devices that meet the technical requirements
                specified below.
              </li>
              <li>
                For files saved in ePUB, mobi and PDF formats:
                <ol>
                  <li>
                    PC:
                    <ul>
                      <li>
                        minimum requirements – Microsoft® Windows® XP operating
                        system with Service Pack 3, with Intel® Pentium® 500 MHz
                        processor, 128 MB RAM and a monitor with a minimum
                        resolution of 800 x 600 pixels.
                      </li>
                      <li>
                        the computer must have additional software installed
                        that allows you to open ePub, mobi, PDF files, e.g.
                        Adobe Reader;
                      </li>
                    </ul>
                  </li>
                  <li>
                    MAC computer
                    <ul>
                      <li>
                        PowerPC® processor: Mac OS X v 10.4.10 or higher
                        operating system installed with PowerPC® G4 or G5 500
                        MHz processor, 128 MB RAM or Intel® processor;
                      </li>
                    </ul>
                  </li>
                  <li>e-book reader;</li>
                  <li>
                    tablet or smartphone with iOS, Android, Windows 8 (or
                    higher) installed and an application that supports epub,
                    mobi, pdf files, e.g. BlueFire Reader;
                  </li>
                </ol>
              </li>
              <li>
                For products saved in MP3 format:
                <ol>
                  <li>
                    computer equipped with devices that allow sound emission
                    using speakers/headphones or other similar devices, with
                    installed software for reading MP3 files, e.g. Winamp,
                    Windows Media Player;
                  </li>
                  <li>
                    any music file reader that allows you to play files saved in
                    MP3 format;
                  </li>
                  <li>
                    a tablet or smartphone with an application installed that
                    allows you to play files saved in MP3 format
                  </li>
                </ol>
              </li>
              <li>
                For products saved in MP4 format, a computer with software
                installed to play movie files, e.g. Windows Media Player, or any
                portable device that allows you to play video files in MP4
                format
              </li>
              <li>
                Each File offered in the Store is accompanied by information
                containing the characteristics of the File (ePUB, PDF, Mobi,
                MP3, MP4), the volume of the File and its format. Before placing
                an Order, the User should check whether the Electronic Device
                intended for downloading a given File meets the hardware
                requirements specified in points 4.1-4.5 and has sufficient
                memory to save the File on the Electronic Device.
              </li>
              <li>
                Technical requirements necessary to use Additional Services:
                <ul>
                  <li>
                    a computer with a processor - Intel® Pentium® 500 MHz (or
                    AMD), equipped with at least 128 MB of RAM, with the
                    Windows® XP operating system or higher installed, Mac OS X
                    (updated operating system with the ability to download and
                    install software), an updated version of an Internet browser
                    and an efficient Internet connection with a minimum speed of
                    1Mb/s.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Creating an Account on the Service
            <ul>
              <li>
                To register an Account on the Service, you must complete the
                Registration Form. The following data must be provided: name,
                surname, country, street, house and apartment number, postal
                code, city, telephone number, e-mail address. It is also
                possible to register using Facebook or Google.
              </li>
              <li>Registering an Account on the Website is free of charge.</li>
              <li>
                Logging in to the Account is done by providing the login and
                password set in the Registration Form.
              </li>
              <li>
                The Customer may at any time, without giving a reason and
                without incurring any fees, delete the Account by sending an
                appropriate request to the Administrator, in particular via
                e-mail or in writing to the addresses provided in point 3.
              </li>
            </ul>
          </li>
          <li>
            Rules for using the Store
            <ul>
              <li>
                Browsing the Store's assortment does not require creating an
                Account. Placing orders by the User for Products in the Store's
                assortment is possible either after creating an Account in
                accordance with the provisions of point 5 of the Regulations or
                by providing the necessary personal and address data enabling
                the execution of the Order without creating an Account.
              </li>
              <li>The Sales Agreement covers new Products.</li>
              <li>
                The final (final) amount to be paid by the User consists of the
                price for the Product and the cost of delivery (including
                transport, delivery and postal fees), about which the User is
                informed on the Store's pages when placing an Order, including
                when expressing the will to be bound by the Sales Agreement.
              </li>
            </ul>
          </li>
          <li>
            Rules for placing an Order
            <ul>
              <li>
                The User may place Orders on the Service's website, 24 hours a
                day, 7 days a week. Orders are processed on working days,
                between 8:00 and 16:00.
              </li>
              <li>
                In order to place an Order for a Product, you must:
                <ol>
                  <li>log in to the Service (optional);</li>
                  <li>
                    select the Product that is the subject of the Order, and
                    then click the "Add to cart" button (or equivalent);
                  </li>
                  <li>
                    log in or use the option to place an Order without
                    registration;
                  </li>
                  <li>
                    if the option to place an Order without registration has
                    been selected – fill out the Order Form by entering the
                    details of the Order recipient and the address to which the
                    Product is to be delivered, select the type of shipment
                    (method of delivery of the Product), enter the invoice
                    details, if different from the details of the Order
                    recipient,
                  </li>
                  <li>
                    click the “Order and pay” button and confirm the order by
                    clicking on the link sent in the e-mail.
                  </li>
                </ol>
              </li>
              <li>
                In order to place an Order for Online Consultations and
                Stationary Services, you must:
                <ol>
                  <li>
                    log in or use the option to place an Order without
                    registration;
                  </li>
                  <li>
                    fill out the Order Form by entering the details of the Order
                    recipient: name and surname, mobile phone number, e-mail
                    address and the purpose of the consultation; regardless of
                    the above, at a later stage the User will be required to
                    provide other data necessary to complete the consultation
                    and services.
                  </li>
                  <li>
                    click the “Order and pay” button and confirm the order by
                    clicking on the link sent in the e-mail,
                  </li>
                </ol>
              </li>
              <li>
                Finalisation of the Order requires the selection of one of the
                available payment methods and, depending on the payment method,
                pay for the order within a specified period, subject to point 8
                and 9.
              </li>
              <li>
                The Administrator reserves the right to withdraw from the
                execution of the Order of the User who has not provided all the
                required data, including the data required under the Act on
                Patient Rights and the Patient Advocate for the purpose of
                maintaining medical records in connection with health care
                services, or the data provided raises doubts as to their
                correctness. An order that cannot be confirmed within 10 days of
                its placement may not be executed.
              </li>
              <li>
                By placing an order, the Customer:
                <ol>
                  <li>
                    agrees to the issuance of a VAT invoice without the
                    recipient's signature,
                  </li>
                  <li>
                    declares that he has read the Regulations and other
                    regulations available on the Website, including the Privacy
                    Policy and accepts their provisions.
                  </li>
                </ol>
              </li>
              <li>
                All prices given on the Website are given in Polish zloty and
                are gross prices.
              </li>
            </ul>
          </li>
          <li>
            Offered delivery and payment methods
            <ul>
              <li>
                The Customer may use the following methods of delivery or
                collection of the ordered Product:
                <ol>
                  <li>courier delivery.</li>
                  <li>
                    personal collection available at the following address:
                    85-448 Bydgoszcz ul. Kruszyńskka 17
                  </li>
                </ol>
              </li>
              <li>
                Personal collection is possible after prior arrangement of the
                date: day and time of collection of the parcel. For this
                purpose, the Customer must always arrange the details of
                personal collection by direct contact with the Seller.
              </li>
              <li>
                The Customer may use the following payment methods:
                <ol>
                  <li>
                    payment by bank transfer to the Administrator's account;
                  </li>
                  <li>
                    payment made electronically via the service provided by the
                    Payment Operator (on the terms specified by the Payment
                    Operator) in the form of: electronic transfer or charging
                    the User's payment card (list of accepted payment cards:
                    Visa/Visa Electron, MasterCard/MasterCard Electronic,
                    Maestro, American Express).
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          {/*  */}
          <li>
            Execution of the Sales Agreement, Online Consultation, Stationary
            Service
            <ul>
              <li>
                The conclusion of the Sales Agreement and Online Consultation
                Agreement between the User and the Administrator takes place
                after the User has previously placed an Order in accordance with
                point 7
              </li>
              <li>
                After placing the Order, the Administrator immediately confirms
                its receipt and simultaneously accepts the Order for execution.
              </li>
              <li>
                Confirmation of receipt of the Order and its acceptance for
                execution takes place by the Administrator sending an
                appropriate e-mail message to the User to the User's e-mail
                address provided when placing the Order. The message contains at
                least the Administrator's declaration of receipt of the Order
                and its acceptance for execution and confirmation of the
                conclusion of the Sales Agreement. Upon receipt by the Customer
                of the above e-mail message, an Agreement is concluded between
                the User and the Administrator.
              </li>
              <li>
                In the event that the Customer chooses:
                <ol>
                  <li>
                    electronic payments or payment by payment card, the User is
                    obliged to make the payment on the day of conclusion of the
                    Sales Agreement - otherwise the order will be canceled;
                  </li>
                  <li>
                    cash on delivery upon receipt of the shipment, the User is
                    obliged to make the payment upon receipt of the shipment -
                    in the event of failure to make the payment, the shipment
                    will not be issued to the User;
                  </li>
                  <li>
                    cash payment upon personal collection of the shipment, the
                    User is obliged to make the payment upon receipt of the
                    shipment at the collection point on the day of receipt of
                    the shipment - in the event of failure to make the payment,
                    the shipment will not be issued to the User.
                  </li>
                </ol>
              </li>
              <li>
                In the event of choosing payment by bank transfer, the following
                procedure applies:
                <ol>
                  <li>
                    after placing the order, the User receives an Order
                    confirmation, based on which the User makes a payment to the
                    Administrator's account within two days of receiving it,
                  </li>
                  <li>
                    the Order confirmation contains the order number, its value
                    and the Administrator's bank account number.
                  </li>
                  <li>
                    The User is obliged to provide the following content on the
                    transfer form: "User's name and surname, Order number […[".
                  </li>
                </ol>
              </li>
              <li>
                If the User has chosen a delivery method other than personal
                collection, the Product will be sent by the Administrator within
                the time specified in its description (subject to point 6), in
                the manner selected by the User when placing the Order.
                <ol>
                  <li>
                    In the event of ordering Products with different delivery
                    dates, the delivery date is the longest given date.
                  </li>
                  <li>
                    In the event of ordering Products with different delivery
                    dates, the Customer has the option of requesting delivery of
                    the Products in parts or delivery of all Products after
                    completing the entire order.
                  </li>
                </ol>
              </li>

              <li>
                The beginning of the delivery period of the Product to the User
                is counted as follows:
                <ol>
                  <li>
                    in the event that the User chooses payment by bank transfer,
                    electronic payment or payment card - from the date of
                    crediting the Administrator's bank account.
                  </li>
                  <li>
                    in the event that the User chooses payment by cash on
                    delivery - from the date of conclusion of the Sales
                    Agreement,
                  </li>
                  <li>
                    in the event that the User chooses personal collection of
                    the Product, the Product will be ready for collection by the
                    User on the date indicated in the Product description. The
                    User will be additionally informed by the Administrator
                    about the readiness of the Product for collection by sending
                    an appropriate e-mail message to the User's e-mail address
                    provided when placing the Order.
                  </li>
                </ol>
              </li>
              <li>
                The beginning of the period of readiness of the Product for
                collection by the User is counted as follows:
                <ol>
                  <li>
                    in the event that the User chooses the method of payment by
                    bank transfer, electronic payment or payment card - from the
                    date of crediting the Administrator's bank account;
                  </li>
                  <li>
                    in the event that the User chooses the method of cash upon
                    personal collection - from the date of conclusion of the
                    Sales Agreement.
                  </li>
                </ol>
              </li>
              <li>
                In the case of Products that are Files:
                <ol>
                  <li>
                    payment of the Order by the User results in automatic (no
                    later than 15 minutes from the moment of receiving payment
                    confirmation from the Payment Operator or crediting the
                    Administrator's bank account) making the ordered File
                    available, by sending the User, to the email address
                    provided by him, an email with a link to the website from
                    which it will be possible to download the File, or directly
                    sending it to the email address provided during the
                    purchase. In special cases (e.g. due to a server failure),
                    the File may be made available later than 15 minutes from
                    the moment of Payment of the Order;
                  </li>
                  <li>
                    from the moment the File is made available, it can be
                    downloaded by the User, whereby downloading this File
                    excludes the possibility of withdrawing from the agreement
                    concerning this File – on the principles specified in points
                    10 and 11.
                  </li>
                </ol>
              </li>
              <li>
                Delivery of the Product (which is not a File) takes place only
                in the territory of
              </li>
              <li>
                Delivery of the Product which is not a File to the Customer is
                subject to payment, unless the Sales Agreement provides
                otherwise. The costs of delivery of the Product (including
                transport, delivery and postal services) are indicated to the
                Customer on the Online Store pages in the "Delivery costs" tab
                and during the Order placement, as well as on selected product
                pages.
              </li>
              <li>
                Personal collection of the Product by the Customer is free of
                charge.
              </li>
              <li>
                Fulfillment of the Online Consultation Agreement is specified in
                the regulations of the service provided electronically "Online
                Consultation" available at:{" "}
                <a href="https://jat-med.com/policy/online-consultations">
                  https://jat-med.com/policy/online-consultations
                </a>
              </li>
              <li>
                A VAT invoice will be issued and sent together with the ordered
                Products (other than Files downloaded via the Internet). In the
                case of Files and Online Consultations, a VAT invoice will be
                issued and sent to the User to the email address provided by
                them within 7 days from the date of crediting the
                Administrator's bank account.
              </li>
            </ul>
          </li>
          <li>
            The right of withdrawal from the Agreement by the Consumer
            <ul>
              <li>
                In accordance with the wording of art. 27 of the Act of 30 May
                2014 on Consumer Rights, each User who is also a Consumer has
                the right to withdraw from a distance contract within 14 days,
                without giving a reason.
              </li>
              <li>
                The period specified in point 1 begins with the delivery of the
                Product to the Consumer or a person indicated by them other than
                the carrier.
              </li>
              <li>
                In the case of an Agreement that covers many Products that are
                delivered separately, in batches or in parts, the period
                specified in point 1 begins with the delivery of the last item,
                batch or part.
              </li>
              <li>
                The Consumer may withdraw from the Agreement by submitting to
                the Administrator an unequivocal declaration of intent to
                withdraw from the Agreement. To meet the deadline for withdrawal
                from the Agreement, it is sufficient for the Consumer to send a
                declaration before the expiry of this period.
              </li>
              <li>
                The declaration may be sent by traditional mail or
                electronically by sending the declaration to the Administrator's
                e-mail address specified in point 2. The declaration may also be
                submitted on a form, the template of which constitutes Annex No.
                1 to the Regulations or Annex to the Act of 30 May 2014 on
                consumer rights, however, this is not obligatory.
              </li>
              <li>
                In the event that the declaration is sent by the Consumer
                electronically, the Administrator shall immediately send the
                Consumer a confirmation of receipt of the declaration of
                withdrawal from the Agreement to the e-mail address provided by
                the Consumer.
              </li>
              <li>
                The right to withdraw from the Agreement concluded remotely does
                not apply to the Consumer in relation to, among others, the
                Agreement:
                <ol>
                  <li>
                    for the provision of digital content, including Files that
                    are not recorded on a tangible medium, if the performance of
                    the service began with the express consent of the consumer
                    before the expiry of the period for withdrawal from the
                    agreement and after the entrepreneur informed him of the
                    loss of the right to withdraw from the Agreement;
                  </li>
                  <li>
                    provision of services, if the Administrator has fully
                    performed the service with the express consent of the
                    Consumer, who was informed before the commencement of the
                    provision that after the Administrator has performed the
                    provision, he will lose the right to withdraw from the
                    Agreement;
                  </li>
                  <li>
                    in which the subject of the provision is an item delivered
                    in a sealed package, which cannot be returned after opening
                    the package due to health protection or hygiene reasons, if
                    the package was opened after delivery;
                  </li>
                  <li>
                    in which the subject of the provision is a non-prefabricated
                    item, manufactured according to the Consumer's
                    specifications or intended to meet his individual needs.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Effects of withdrawal from the Agreement by the Consumer
            <ul>
              <li>
                In the event of withdrawal from the Agreement concluded at a
                distance, the Agreement is deemed not to have been concluded.
              </li>
              <li>
                In the event of withdrawal from the Agreement, the Administrator
                shall return to the Consumer immediately, no later than within
                14 days from the date of receipt of the Consumer's declaration
                of withdrawal from the Agreement, all payments made by him,
                including the costs of delivering the Product, with the
                exception of additional costs resulting from the Consumer's
                choice of a delivery method other than the cheapest standard
                delivery method offered by the Administrator.
              </li>
              <li>
                The Administrator shall return the payments using the same
                payment methods that were used by the Consumer in the original
                transaction, unless the Consumer has expressly agreed to another
                solution that will not involve any costs for him.
              </li>
              <li>
                The Administrator may withhold the return of the payment until
                the Product is received back or until proof of its return is
                provided to him, depending on which event occurs first.
              </li>
              <li>
                The Consumer should return the Product to the Administrator's
                address specified in the Regulations immediately, no later than
                14 days from the day on which he informed the Administrator of
                the withdrawal from the Agreement. The deadline will be met if
                the Consumer returns the Product before the expiry of the 14-day
                period.
              </li>
              <li>
                The consumer bears the direct costs of returning the Product,
                including the costs of returning the Product if, due to its
                nature, the Product could not be returned by post in the usual
                manner.
              </li>
              <li>
                The consumer is only liable for the reduction in the value of
                the Product resulting from its use in a manner other than
                necessary to establish the nature, characteristics and
                functioning of the Product.
              </li>
              <li>
                If, due to the nature of the Product, it cannot be returned by
                regular mail, information about this, as well as the costs of
                returning the Product, will be included in the Product
                description in the Store.
              </li>
            </ul>
          </li>
          <li>
            Complaints and warranty
            <ul>
              <li>
                Each User may submit complaints regarding any services listed in
                the Regulations (including defects and faults of Files) based on
                the provisions regarding warranty in the Civil Code. If the
                Customer is an Entrepreneur, the parties exclude liability under
                warranty.
              </li>
              <li>
                Complaints should be submitted in writing or electronically to
                the Administrator's addresses provided in the Regulations.
              </li>
              <li>
                It is recommended that the complaint include, among other
                things, a concise description of the defect, the circumstances
                (including the date) of its occurrence, the data of the User
                submitting the complaint, and the Customer's request in
                connection with the defect of the goods.
              </li>
              <li>
                The Administrator will respond to the complaint request
                immediately, no later than within 14 days, and if it does not do
                so within this period, it is considered that the Customer's
                request was considered justified.
              </li>
              <li>
                Products returned under the complaint procedure should be sent
                to the address provided in point 3 of the Regulations.
              </li>
            </ul>
          </li>
          <li>
            Processing of personal data
            <ul>
              <li>
                In connection with the User's use of the Service, the
                Administrator processes data to the extent necessary to provide
                individual services offered on the Service, as well as
                information about the User's activity on the Service. Detailed
                principles and purposes of processing personal data collected
                during the User's use of the Service have been specified in the
                Information Clause regarding the processing of personal data on
                the website and online store of Jat-med. sp. z o.o. with its
                registered office in Bydgoszcz, available at{" "}
                <a href="https://jat-med.com/policy/personal-data-processing">
                  https://jat-med.com/policy/personal-data-processing
                </a>
              </li>
            </ul>
          </li>
          <li>
            Copyright and license
            <ul>
              <li>
                The Administrator declares that all materials available on the
                Service, including information, graphics, articles, photos,
                Files, are subject to legal protection provided for in
                particular in the Act of 4 February 1994 on copyright and
                related rights.
              </li>
              <li>
                Obtaining access to the Service within the Account means that
                the Administrator (Licensor) grants the User (Licensee) a
                non-exclusive, time-limited license without the right to grant
                sublicenses, to use the materials contained in the Internet
                Service exclusively for the User's own needs, and also in cases
                expressly permitted by law and in accordance with the principles
                and conditions specified in the Regulations, via the Internet.
              </li>
              <li>
                The license is granted for a specified period, corresponding to
                the period of access to the given content on the Service.
              </li>
              <li>
                The license does not entitle to:
                <ol>
                  <li>
                    disseminating information downloaded from the Service,
                    including in particular on the basis of lending, renting,
                    leasing or sale,
                  </li>
                  <li>
                    granting access to the Service to third parties, including
                    sharing the password with third parties, except for persons
                    expressly authorized by the User,
                  </li>
                  <li>
                    recording and multiplying data downloaded from the Service,
                    except for making printouts exclusively for your own needs,
                  </li>
                  <li>
                    transfer of rights resulting from the agreement to a third
                    party.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Liability
            <ul>
              <li>
                The Administrator will exercise all due diligence to ensure that
                the use of the Service is uninterrupted, without faults and
                inconveniences for Users. However, the Administrator is not
                responsible for disruptions, including interruptions in the
                functioning of the Service caused by force majeure, unauthorized
                actions of third parties or incompatibility of the Store with
                the User's technical infrastructure.
              </li>
              <li>
                The Administrator reserves the possibility of short-term
                interruptions in the functioning of the Service resulting from
                necessary repairs, maintenance, and backups. To the extent
                possible, if the above-mentioned activities do not result from
                faults but from planned activities, the Service Provider
                undertakes to inform Customers about this fact, stating the
                expected duration of the inability to use the Service.
              </li>
              <li>
                The User is responsible for the security and confidentiality of
                their Account password. The Administrator is not responsible for
                unauthorized access to the Account as a result of third parties
                gaining access to the password. In the event of suspicion of the
                possibility of disclosing the password to third parties, the
                User undertakes to immediately change the password or contact
                the Administrator for this purpose.
              </li>
              <li>
                The User bears full responsibility for breaking the law or
                damage caused by their actions on the Service, in particular
                providing false data, disclosing professional secrets, other
                confidential information or business secrets, infringement of
                personal rights or copyrights and related rights.
              </li>
            </ul>
          </li>
          <li>
            Final provisions
            <ul>
              <li>
                The Regulations are available on the Service website and at the
                Administrator's office. The Regulations - at the User's request
                sent by e-mail to the e-mail address:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>{" "}
                - will be provided to the User free of charge in electronic form
                to the e-mail address provided by the User.
              </li>
              <li>
                The Administrator reserves the right to make changes to the
                Regulations for important reasons, i.e. changes in legal
                regulations, changes in payment and delivery methods - to the
                extent to which these changes affect the implementation of the
                provisions of the Regulations.
              </li>
              <li>
                The Administrator will inform the User of any change at least 14
                days in advance. Within 14 days of receiving information about
                the change, the User may refuse to accept it - by e-mail sent to{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
                . In such a case, the User Account will be closed on the day the
                change comes into effect.
              </li>
              <li>
                Purchases made in the Store that occurred before the date the
                change comes into effect are subject to the previous rules.
              </li>
              <li>
                The Administrator reserves the right to place advertising
                content on the website, in forms customarily used on the
                Internet.
              </li>
              <li>
                In order to use out-of-court methods of handling complaints and
                pursuing claims, the User is entitled to contact the Provincial
                Inspectorate of Trade Inspection competent for their place of
                residence with a request:
                <ol>
                  <li>
                    a) to initiate mediation proceedings on the amicable
                    settlement of the dispute between the Administrator and the
                    User,
                  </li>
                  <li>
                    b) to resolve the dispute arising from the concluded sales
                    agreement before the Permanent Consumer Arbitration Court
                    operating at the Provincial Inspectorate of Trade
                    Inspection.
                  </li>
                </ol>
              </li>
              <li>
                Detailed information on out-of-court complaint and claim
                settlement methods, as well as the rules of access to these
                procedures are available at the offices and on the websites of
                district (municipal) consumer ombudsmen, Voivodship
                Inspectorates of Trade Inspection and at the website of the
                Office of Competition and Consumer Protection, i.e.{" "}
                www.uokik.gov.pl/spory_konsumenckie.php .
              </li>
              <li>
                The User may also use out-of-court complaint and claim
                settlement methods using the EU online platform ODR (Online
                Dispute Resolution). The platform is available at:{" "}
                <a href="http://ec.europa.eu/consumers/odr/">
                  http://ec.europa.eu/consumers/odr/
                </a>
                . If you wish to use the indicated method of handling a case,
                please contact the Administrator.
              </li>
              <li>
                In matters not regulated in the Regulations, the generally
                applicable provisions of Polish law shall apply, in particular:
                the Civil Code; the Act on the provision of services by
                electronic means; the Act on consumer rights, the Act on the
                protection of personal data, the GDPR and other relevant
                provisions of law.
              </li>
              <li className={styles.notDecorated}>
                Appendix No. 1 Sample withdrawal form
                <ul>
                  <li>
                    Office address:
                    <ul>
                      <li>85-448 Bydgoszcz</li>
                      <li>Ul. Kruszyńska 17</li>
                    </ul>
                  </li>
                  <li>
                    Phone: <a href="tel:+48518468678">518 648 678</a>
                  </li>
                  <li>
                    Email:{" "}
                    <a href="mailto:jat-medcom2018@gmail.com">
                      jat-medcom2018@gmail.com
                    </a>
                  </li>
                  <li>Account number: 17 1240 1183 1111 0011 5020 1417</li>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
      )}
      {i18next.resolvedLanguage === "uk" && (
        <ol>
          <li>
            Вступні положення
            <ul>
              <li>
                Положення визначає правила роботи сайту jat-med.com, доступного
                за адресою в мережі Інтернет{" "}
                <a href="https://jat-med.com">jat-med.com</a>, та здійснення
                покупок в інтернет-магазині jat-med.com, доступного за адресою в
                мережі Інтернет <a href="https://jat-med.com">jat-med.com</a>.
              </li>
              <li>
                Сайтом керує Jat-med sp.o.o. з зареєстрованим офісом у м. Бидгощ
                на вул. Крушинська 17, внесений до реєстру підприємців
                Національного судового реєстру, який веде Окружний суд у
                БИДГОЩІ, XIII КОМЕРЦІЙНИЙ ВІДДІЛ НАЦІОНАЛЬНОГО СУДОВОГО РЕЄСТРУ,
                під номером KRS 0001150821, NIP 9671483929, REGON 540725908.
              </li>
            </ul>
          </li>
          <li>
            Визначення
            <ul>
              <li>
                <b>Адміністратор</b> – Jat-med sp. з зареєстрованим офісом у м.
                Бидгощ на вул. Крушинська 17, внесений до реєстру підприємців
                Національного судового реєстру, який веде Окружний суд у
                БИДГОЩІ, XIII КОМЕРЦІЙНИЙ ВІДДІЛ НАЦІОНАЛЬНОГО СУДОВОГО РЕЄСТРУ,
                під номером KRS 0001150821, NIP 9671483929, REGON 540725908.
              </li>
              <li>
                <b>Веб-сайт</b> – веб-сайт, керований Адміністратором за адресою{" "}
                <a href="https://jat-med.com">jat-med.com</a>, який реалізує
                Продукцію, надає Файли, що містять матеріали для лікування та
                профілактики захворювань опорно-рухового апарату, а також надає
                інші послуги, зазначені в Регламенті, включаючи можливість
                укладення Договору на онлайн-консультації та стаціонарні
                консультації.
              </li>
              <li>
                <b>Споживач</b> – Користувач, який є фізичною особою, що укладає
                з Адміністратором в рамках Сайту Договір, предмет якого
                безпосередньо не пов’язаний з підприємницькою чи професійною
                діяльністю Користувача.
              </li>
              <li>
                <b>Підприємець</b> – Користувач, який не є Споживачем.
              </li>
              <li>
                <b>Магазин</b> – окрема частина Сервісу, інтернет-магазин
                Адміністратора за адресою в Інтернеті{" "}
                <a href="https://jat-med.com">jat-med.com</a>
              </li>
              <li>
                <b>Дистанційний договір</b> – договір, укладений із Замовником в
                рамках організованої дистанційної системи укладання контрактів
                (в межах Веб-сайту), без одночасної фізичної присутності сторін,
                з виключним використанням одного або кількох засобів
                дистанційного зв’язку до моменту укладення договору.
              </li>
              <li>
                <b>Регламент</b> – регламент сайту Jat-med.com
              </li>
              <li>
                <b>Замовлення</b> – волевиявлення Користувача, подане через
                Форму замовлення і спрямоване безпосередньо на укладення
                Договору з Адміністратором.
              </li>
              <li>
                <b>Обліковий запис</b> – обліковий запис користувача на
                Веб-сайті, який збирає дані, надані Клієнтом, та інформацію про
                Замовлення, розміщені ним на Веб-сайті.
              </li>
              <li>
                <b>Реєстраційна форма</b> – доступна на Веб-сайті форма, що
                дозволяє створити обліковий запис.
              </li>
              <li>
                <b>Форма замовлення</b> – інтерактивна форма, доступна на
                Веб-сайті, що дозволяє оформити замовлення.
              </li>
              <li>
                <b>Кошик</b> – елемент програмного забезпечення Магазину, в
                якому видно Товари, обрані Користувачем для покупки, а також
                можна визначити та змінити дані Замовлення, зокрема кількість
                товарів.
              </li>
              <li>
                <b>Онлайн-консультація</b> – послуга, що надається
                Адміністратором (забезпечення консультації спеціаліста) в
                електронному вигляді на умовах, визначених регламентом
                дистанційної послуги «Он-лайн консультація» за адресою:{" "}
                <a href="https://jat-med.com/policy/online-consultations">
                  jat-med.com/policy/online-consultations
                </a>
              </li>
              <li>
                <b>Стаціонарна консультація</b> – Послуга, що надається
                Адміністратором (надання консультації спеціаліста) в стаціонарі
                на умовах, визначених положенням про стаціонарне обслуговування,
                доступним за посиланням:{" "}
                <a href="https://jat-med.com/policy/in-person-consultations">
                  jat-med.com/policy/in-person-consultations
                </a>
              </li>
              <li>
                <b>Процесор платежів:</b>{" "}
                <a href="https://stripe.com">stripe.com</a>
              </li>
              <li>
                <b>Продукт</b> – рухомий об’єкт (включаючи Файл) або послуга,
                доступна в Магазині, яка є предметом Угоди купівлі-продажу між
                Користувачем і Адміністратором.
              </li>
              <li>
                <b>Договір купівлі-продажу</b> – договір купівлі-продажу Товару,
                який укладається або укладається між Користувачем і
                Адміністратором через Веб-сайт. Договір купівлі-продажу також
                означає – стосовно характеристик Продукту – договір про надання
                послуг.
              </li>
              <li>
                <b>Договір про онлайн-консультацію</b> – угода про надання
                послуг у формі онлайн-консультації, яка укладається або
                укладається між Користувачем та Адміністратором через Веб-сайт
                та надається на умовах, визначених регламентом дистанційної
                послуги «Онлайн-консультація», доступним за адресою:{" "}
                <a href="https://jat-med.com/policy/online-consultations">
                  https://jat-med.com/policy/online-consultations
                </a>
              </li>
              <li>
                <b>Договір стаціонарної консультації</b> – угода про надання
                стаціонарних послуг, яка укладається або укладається між
                Користувачем та Адміністратором через Веб-сайт і надається на
                умовах, визначених положенням про стаціонарну послугу, доступним
                за посиланням:{" "}
                <a href="https://jat-med.com/policy/in-person-consultations">
                  https://jat-med.com/policy/in-person-consultations
                </a>
              </li>
              <li>
                <b>Договір</b> – Договір купівлі-продажу, Договір про Договір –
                Договір купівлі-продажу, Договір про онлайн-консультацію,
                Договір про стаціонарні послуги.
              </li>
              <li>
                <b>Електронний пристрій</b> – пристрій, призначений, серед
                іншого, для завантаження та збереження цифрових файлів
                (наприклад, персональний комп’ютер, смартфон, планшет, пристрій
                для читання електронних книг).
              </li>
              <li>
                <b>Додаткові послуги</b> – послуги, які Адміністратор надає
                Користувачам, зареєстрованим на Веб-сайті (тобто мають Обліковий
                запис).
              </li>
              <li>
                <b>Користувач</b> – фізична особа, юридична особа та
                організаційна одиниця без статусу юридичної особи, які
                відвідують Веб-сайт або використовують послуги Веб-сайту.
              </li>
            </ul>
          </li>
          <li>
            Звернення до Сервісу
            <ul>
              <li>
                Адреса провадження діяльності Адміністратора: 85-448 Бидгощ вул.
                Крушинська 17
              </li>
              <li>
                Електронна адреса адміністратора:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                Телефон адміністратора:{" "}
                <a href="tel:+48518468678">+48 518 648 678</a>
              </li>
              <li>
                Номер банківського рахунку адміністратора: 17 1240 1183 1111
                0011 5020 1417
              </li>
              <li>
                Користувач може зв'язатися з Адміністратором за адресами та
                номерами телефонів, вказаними в цьому пункті.
              </li>
              <li>
                Користувач може спілкуватися з Адміністратором по телефону в
                такі години: 9 – 15.
              </li>
            </ul>
          </li>
          <li>
            Технічні вимоги до використання Сервісу і послуги, які пропонуються
            в ньому
            <ul>
              <li>
                Для користування Веб-сайтом, включаючи перегляд асортименту
                Магазину та оформлення Замовлень, ви повинні:
                <ol>
                  <li>
                    Електронний пристрій з доступом до Інтернету та
                    веб-браузером.
                  </li>
                  <li>активний обліковий запис електронної пошти,</li>
                  <li>файли cookie включені,</li>
                  <li>Встановлено FlashPlayer.</li>
                </ol>
              </li>
              <li>
                Угоду про продаж файлів можна ефективно виконати лише за
                допомогою електронних пристроїв, які відповідають технічним
                вимогам, зазначеним нижче.
              </li>
              <li>
                Для файлів, збережених у форматах ePUB, mobi та PDF:
                <ol>
                  <li>
                    ПК:
                    <ul>
                      <li>
                        Мінімальні вимоги – Microsoft® Windows® XP із
                        встановленим пакетом оновлень 3, процесор Intel®
                        Pentium® 500 МГц, 128 МБ оперативної пам’яті та монітор
                        із мінімальною роздільною здатністю 800 x 600 пікселів.
                      </li>
                      <li>
                        на комп’ютері має бути встановлено додаткове програмне
                        забезпечення, яке дозволяє відкривати файли ePub, mobi,
                        PDF, наприклад Adobe Reader;
                      </li>
                    </ul>
                  </li>
                  <li>
                    Комп'ютер MAC
                    <ul>
                      <li>
                        Процесор PowerPC®: Mac OS X v10.4.10 або вище з
                        процесором PowerPC® G4 або G5 500 МГц, 128 МБ
                        оперативної пам’яті або процесором Intel®;
                      </li>
                    </ul>
                  </li>
                  <li>пристрій для читання електронних книг;</li>
                  <li>
                    планшет або смартфон із встановленою ОС iOS, Android,
                    Windows 8 (або вище) і програмою, яка підтримує файли epub,
                    mobi, pdf, наприклад, BlueFire Reader;
                  </li>
                </ol>
              </li>
              <li>
                Для продуктів, збережених у форматі MP3:
                <ol>
                  <li>
                    комп’ютер, обладнаний пристроями, які дозволяють
                    випромінювати звук через динаміки/навушники або інші подібні
                    пристрої, з інстальованим програмним забезпеченням для
                    читання MP3-файлів, наприклад, Winamp, Windows Media Player;
                  </li>
                  <li>
                    будь-яка програма для читання музичних файлів, що дозволяє
                    відтворювати файли, збережені у форматі MP3;
                  </li>
                  <li>
                    планшет або смартфон із встановленою програмою, що дозволяє
                    відтворювати файли, збережені у форматі MP3
                  </li>
                </ol>
              </li>
              <li>
                Для продуктів, збережених у форматі MP4, комп’ютер із
                встановленим програмним забезпеченням для відтворення
                відеофайлів, наприклад Windows Media Player або будь-який
                портативний пристрій, який може відтворювати відеофайли MP4
              </li>
              <li>
                Кожен файл, пропонований у Магазині, супроводжується
                інформацією, яка містить характеристики файлу (ePUB, PDF, Mobi,
                MP3, MP4), обсяг файлу та його формат. Перед розміщенням
                Замовлення Користувач повинен перевірити, чи відповідає
                Електронний пристрій, призначений для завантаження даного Файлу,
                апаратним вимогам, зазначеним у п. 4.1-4.5 і має достатньо
                пам’яті для збереження файлу на електронному пристрої.
              </li>
              <li>
                Технічні вимоги, необхідні для використання додаткових послуг:
                <ol>
                  <li>
                    комп’ютер із процесором – Intel® Pentium® 500 МГц (або AMD),
                    оснащений як мінімум оперативною пам’яттю. 128 Мб,
                    встановлена ​​Windows® XP або вище, Mac OS X (оновлена
                    ​​операційна система з можливістю завантаження та
                    встановлення програмного забезпечення), оновлена ​​версія
                    інтернет-браузера та робоче інтернет-з’єднання з мінімальною
                    швидкістю 1 Мб/с.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Створення облікового запису на веб-сайті
            <ul>
              <li>
                Щоб зареєструвати обліковий запис на веб-сайті, заповніть
                реєстраційну форму. Необхідно вказати такі дані: ім'я, прізвище,
                країна, вулиця, номер будинку та квартири, поштовий індекс,
                місто, номер телефону, адреса електронної пошти. Також можна
                зареєструватися через Facebook або Google.
              </li>
              <li>Реєстрація облікового запису на веб-сайті безкоштовна.</li>
              <li>
                Вхід в Обліковий запис здійснюється шляхом введення логіна та
                пароля, встановлених у Реєстраційній формі.
              </li>
              <li>
                Клієнт може в будь-який час, без пояснення причини та без
                стягнення будь-яких комісій, видалити Обліковий запис,
                надіславши відповідний запит Адміністратору, зокрема електронною
                поштою або письмово за адресами, вказаними в пункті 3.
              </li>
            </ul>
          </li>
          <li>
            Правила користування Магазином
            <ul>
              <li>
                Для перегляду асортименту Магазину не потрібно створювати
                обліковий запис. Користувач може розміщувати замовлення на
                Товари в асортименті Магазину або після створення Облікового
                запису відповідно до положень пункту 5 Правил, або шляхом
                надання необхідних особистих та адресних даних, що дозволяють
                виконати Замовлення без створення Облікового запису.
              </li>
              <li>Договір купівлі-продажу стосується нових продуктів.</li>
              <li>
                Кінцева (остаточна) сума, яку сплачує Користувач, складається з
                ціни Товару та вартості доставки (включаючи транспорт, доставку
                та поштові збори), про що Користувач повідомляється на веб-сайті
                Магазину під час оформлення Замовлення, у тому числі під час
                волевиявлення на виконання Договору купівлі-продажу.
              </li>
            </ul>
          </li>
          <li>
            Правила оформлення Замовлення
            <ul>
              <li>
                Користувач може розміщувати Замовлення на Веб-сайті 24 години на
                добу, 7 днів на тиждень. Замовлення обробляються в робочі дні, в
                робочий час 8-16.
              </li>
              <li>
                Щоб розмістити замовлення на продукт, вам необхідно:
                <ol>
                  <li>авторизуватися в Сервісі (за бажанням);</li>
                  <li>
                    виберіть Продукт, який є предметом замовлення, а потім
                    натисніть кнопку «Додати до кошика» (або еквівалент);
                  </li>
                  <li>
                    увійти або скористатись опцією оформити замовлення без
                    реєстрації;
                  </li>
                  <li>
                    якщо вибрано варіант оформлення Замовлення без реєстрації –
                    заповніть Форму замовлення, вказавши дані одержувача
                    Замовлення та адресу, за якою має бути доставлений Товар,
                    виберіть тип відправлення (спосіб доставки Товару), введіть
                    реквізити рахунку, якщо вони відрізняються від одержувача
                    Замовлення;
                  </li>
                  <li>
                    натиснути кнопку «Замовити та оплатити» та підтвердити
                    замовлення, перейшовши за посиланням, надісланим в
                    електронному листі.
                  </li>
                </ol>
              </li>
              <li>
                Для оформлення замовлення онлайн-консультацій та стаціонарних
                послуг необхідно:
                <ol>
                  <li>
                    увійти або скористатись опцією оформити замовлення без
                    реєстрації;
                  </li>
                  <li>
                    заповнити Анкету Замовлення, вказавши дані одержувача
                    Замовлення: прізвище, ім’я та по батькові, номер мобільного
                    телефону, адресу електронної пошти та мету консультації;
                    Незалежно від вищезазначеного, на більш пізньому етапі
                    Користувач повинен буде надати інші дані, необхідні для
                    надання консультацій та послуг.
                  </li>
                  <li>
                    натиснути кнопку «Замовити та оплатити» та підтвердити
                    замовлення, перейшовши за посиланням, надісланим в
                    електронному листі.
                  </li>
                </ol>
              </li>
              <li>
                Для завершення замовлення необхідно вибрати один із доступних
                способів оплати та, залежно від способу оплати, оплатити
                замовлення протягом зазначеного періоду, відповідно до пункту 8
                і 9.
              </li>
              <li>
                Адміністратор залишає за собою право відмовитися від виконання
                Замовлення Користувача, який не надав усі необхідні дані, в тому
                числі дані, необхідні відповідно до Закону про права пацієнта та
                захисника прав пацієнта для ведення медичної документації у
                зв’язку з наданням медичної допомоги, або надані дані викликають
                сумніви щодо їх правильності. Будь-яке замовлення, яке не можна
                підтвердити протягом 10 днів після розміщення, не може бути
                оброблено.
              </li>
              <li>
                Розміщуючи замовлення, Клієнт:
                <ol>
                  <li>
                    дає згоду на виписку податкової накладної без підпису
                    одержувача;
                  </li>
                  <li>
                    заявляє, що він/вона ознайомився з Регламентом та іншими
                    положеннями, доступними на веб-сайті Сервісу, включаючи
                    Політику конфіденційності, і приймає їх положення.
                  </li>
                </ol>
              </li>
              <li>
                Усі ціни, зазначені на Веб-сайті, наведені в польських злотих і
                є цінами брутто.
              </li>
            </ul>
          </li>
          <li>
            Пропоновані способи доставки та оплати
            <ul>
              <li>
                Клієнт може використовувати такі способи доставки або отримання
                замовленого Товару:
                <ol>
                  <li>кур'єрська доставка.</li>
                  <li>
                    Особиста колекція доступна за адресою: 85-448 Bydgoszcz ul.
                    Крушинська 17
                  </li>
                </ol>
              </li>
              <li>
                Особисте отримання можливе після попередньої домовленості про
                дату та час отримання відправлення. Для цього Клієнт повинен
                щоразу узгоджувати деталі особистого отримання, безпосередньо
                звертаючись до Продавця.
              </li>
              <li>
                Клієнт може використовувати такі способи оплати:
                <ol>
                  <li>оплата переказом на рахунок Адміністратора;</li>
                  <li>
                    оплата, здійснена в електронному вигляді через сервіс, що
                    надається Платіжним оператором (на умовах, визначених
                    Платіжним оператором) у вигляді: електронного переказу або
                    списання платіжної картки Користувача (список прийнятих
                    платіжних карток: Visa/Visa Electron, MasterCard/MasterCard
                    Electronic, Maestro, American Express).
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Оформлення договору купівлі-продажу, онлайн-консультація,
            стаціонарне обслуговування
            <ul>
              <li>
                Укладення Договору купівлі-продажу та Договору
                онлайн-консультації між Користувачем та Адміністратором
                відбувається після оформлення Користувачем Замовлення згідно з
                п. 7
              </li>
              <li>
                Після оформлення Замовлення Адміністратор негайно підтверджує
                його отримання та одночасно приймає Замовлення до виконання.
              </li>
              <li>
                Підтвердження отримання Замовлення та прийняття його до
                виконання відбувається шляхом направлення відповідного
                електронного повідомлення Адміністратором Користувачеві на
                адресу електронної пошти, вказану Користувачем при оформленні
                Замовлення. Повідомлення містить як мінімум заяву Адміністратора
                про отримання Замовлення та прийняття його до виконання, а також
                підтвердження укладення Договору купівлі-продажу. Після
                отримання Клієнтом вищевказаного електронного повідомлення між
                Користувачем та Адміністратором укладається Договір.
              </li>
              <li>
                У разі вибору Замовником:
                <ol>
                  <li>
                    електронних платежів або платежів платіжною карткою,
                    Користувач зобов'язаний здійснити оплату в день укладення
                    Договору купівлі-продажу - інакше замовлення буде
                    анульовано;
                  </li>
                  <li>
                    накладений платіж при отриманні відправлення, Користувач
                    зобов'язаний здійснити оплату при отриманні відправлення - у
                    разі несплати відправлення не буде видано Користувачеві;
                  </li>
                  <li>
                    оплата готівкою при особистому отриманні відправлення,
                    Користувач зобов'язаний здійснити оплату при отриманні
                    відправлення в пункті прийому в день отримання відправлення
                    - у разі несплати відправлення не буде видано Користувачеві.
                  </li>
                </ol>
              </li>
              <li>
                Якщо ви обираєте оплату банківським переказом, застосовується
                така процедура:
                <ol>
                  <li>
                    після оформлення замовлення Користувач отримує Підтвердження
                    замовлення, на підставі якого Користувач здійснює оплату на
                    рахунок Адміністратора протягом двох днів з моменту його
                    отримання;
                  </li>
                  <li>
                    Підтвердження замовлення містить номер замовлення, його
                    вартість та номер банківського рахунку Адміністратора.
                  </li>
                  <li>
                    Користувач зобов’язаний надати на формі переказу наступний
                    зміст: «Прізвище та ім’я користувача, номер замовлення […[».
                  </li>
                </ol>
              </li>
              <li>
                Якщо Користувач вибрав спосіб доставки, відмінний від особистого
                забору, Товар буде відправлений Адміністратором у терміни,
                зазначені в його описі (з урахуванням пункту 6), способом,
                обраним Користувачем при оформленні Замовлення.
                <ol>
                  <li>
                    У разі замовлення Продуктів з різними термінами доставки
                    датою доставки є найдовший із вказаних термінів.
                  </li>
                  <li>
                    У разі замовлення Продуктів з різними датами доставки Клієнт
                    може вимагати доставку Продуктів частинами або доставку всіх
                    Продуктів після завершення всього замовлення.
                  </li>
                </ol>
              </li>
              <li>
                Початок періоду доставки Товару Користувачеві відраховується
                наступним чином:
                <ol>
                  <li>
                    якщо Користувач обирає оплату банківським переказом,
                    електронним платежем або платіжною карткою – з дати
                    зарахування коштів на банківський рахунок Адміністратора.
                  </li>
                  <li>
                    якщо Користувач обирає як спосіб оплати накладений платіж –
                    з дати укладення Договору купівлі-продажу,
                  </li>
                  <li>
                    Якщо Користувач обирає особистий збір Продукту, Продукт буде
                    готовий до отримання Користувачем у дату, зазначену в описі
                    Товару. Про готовність Товару до отримання Користувач буде
                    додатково проінформований Адміністратором шляхом
                    відправлення відповідного електронного повідомлення на
                    адресу електронної пошти Користувача, вказану при оформленні
                    Замовлення.
                  </li>
                </ol>
              </li>
              <li>
                Початок періоду готовності Товару до прийому Користувачем
                відраховується наступним чином:
                <ol>
                  <li>
                    якщо Користувач обирає оплату банківським переказом,
                    електронним платежем або платіжною карткою – з дати
                    зарахування коштів на банківський рахунок Адміністратора;
                  </li>
                  <li>
                    при виборі Користувачем готівки при особистому отриманні – з
                    дати укладення Договору купівлі-продажу.
                  </li>
                </ol>
              </li>
              <li>
                У випадку продуктів, які є файлами:
                <ol>
                  <li>
                    Оплата Замовлення Користувачем призводить до автоматичного
                    (не пізніше ніж через 15 хвилин з моменту отримання
                    підтвердження оплати від Платіжного оператора або
                    зарахування коштів на банківський рахунок Адміністратора)
                    надання доступу до замовленого Файлу шляхом відправлення
                    Користувачеві на вказану ним адресу електронної пошти
                    електронного листа з посиланням на веб-сайт, з якого можна
                    буде завантажити Файл, або безпосередньо на адресу
                    електронної пошти, вказану під час покупки. В особливих
                    випадках (наприклад, через збій сервера) Файл може бути
                    доступний пізніше ніж через 15 хвилин з моменту оплати
                    замовлення;
                  </li>
                  <li>
                    з моменту надання Файлу, він може бути завантажений
                    Користувачем, але завантаження цього Файлу виключає
                    можливість відмови від угоди щодо цього Файлу – на умовах,
                    зазначених у п. 10 і 11.
                  </li>
                </ol>
              </li>
              <li>
                Доставка Продукту (не є Файлом) відбувається тільки в межах
                території
              </li>
              <li>
                Доставка Клієнту Продукту, який не є Файлом, підлягає оплаті,
                якщо інше не передбачено Договором купівлі-продажу. Вартість
                доставки Товару (включаючи транспорт, доставку та поштові
                витрати) вказується Покупцеві на сайті Інтернет-магазину у
                вкладці «Вартість доставки» та під час оформлення Замовлення, а
                також на сторінках вибраного товару.
              </li>
              <li>Особисте отримання Товару Клієнтом є безкоштовним.</li>
              <li>
                Реалізація Договору про онлайн-консультацію визначається
                регламентом послуги, що надається в електронному вигляді
                «Онлайн-консультація» за адресою:{" "}
                <a href="https://jat-med.com/policy/online-consultations">
                  jat-med.com/policy/online-consultations
                </a>
              </li>
              <li>
                Рахунок-фактура з ПДВ буде видано та надіслано разом із
                замовленими Продуктами (окрім Файлів, завантажених через
                Інтернет). У випадку файлів та онлайн-консультацій
                рахунок-фактура з ПДВ буде виставлена ​​та надіслана
                Користувачеві на вказану ним/нею адресу електронної пошти
                протягом 7 днів із дати зарахування коштів на банківський
                рахунок Адміністратора.
              </li>
            </ul>
          </li>
          <li>
            Право Споживача на відмову від Договору
            <ul>
              <li>
                Відповідно до редакції ст 27 Закону від 30 травня 2014 року про
                права споживачів, кожен Користувач, який також є Споживачем, має
                право відмовитися від дистанційного договору протягом 14 днів
                без пояснення причини.
              </li>
              <li>
                Строк, зазначений у пп 1 починається з доставки Товару
                Споживачеві або вказаній ним особі, крім перевізника.
              </li>
              <li>
                У випадку Контракту, який охоплює кілька Продуктів, що
                постачаються окремо, партіями або частинами, період, зазначений
                у розд. 1 починається з поставки останнього товару, партії або
                частини.
              </li>
              <li>
                Споживач може відмовитися від Договору шляхом подання
                Адміністратору однозначної заяви про відмову від Договору. Для
                дотримання терміну для відмови від Договору достатньо, щоб
                Споживач надіслав заяву до закінчення цього терміну.
              </li>
              <li>
                Декларація може бути надіслана звичайною поштою або в
                електронному вигляді шляхом надсилання заяви на електронну
                адресу Адміністратора, зазначену в п.п. 2. Декларацію можна
                подавати також за формою, зразок якої є додатком № 1 до
                Положення або додатком до Закону від 30 травня 2014 року «Про
                права споживачів», але це не є обов’язковим.
              </li>
              <li>
                У разі надсилання Споживачем заяви в електронному вигляді,
                Адміністратор негайно надсилає Споживачу підтвердження про
                отримання заяви про відмову від Договору на вказану Споживачем
                електронну адресу.
              </li>
              <li>
                Право на відмову від Дистанційного контракту не поширюється на
                Споживача, зокрема щодо: Угоди:
                <ol>
                  <li>
                    для постачання цифрового контенту, у тому числі файлів, які
                    не записані на матеріальному носії, якщо надання послуги
                    почалося за прямою згодою споживача до закінчення кінцевого
                    терміну для відмови від договору та після того, як
                    підприємець повідомив його про втрату права відмовитися від
                    договору;
                  </li>
                  <li>
                    надання послуг, якщо Адміністратор повністю виконав послугу
                    за явною згодою Споживача, який перед початком надання був
                    поінформований про те, що після виконання Адміністратором
                    послуги він втрачає право відмовитися від Договору;
                  </li>
                  <li>
                    якщо предметом послуги є товар, доставлений у запечатаній
                    упаковці, який неможливо повернути після відкриття упаковки
                    з міркувань охорони здоров’я чи гігієни, якщо упаковка була
                    відкрита після доставки;
                  </li>
                  <li>
                    якщо предметом надання є нерозбірний виріб, виготовлений за
                    специфікаціями Споживача або призначений для задоволення
                    його індивідуальних потреб.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Наслідки відмови Споживача від Договору
            <ul>
              <li>
                У разі відмови від Договору, укладеного на відстані, Договір
                вважається неукладеним.
              </li>
              <li>
                У разі відмови від Договору Адміністратор повинен негайно
                повернути Споживачеві не пізніше ніж протягом 14 днів з дати
                отримання заяви Споживача про відмову від Договору всі здійснені
                ним платежі, включаючи витрати на доставку Продукту, за винятком
                додаткових витрат, пов’язаних із способом доставки, обраним
                Споживачем, відмінним від найдешевшого стандартного способу
                доставки, запропонованого Адміністратором.
              </li>
              <li>
                Адміністратор відшкодує платіж за допомогою тих самих методів
                оплати, які використовував Споживач у початковій транзакції,
                якщо Споживач прямо не погодився на інше рішення, яке не
                призведе до будь-яких витрат для нього.
              </li>
              <li>
                Адміністратор може утримати відшкодування до повернення Продукту
                або до надання йому підтвердження його повернення, залежно від
                того, яка подія відбудеться першою.
              </li>
              <li>
                Споживач повинен повернути Товар за адресою Адміністратора,
                зазначеною в Регламенті, негайно, не пізніше 14 днів з дати,
                коли він повідомив Адміністратору про свою відмову від Угоди.
                Кінцевий термін буде дотримано, якщо Споживач поверне Товар до
                закінчення 14-денного періоду.
              </li>
              <li>
                Споживач несе прямі витрати на повернення Продукту, включно з
                витратами на повернення Продукту, якщо через його характер
                Продукт не можна було повернути звичайною поштою.
              </li>
              <li>
                Споживач несе відповідальність лише за будь-яке зменшення
                вартості Продукту внаслідок його використання іншим способом,
                ніж це необхідно для встановлення природи, характеристик і
                функціонування Продукту.
              </li>
              <li>
                Якщо через характер Продукту його неможливо повернути звичайною
                поштою, інформація про це, а також вартість повернення Продукту
                буде включена в опис Товару в Магазині.
              </li>
            </ul>
          </li>
          <li>
            Скарги та гарантія
            <ul>
              <li>
                Кожен Користувач може подавати скарги щодо будь-яких послуг,
                перелічених у Регламенті (включаючи дефекти та несправності
                Файлів) на основі положень про гарантію в Цивільному кодексі.
                Якщо Замовник є Підприємцем, сторони виключають відповідальність
                за гарантією.
              </li>
              <li>
                Скарги слід подавати в письмовій або електронній формі на адреси
                Адміністратора, вказані в Регламенті.
              </li>
              <li>
                Рекомендується включити до скарги, серед іншого: стислий опис
                дефекту, обставини (включаючи дату) його виникнення, реквізити
                Користувача, який подає рекламацію, та вимогу Клієнта у зв’язку
                з дефектом товару.
              </li>
              <li>
                Адміністратор надає відповідь на скаргу негайно, не пізніше 14
                днів, і якщо він не зробить цього протягом цього терміну,
                вважається, що він визнав вимогу Клієнта обґрунтованою.
              </li>
              <li>
                Продукти, які повертаються відповідно до процедури рекламації,
                повинні бути надіслані за адресою, зазначеною в пункті 3
                Регламенту.
              </li>
            </ul>
          </li>
          <li>
            Обробка персональних даних
            <ul>
              <li>
                У зв’язку з використанням Користувачем Веб-сайту Адміністратор
                обробляє дані в обсязі, необхідному для надання окремих послуг,
                пропонованих на Веб-сайті, а також інформацію про діяльність
                Користувача на Веб-сайті. Детальні принципи та цілі обробки
                персональних даних, зібраних під час використання Користувачем
                Сайту, викладені в Інформаційному пункті щодо обробки
                персональних даних на сайті та в інтернет-магазині Jat-med. sp.z
                o. з зареєстрованим офісом у Бидгощі, доступний за адресою{" "}
                <a href="https://jat-med.com/policy/personal-data-processing">
                  https://jat-med.com/policy/personal-data-processing
                </a>
              </li>
            </ul>
          </li>
          <li>
            Авторське право та ліцензія
            <ul>
              <li>
                Адміністратор заявляє, що всі матеріали, доступні на веб-сайті,
                включаючи інформацію, графіку, статті, фотографії, файли,
                підлягають правовому захисту, передбаченому, зокрема, Законом
                від 4 лютого 1994 року про авторське право та суміжні права.
              </li>
              <li>
                Отримання доступу до Веб-сайту під обліковим записом означає, що
                Адміністратор (Ліцензіар) надає Користувачеві (Ліцензіату)
                невиключну, обмежену в часі ліцензію без права надання
                субліцензій на використання матеріалів, що містяться на
                Веб-сайті, виключно для власних потреб Користувача, а також у
                випадках, прямо дозволених законодавством і відповідно до
                принципів і умов, зазначених у Регламенті, через Інтернет.
              </li>
              <li>
                Ліцензія надається на фіксований термін, що відповідає періоду
                доступу до даного контенту на Сайті.
              </li>
              <li>
                Ліцензія не дає вам права:
                <ol>
                  <li>
                    розповсюдження інформації, завантаженої з Сервісу, зокрема
                    на основі позики, оренди, лізингу чи продажу,
                  </li>
                  <li>
                    надання доступу до Сервісу третім особам, включаючи передачу
                    пароля третім особам, за винятком осіб, прямо уповноважених
                    Користувачем,
                  </li>
                  <li>
                    запис і розмноження даних, завантажених із Сервісу, за
                    винятком роздруківок виключно для власних потреб,
                  </li>
                  <li>передача права, що випливає з договору, третій особі.</li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Відповідальність
            <ul>
              <li>
                Адміністратор докладатиме всіх зусиль, щоб забезпечити
                безперебійне використання Веб-сайту, без збоїв або незручностей
                для Користувачів. Однак Адміністратор не несе відповідальності
                за будь-які збої, в тому числі перебої в роботі Сайту,
                спричинені обставинами непереборної сили, несанкціонованими
                діями третіх осіб або несумісністю Магазину з технічною
                інфраструктурою Користувача.
              </li>
              <li>
                Адміністратор залишає за собою право на короткочасні перерви в
                роботі Веб-сайту через необхідні ремонти, технічне
                обслуговування та резервне копіювання. Наскільки це можливо,
                якщо вищезазначені дії є результатом не помилок, а запланованих
                дій, Постачальник послуг зобов’язується повідомити про це
                Клієнтів із зазначенням очікуваної тривалості неможливості
                користування Сервісом.
              </li>
              <li>
                Користувач несе відповідальність за безпеку та конфіденційність
                пароля свого Облікового запису. Адміністратор не несе
                відповідальності за будь-який несанкціонований доступ до
                Облікового запису в результаті отримання третіми особами доступу
                до пароля. У разі виникнення підозри, що пароль міг бути
                розголошений третім особам, Користувач зобов'язується негайно
                змінити пароль або звернутися для цього до Адміністратора.
              </li>
              <li>
                Користувач несе повну відповідальність за порушення
                законодавства або шкоду, заподіяну його діями на Сайті, зокрема
                надання неправдивих даних, розголошення професійної таємниці,
                іншої конфіденційної інформації або комерційної таємниці,
                порушення особистих прав або авторських і суміжних прав.
              </li>
            </ul>
          </li>
          <li>
            Прикінцеві положення
            <ul>
              <li>
                З Регламентом можна ознайомитись на Сайті та в офісі
                Адміністратора. Правила – за бажанням Користувача, надісланим
                електронною поштою на електронну адресу:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>{" "}
                – будуть безоплатно надіслані Користувачеві в електронному
                вигляді на вказану Користувачем адресу електронної пошти.
              </li>
              <li>
                Адміністратор залишає за собою право вносити зміни до Регламенту
                з важливих причин, наприклад, зміни правових положень, зміни
                способів оплати та доставки – у тій мірі, в якій ці зміни
                впливають на реалізацію положень Регламенту.
              </li>
              <li>
                Адміністратор повідомить Користувача про будь-які зміни
                принаймні за 14 днів. Протягом 14 днів з моменту отримання
                інформації про зміну Користувач може відмовитися від її
                прийняття – електронною поштою на адресу{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
                . У такому випадку обліковий запис користувача буде закрито в
                день, коли зміни набудуть чинності.
              </li>
              <li>
                Покупки, здійснені в Магазині до набрання чинності змінами,
                регулюються діючими правилами.
              </li>
              <li>
                Адміністратор залишає за собою право розміщувати рекламний
                контент на веб-сайті у формах, які зазвичай використовуються в
                Інтернеті.
              </li>
              <li>
                Для використання позасудових методів розгляду скарг та розгляду
                претензій Користувач має право звернутися до Провінційної
                інспекції торгівлі за місцем його/її проживання із запитом:
                <ol>
                  <li>
                    а) ініціювати процедуру посередництва для мирного вирішення
                    спору між Адміністратором і Користувачем,
                  </li>
                  <li>
                    б) вирішувати спір, що виникає з укладеного договору
                    купівлі-продажу, в Постійному споживчому арбітражному суді,
                    що діє при Воєводській інспекції торгівлі.
                  </li>
                </ol>
              </li>
              <li>
                Детальна інформація про позасудові процедури розгляду скарг і
                претензій, а також правила доступу до цих процедур доступна в
                офісах і на веб-сайтах районних (міських) уповноважених
                споживачів, воєводських інспекцій торговельної інспекції та на
                веб-сторінці Управління конкуренції та захисту прав споживачів,
                тобто www.uokik.gov.pl/spory_konsumenckie.php.
              </li>
              <li>
                Користувач також може використовувати позасудові методи
                врегулювання скарг і розгляду претензій за допомогою
                онлайн-платформи ЄС ODR (Online Dispute Resolution). Платформа
                доступна за адресою:{" "}
                <a href="http://ec.europa.eu/consumers/odr/">
                  http://ec.europa.eu/consumers/odr/
                </a>
                . Якщо ви бажаєте скористатися вказаним способом вирішення
                питання, зверніться до Адміністратора.
              </li>
              <li>
                У питаннях, не врегульованих Регламентом, застосовуються
                загальноприйняті положення польського законодавства, зокрема:
                Цивільний кодекс; Акт про надання послуг засобами електронного
                зв'язку; Закон про права споживачів, Закон про захист
                персональних даних, GDPR та інші відповідні законодавчі
                положення.
              </li>
              <li className={styles.notDecorated}>
                Додаток № 1 Зразок форми відмови
                <ul>
                  <br />
                  Адреса офісу:
                  <ul>
                    <li>85-448 Бидгощ вул Крушинська 17</li>

                    <li>
                      Телефон: <a href="tel:+48518468678">518 648 678</a>
                    </li>
                    <li>
                      Email:{" "}
                      <a href="mailto:jat-medcom2018@gmail.com">
                        jat-medcom2018@gmail.com
                      </a>
                    </li>
                    <li>Номер рахунку: 17 1240 1183 1111 0011 5020 1417</li>
                  </ul>
                </ul>
              </li>
            </ul>
          </li>
        </ol>
      )}
    </article>
  );
};

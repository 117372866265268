import {
  CreateVoucherResponce,
  PaymentFormType,
} from "./../../shared/types/payment";
import { IPaymentResponse } from "../../shared/types/payment";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { ReducerKeys } from "../types";
import { axiosBaseQuery, PathBuilder, RequestTypes } from "../../utils";
import {
  credential,
  IVoucherDto,
  IVoucherListResponse,
  IVoucherQuery,
  IVoucher,
  IMessageResponse,
} from "../../shared";

export const voucherApi = createApi({
  reducerPath: ReducerKeys.VOUCHERS_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.VOUCHERS_API_REDUCER_KEY],
  endpoints: (build) => ({
    createVoucherPublic: build.mutation<CreateVoucherResponce, IVoucherDto>({
      query: (params) => ({
        url: PathBuilder.voucherApi.createPublicVoucher(),
        method: RequestTypes.post,
        data: params,
      }),
      invalidatesTags: [ReducerKeys.VOUCHERS_API_REDUCER_KEY],
    }),
    createVoucherPublicPayment: build.mutation<IPaymentResponse, string>({
      query: (id) => ({
        url: PathBuilder.voucherApi.createPublicVoucherPayment(id),
        method: RequestTypes.post,
      }),
      invalidatesTags: [ReducerKeys.VOUCHERS_API_REDUCER_KEY],
    }),
    markAsPaid: build.mutation<IMessageResponse, PaymentFormType>({
      query: (data) => ({
        url: PathBuilder.voucherApi.markAsPaid(data.id),
        method: RequestTypes.post,
        data: { paymentType: data.paymentType },
      }),
      invalidatesTags: [ReducerKeys.VOUCHERS_API_REDUCER_KEY],
    }),
    fetchVoucherListByStaff: build.query<IVoucherListResponse, IVoucherQuery>({
      query: (params) => ({
        url: PathBuilder.voucherApi.fetchVouchersByStaff(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.VOUCHERS_API_REDUCER_KEY],
    }),
    fetchVoucherDetailsByStaff: build.query<IVoucher, string>({
      query: (id) => ({
        url: PathBuilder.voucherApi.fetchVoucherDetailsByStaff(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.VOUCHERS_API_REDUCER_KEY],
    }),
    fetchVoucherListByUser: build.query<IVoucherListResponse, IVoucherQuery>({
      query: (params) => ({
        url: PathBuilder.voucherApi.fetchVouchersByUser(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.VOUCHERS_API_REDUCER_KEY],
    }),
    fetchVoucherDetailsByUser: build.query<IVoucher, string>({
      query: (id) => ({
        url: PathBuilder.voucherApi.fetchVoucherDetailsByUser(id),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.VOUCHERS_API_REDUCER_KEY],
    }),
    addVoucherToUser: build.mutation<IMessageResponse, string>({
      query: (id) => ({
        url: PathBuilder.voucherApi.addVoucherToUser(id),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.VOUCHERS_API_REDUCER_KEY],
    }),
    validateVoucher: build.query<
      IMessageResponse,
      { visitId: string; voucherKey: string }
    >({
      query: ({ visitId, voucherKey }) => ({
        url: PathBuilder.voucherApi.validateVoucher(visitId, voucherKey),
        method: RequestTypes.get,
      }),
      providesTags: () => [ReducerKeys.VOUCHERS_API_REDUCER_KEY],
    }),
  }),
});

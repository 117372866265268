import React from "react";
// import { SocialLinks } from "../SocialLinks";
import { logo } from "@jat-med/assets";
import styles from "./footer.module.scss";
import { CustomLink } from "../CustomLink";
import { placeApi, serviceApi } from "../../store/services";
import {
  RoutesEnum,
  placeQueryData,
  servicePaginationData,
} from "../../shared";
import { useTranslation } from "react-i18next";
import { ScrollToTop } from "../StrollToTop";

export const Footer = () => {
  const { t } = useTranslation();

  const { data: fetchedServiceList } = serviceApi.useFetchServiceListQuery(
    servicePaginationData
  );

  const { data: fetchedHospitalList } =
    placeApi.useFetchPlaceListQuery(placeQueryData);

  const hospitalList = fetchedHospitalList?.items;

  return (
    <section className={styles.footer_section}>
      <div className={styles.main}>
        <div className={styles.logo_part}>
          <CustomLink
            to={RoutesEnum.initialRoute}
            customStyle={styles.logo_link}
          >
            <img src={logo} alt="Logo" />
          </CustomLink>
          <div className={styles.our_social_wrap}>
            {/* commented till social media links are added */}
            {/* <p className={styles.title}>{t("navigation.socialMedia")}</p>
            <SocialLinks customStyle={styles.footer_social_links} /> */}
          </div>
        </div>
        <div className={styles.info_part}>
          <div className={styles.top}>
            {hospitalList &&
              hospitalList.map((hospital) => (
                <div key={hospital._id} className={styles.left}>
                  <p className={styles.city}>{hospital.city}</p>
                  <p className={styles.address}>
                    <span>{`${hospital.street}, ${hospital.buildingNumber}`}</span>
                  </p>
                  <p className={styles.phone}>{hospital.phoneNumber}</p>
                  <a href={`mailto:${hospital.email}`}>{hospital.email}</a>
                </div>
              ))}

            <div className={styles.right}>
              <CustomLink
                to={RoutesEnum.appointment}
                className="orange"
                customStyle={styles.appointment_link}
              >
                {t("buttons.appointment")}
              </CustomLink>
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.left}>
              <h3 className={styles.title}>{t("navigation.services")}</h3>
              <ul>
                {!!fetchedServiceList &&
                  fetchedServiceList.items.map((service) => (
                    <li key={service._id}>
                      <CustomLink to={`${RoutesEnum.services}/${service._id}`}>
                        {service.title}
                      </CustomLink>
                    </li>
                  ))}
              </ul>
            </div>
            <div className={styles.center}>
              <h3 className={styles.title}>{t("navigation.aboutUs")}</h3>
              <ul>
                <li>
                  <CustomLink to={RoutesEnum.about}>
                    {t("navigation.clinic")}
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={RoutesEnum.doctors}>
                    {t("navigation.doctors")}
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={RoutesEnum.contacts}>
                    {t("navigation.contacts")}
                  </CustomLink>
                </li>
              </ul>
            </div>
            <div className={styles.right}>
              <h3 className={styles.title}>
                {t("navigation.usefulInformation")}
              </h3>
              <ul>
                <li>
                  <CustomLink to={`${RoutesEnum.articles}?type=news`}>
                    {t("navigation.news")}
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={`${RoutesEnum.articles}?type=post`}>
                    {t("navigation.articles")}
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={`${RoutesEnum.faq}`}>
                    {t("questionsAnswers")}
                  </CustomLink>
                </li>
              </ul>
            </div>
            <div>
              <h3 className={styles.title}>{t("navigation.policy")}</h3>
              <ul>
                <li>
                  <CustomLink to={`${RoutesEnum.onlineConsultations}`}>
                    {t("navigation.onlineConsultations")}
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={`${RoutesEnum.inPersonConsultations}`}>
                    {t("navigation.inPersonConsultations")}
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={`${RoutesEnum.websiteTerms}`}>
                    {t("navigation.websiteTerms")}
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={`${RoutesEnum.cookiePolicy}`}>
                    {t("navigation.cookiePolicy")}
                  </CustomLink>
                </li>
                <li>
                  <CustomLink to={`${RoutesEnum.personalDataProcessing}`}>
                    {t("navigation.personalData")}
                  </CustomLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <p
        className={styles.copyright}
      >{`© ${new Date().getFullYear()} JATMED`}</p>
      <ScrollToTop />
    </section>
  );
};

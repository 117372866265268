import React from "react";
import styles from "./styles.module.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
export const CookiePolicy = () => {
  const { t } = useTranslation();
  return (
    <article className={styles.article}>
      <h1>{t("CookiePolicy.title")}</h1>
      <i>{t("CookiePolicy.paragraph1")}</i>
      {i18next.resolvedLanguage === "pl" && (
        <ol>
          <li>
            Wprowadzenie
            <ul>
              <li>
                Nasza strona internetowa,{" "}
                <a href="https://jat-med.com">jat-med.com</a> (dalej: „strona
                internetowa”) używa plików cookie i innych powiązanych
                technologii (dla wygody wszystkie technologie są określane jako
                „pliki cookie”). Pliki cookie są również umieszczane przez
                strony zewnętrzne. W poniższym dokumencie informujemy o
                korzystaniu z plików cookie na naszej stronie internetowej.
              </li>
            </ul>
          </li>
          <li>
            Czym są ciasteczka?
            <ul>
              <li>
                Plik cookie to niewielki prosty plik wysyłany wraz ze stronami
                tej witryny i przechowywany przez przeglądarkę na dysku twardym
                komputera lub innego urządzenia. Informacje w nich przechowywane
                mogą zostać zwrócone na nasze serwery lub do serwerów
                odpowiednich stron trzecich podczas kolejnej wizyty.
              </li>
            </ul>
          </li>
          <li>
            Czym są skrypty?
            <ul>
              <li>
                Skrypt to fragment kodu programu, który służy do prawidłowego i
                interaktywnego działania naszej witryny. Ten kod jest wykonywany
                na naszym serwerze lub na twoim urządzeniu.
              </li>
            </ul>
          </li>
          <li>
            Co to jest pixel tag?
            <ul>
              <li>
                Web beacon (lub znacznik pikselowy) to mały, niewidoczny
                fragment tekstu lub obrazu na stronie internetowej, który służy
                do monitorowania ruchu na stronie internetowej. Aby to zrobić,
                różne dane o tobie są przechowywane przy użyciu web beaconów.
              </li>
            </ul>
          </li>
          <li>
            Ciasteczka
            <ul>
              <li>
                Ciasteczka techniczne lub funkcjonalne
                <ul>
                  <li>
                    Niektóre pliki cookie zapewniają prawidłowe działanie
                    niektórych części witryny i poznanie preferencji
                    użytkownika. Umieszczając funkcjonalne pliki cookie,
                    ułatwiamy odwiedzanie naszej strony internetowej. W ten
                    sposób nie musisz wielokrotnie wprowadzać tych samych
                    informacji podczas odwiedzania naszej strony internetowej i,
                    na przykład, przedmioty pozostają w koszyku, dopóki nie
                    zapłacisz. Możemy umieścić te pliki cookie bez Twojej zgody.
                  </li>
                </ul>
              </li>
              <li>
                Cookies analityczne
                <ul>
                  <li>
                    Używamy analitycznych plików cookie, aby zoptymalizować
                    korzystanie ze strony internetowej dla naszych użytkowników.
                    Dzięki tym analitycznym plikom cookie uzyskujemy wgląd w
                    korzystanie z naszej witryny. Prosimy o zgodę na
                    umieszczanie analitycznych plików cookie.
                  </li>
                </ul>
              </li>
              <li>
                Pliki cookies reklamowe
                <ul>
                  <li>
                    W tej witrynie używamy reklamowych plików cookies, które
                    pozwalają nam uzyskać wgląd w wyniki kampanii. To się dzieje
                    w oparciu o profile, które tworzymy na podstawie twojego
                    zachowania na <a href="https://jat-med.com">jat-med.com</a>.
                    Z tymi plikami cookie jesteś, jako odwiedzający stronę,
                    powiązany z unikalnym ID, ale te pliki cookie nie będą
                    profilowały twojego zachowania i zainteresowań do
                    wyświetlania spersonalizowanych reklam.
                  </li>
                </ul>
              </li>
              <li>
                Marketingowe / śledzące pliki cookies
                <ul>
                  <li>
                    Marketingowe / śledzące pliki cookies są plikami cookies
                    (lub innymi formami lokalnego przechowywania) używanymi do
                    stworzenia profilu użytkownika w celu wyświetlania mu reklam
                    lub śledzenia tego użytkownika na tej lub na kilku witrynach
                    w podobnych celach marketingowych. Ponieważ te pliki cookie
                    są oznaczone jako śledzące pliki cookie, prosimy o zgodę na
                    ich umieszczenie.
                  </li>
                </ul>
              </li>
              <li>
                Media społecznościowe
                <ul>
                  <li>
                    Na naszej witrynie, mamy treści z Facebook, żeby promować
                    poszczególne strony (np. przyciski "przypnij", "polub to",
                    "podziel się") w mediach społecznościowych, jak Facebook. Ta
                    treść jest osadzona kodem pochodzącym z Facebook i umieszcza
                    pliki cookies. Ta treść może przechowywać i przetwarzać
                    pewne informacje na potrzeby spersonalizowanych reklam.
                    Przeczytaj oświadczenie o ochronie prywatności tych sieci
                    społecznościowych (które mogą się regularnie zmieniać), aby
                    dowiedzieć się, co robią z twoimi (osobistymi) danymi, które
                    przetwarzają za pomocą tych plików cookie. Odzyskiwane dane
                    są w miarę możliwości anonimizowane. Sieć społecznościowa
                    Facebook jest zlokalizowana w Stanach Zjednoczonych.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Umieszczone pliki cookie
            <ul>
              <li>
                Google Maps
                <ul>
                  <li>Marketing/śledzenie</li>
                  <li>Consent to service google-maps</li>
                </ul>
              </li>
              <li>
                Vimeo
                <ul>
                  <li>Statystyki</li>
                  <li>Consent to service vimeo</li>
                </ul>
              </li>
              <li>
                YouTube
                <ul>
                  <li>Marketing/śledzenie, Funkcjonalne</li>
                  <li>Consent to service youtube</li>
                </ul>
              </li>
              <li>
                Facebook
                <ul>
                  <li>Marketing/śledzenie, Funkcjonalne</li>
                  <li>Consent to service facebook</li>
                </ul>
              </li>
              <li>
                Różne
                <ul>
                  <li>Cel w toku sprawdzania</li>
                  <li>Consent to service różne</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Zgoda
            <ul>
              <li>
                Kiedy odwiedzasz naszą stronę po raz pierwszy, pokażemy Ci
                wyskakujące okienko z wyjaśnieniem dotyczącym plików cookie. Po
                kliknięciu przycisku "Zapisz preferencje" wyrażasz zgodę na
                używanie przez nas kategorii plików cookie i wtyczek wybranych w
                wyskakującym okienku, jak opisano w tym oświadczeniu dotyczącym
                plików cookie. Korzystanie z plików cookie może zostać wyłączone
                za pośrednictwem przeglądarki, ale pamiętaj, że nasza witryna
                może już nie działać poprawnie.
              </li>
              <li>
                Zarządzaj swoimi ustawieniami zgody na pliki cookies
                <ul>
                  <li>Funkcjonalne - Zawsze aktywne</li>
                  <li>Statystyka</li>
                  <li>Marketing</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Włączanie / wyłączanie i usuwanie plików cookie
            <ul>
              <li>
                Możesz użyć przeglądarki internetowej do automatycznego lub
                ręcznego usuwania plików cookie. Możesz również określić, że
                niektóre pliki cookie mogą nie być umieszczane. Inną opcją jest
                zmiana ustawień przeglądarki internetowej, tak aby otrzymywać
                komunikat za każdym razem, gdy plik cookie zostanie umieszczony.
                Aby uzyskać więcej informacji na temat tych opcji, zapoznaj się
                z instrukcjami w sekcji pomocy przeglądarki.
              </li>
              <li>
                Pamiętaj, że nasza witryna może nie działać poprawnie, jeśli
                wszystkie pliki cookie są wyłączone. Jeśli usuniesz pliki cookie
                w swojej przeglądarce, zostaną one ponownie umieszczone po
                wyrażeniu zgody podczas ponownego odwiedzania naszych stron
                internetowych.
              </li>
            </ul>
          </li>
          <li>
            Twoje prawa w odniesieniu do danych osobowych
            <ul>
              <span>
                Masz następujące prawa w odniesieniu do swoich danych osobowych:
              </span>
              <ul>
                <li>
                  Masz prawo wiedzieć, dlaczego Twoje dane osobowe są potrzebne,
                  co się z nimi stanie i jak długo będą przechowywane.
                </li>
                <li>
                  Prawo dostępu: masz prawo dostępu do znanych nam danych
                  osobowych.
                </li>
                <li>
                  Prawo do sprostowania: masz prawo do uzupełnienia,
                  poprawienia, usunięcia lub zablokowania danych osobowych w
                  dowolnym momencie.
                </li>
                <li>
                  Jeśli wyrazisz zgodę na przetwarzanie swoich danych, masz
                  prawo odwołać tę zgodę i usunąć swoje dane osobowe.
                </li>
                <li>
                  Prawo do przekazywania danych: masz prawo zażądać od
                  administratora wszystkich swoich danych osobowych i przekazać
                  je w całości innemu kontrolerowi.
                </li>
                <li>
                  Prawo do sprzeciwu: możesz sprzeciwić się przetwarzaniu
                  danych. Przestrzegamy tego, chyba że istnieją uzasadnione
                  podstawy do przetwarzania.
                </li>
              </ul>
              <span>
                Aby skorzystać z tych praw, skontaktuj się z nami. Proszę
                odnieść się do danych kontaktowych na dole tego oświadczenia
                cookie. Jeśli masz skargę dotyczącą sposobu postępowania z
                Twoimi danymi, chcielibyśmy usłyszeć od Ciebie, ale masz również
                prawo złożyć skargę do organu nadzorczego (organu ochrony
                danych).
              </span>
            </ul>
          </li>
          <li>
            Dane kontaktowe
            <ul>
              <span>
                W przypadku pytań i / lub komentarzy dotyczących naszej polityki
                dotyczącej plików cookie i tego oświadczenia, prosimy o kontakt
                z nami za pomocą następujących danych kontaktowych:
              </span>
              <li>
                Jat-med sp. z o.o., 85-448 Bydgoszcz, Ul. Kruszyńska 17, Polska
              </li>
              <li>
                Witryna internetowa:{" "}
                <a href="https://jat-med.com">jat-med.com</a>
              </li>
              <li>
                Adres e-mail:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                Phone number: <a href="tel:+48518468678">+48 518 648 678</a>
              </li>
              <li>
                Ta Polityka Plików Cookies została zsynchronizowana z{" "}
                <a href="https://cookiedatabase.org">cookiedatabase.org </a> w
                dniu 13 kwiecień 2023.
              </li>
            </ul>
          </li>
        </ol>
      )}
      {i18next.resolvedLanguage === "en" && (
        <ol>
          <li>
            Introduction
            <ul>
              <li>
                Our website, <a href="https://jat-med.com">jat-med.com</a>{" "}
                (hereinafter referred to as the "Website") uses cookies and
                other related technologies (for convenience, all technologies
                are referred to as "cookies"). Cookies are also placed by
                external parties. In the following document, we inform you about
                the use of cookies on our website.
              </li>
            </ul>
          </li>
          <li>
            What are cookies?
            <ul>
              <li>
                A cookie is a small, simple file sent along with the pages of
                this website and stored by your browser on the hard drive of
                your computer or other device. The information stored in them
                may be returned to our servers or to the servers of the relevant
                third parties during a subsequent visit.
              </li>
            </ul>
          </li>
          <li>
            What are scripts?
            <ul>
              <li>
                A script is a piece of program code that is used to make our
                website work properly and interactively. This code is executed
                on our server or on your device.
              </li>
            </ul>
          </li>
          <li>
            What is a pixel tag?
            <ul>
              <li>
                A web beacon (or pixel tag) is a small, invisible piece of text
                or image on a website that is used to monitor traffic on a
                website. To do this, various data about you is stored using web
                beacons.
              </li>
            </ul>
          </li>
          <li>
            Cookies
            <ul>
              <li>
                Technical or functional cookies
                <ul>
                  <li>
                    Some cookies ensure that certain parts of the website work
                    properly and that user preferences are known. By placing
                    functional cookies, we make it easier for you to visit our
                    website. This way, you do not have to repeatedly enter the
                    same information when visiting our website and, for example,
                    items remain in your shopping cart until you pay. We can
                    place these cookies without your consent.
                  </li>
                </ul>
              </li>
              <li>
                Analytical cookies
                <ul>
                  <li>
                    We use analytical cookies to optimise the use of the website
                    for our users. These analytical cookies allow us to gain
                    insight into the use of our website. We ask for your consent
                    to place analytical cookies.
                  </li>
                </ul>
              </li>
              <li>
                Advertising cookies
                <ul>
                  <li>
                    We use advertising cookies on this website that allow us to
                    gain insight into the results of campaigns. This is done
                    based on the profiles we create based on your behavior on{" "}
                    <a href="https://jat-med.com">jat-med.com</a>. With these
                    cookies you, as a visitor to the site, are associated with a
                    unique ID, but these cookies will not profile your behavior
                    and interests to display personalized ads.
                  </li>
                </ul>
              </li>
              <li>
                Marketing / tracking cookies
                <ul>
                  <li>
                    Marketing / tracking cookies are cookies (or other forms of
                    local storage) used to create a profile of a user in order
                    to display ads to them or to track that user on this or
                    several websites for similar marketing purposes. Because
                    these cookies are marked as tracking cookies, we ask for
                    your consent to place them.
                  </li>
                </ul>
              </li>
              <li>
                Social media
                <ul>
                  <li>
                    On our website, we have content from Facebook to promote
                    individual pages (e.g. "pin it", "like it", "share" buttons)
                    on social media such as Facebook. This content is embedded
                    with code from Facebook and places cookies. This content may
                    store and process certain information for the purpose of
                    personalized ads. Please read the privacy statements of
                    these social networks (which may change regularly) to find
                    out what they do with your (personal) data that they process
                    using these cookies. The data retrieved is anonymized where
                    possible. The social network Facebook is based in the United
                    States.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Cookies placed
            <ul>
              <li>
                Google Maps
                <ul>
                  <li>Marketing/tracking</li>
                  <li>Consent to service google-maps</li>
                </ul>
              </li>
              <li>
                Vimeo
                <ul>
                  <li>Statistics</li>
                  <li>Consent to service vimeo</li>
                </ul>
              </li>
              <li>
                YouTube
                <ul>
                  <li>Marketing/tracking, Functional</li>
                  <li>Consent to service youtube</li>
                </ul>
              </li>
              <li>
                Facebook
                <ul>
                  <li>Marketing/tracking, Functional</li>
                  <li>Consent to service facebook</li>
                </ul>
              </li>
              <li>
                Various
                <ul>
                  <li>Purpose under review</li>
                  <li>Consent to service various</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Consent
            <ul>
              <li>
                When you visit our website for the first time, we will show you
                a pop-up explaining the cookies. By clicking the "Save
                preferences" button, you consent to our use of the categories of
                cookies and plug-ins selected in the pop-up as described in this
                cookie statement. The use of cookies can be disabled via your
                browser, but please note that our site may no longer function
                properly.
              </li>
              <li>
                Manage your cookie consent settings
                <ul>
                  <li>Functional - Always active</li>
                  <li>Statistics</li>
                  <li>Marketing</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Enabling / disabling and deleting cookies
            <ul>
              <li>
                You can use your web browser to automatically or manually delete
                cookies. You can also specify that some cookies may not be
                placed. Another option is to change your web browser settings so
                that you receive a message each time a cookie is placed. For
                more information about these options, please refer to the
                instructions in the help section of your browser.
              </li>
              <li>
                Please note that our site may not function properly if all
                cookies are disabled. If you delete cookies in your browser,
                they will be re-placed after you have given your consent when
                you visit our websites again.
              </li>
            </ul>
          </li>
          <li>
            Your rights in relation to personal data
            <ul>
              <span>
                You have the following rights in relation to your personal data:
              </span>
              <li>
                You have the right to know why your personal data is needed,
                what will happen to it and how long it will be stored.
              </li>
              <li>
                Right of access: you have the right to access the personal data
                known to us.
              </li>
              <li>
                Right of rectification: you have the right to have your personal
                data completed, rectified, deleted or blocked at any time.
              </li>
              <li>
                If you have given your consent to the processing of your data,
                you have the right to revoke this consent and have your personal
                data deleted.
              </li>
              <li>
                Right to data portability: you have the right to request the
                controller to provide you with all of your personal data and to
                transfer it in its entirety to another controller.
              </li>
              <li>
                Right to object: you can object to the processing of your data.
                We comply with this, unless there are legitimate grounds for
                processing.
              </li>
              <br />
              <span>
                To exercise these rights, please contact us. Please refer to the
                contact details at the bottom of this cookie statement. If you
                have a complaint about how we handle your data, we would like to
                hear from you, but you also have the right to lodge a complaint
                with a supervisory authority (data protection authority).
              </span>
            </ul>
          </li>
          <li>
            Contact details
            <ul>
              <span>
                For questions and/or comments about our cookie policy and this
                statement, please contact us using the following contact
                details:
              </span>
              <li>
                Jat-med sp. z o.o., 85-448 Bydgoszcz, Ul. Kruszyńska 17, Poland
              </li>
              <li>
                Website: <a href="https://jat-med.com">jat-med.com</a>
              </li>
              <li>
                Email address:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                Phone number: <a href="tel:+48518468678">+48 518 468 678</a>
              </li>
              <li>
                This Cookie Policy was synchronized with{" "}
                <a href="https://cookiedatabase.org">cookiedatabase.org</a> on
                April 13, 2023.
              </li>
            </ul>
          </li>
        </ol>
      )}
      {i18next.resolvedLanguage === "uk" && (
        <ol>
          <li>
            Вступ
            <ul>
              <li>
                Наш веб-сайт <a href="https://jat-med.com">jat-med.com</a>{" "}
                (надалі іменований «Веб-сайт») використовує файли cookie та інші
                пов’язані технології (для зручності всі технології називаються
                «файлами cookie»). Файли cookie також розміщуються зовнішніми
                сайтами. У документі нижче ми повідомляємо вам про використання
                файлів cookie на нашому веб-сайті.
              </li>
            </ul>
          </li>
          <li>
            Що таке файли cookie?
            <ul>
              <li>
                Файл cookie — це невеликий простий файл, який надсилається разом
                із сторінками цього веб-сайту та зберігається вашим браузером на
                жорсткому диску вашого комп’ютера чи іншого пристрою.
                Інформація, що зберігається в них, може бути повернута на наші
                сервери або на сервери відповідних третіх сторін під час
                наступного відвідування.
              </li>
            </ul>
          </li>
          <li>
            Що таке скрипти?
            <ul>
              <li>
                Сценарій — це фрагмент програмного коду, який використовується
                для належного та інтерактивного функціонування нашого веб-сайту.
                Цей код виконується на нашому сервері або на вашому пристрої.
              </li>
            </ul>
          </li>
          <li>
            Що таке піксельний тег?
            <ul>
              <li>
                Веб-маяк (або піксельний тег) — це невеликий невидимий фрагмент
                тексту або зображення на веб-сторінці, який використовується для
                моніторингу трафіку на веб-сайті. Для цього різні дані про вас
                зберігаються за допомогою веб-маяків.
              </li>
            </ul>
          </li>
          <li>
            Cookie
            <ul>
              <li>
                Технічні або функціональні файли cookie
                <ul>
                  <li>
                    Деякі файли cookie забезпечують правильну роботу деяких
                    частин веб-сайту та вивчають налаштування користувача.
                    Розміщуючи функціональні файли cookie, ми полегшуємо вам
                    відвідування нашого веб-сайту. Таким чином вам не доведеться
                    вводити ту саму інформацію кілька разів під час відвідування
                    нашого веб-сайту, і, наприклад, товари залишаються у вашому
                    кошику, доки ви не сплатите. Ми можемо розміщувати ці файли
                    cookie без вашої згоди.
                  </li>
                </ul>
              </li>
              <li>
                Аналітичні файли cookie
                <ul>
                  <li>
                    Ми використовуємо аналітичні файли cookie, щоб оптимізувати
                    роботу веб-сайту для наших користувачів. Ці аналітичні файли
                    cookie дозволяють нам отримати уявлення про використання
                    нашого веб-сайту. Ми просимо вашої згоди на розміщення
                    аналітичних файлів cookie.
                  </li>
                </ul>
              </li>
              <li>
                Рекламні файли cookie
                <ul>
                  <li>
                    Ми використовуємо рекламні файли cookie на цьому веб-сайті,
                    щоб ми могли отримати уявлення про результати кампанії. Це
                    відбувається на основі профілів, які ми створюємо на основі
                    вашої поведінки на{" "}
                    <a href="https://jat-med.com">jat-med.com</a>. З цими
                    файлами cookie ви, як відвідувач сайту, пов’язуєте ці файли
                    cookie з унікальним ідентифікатором, але ці файли cookie не
                    профілюватимуть вашу поведінку та інтереси для відображення
                    персоналізованої реклами.
                  </li>
                </ul>
              </li>
              <li>
                Маркетингові/відстежувальні файли cookie
                <ul>
                  <li>
                    Маркетингові/відстежувальні файли cookie — це файли cookie
                    (або інші форми локального зберігання), які використовуються
                    для створення профілю користувача з метою показу реклами
                    цьому користувачеві або для відстеження цього користувача на
                    тому самому чи кількох веб-сайтах для подібних маркетингових
                    цілей. Оскільки ці файли cookie позначені як файли cookie
                    для відстеження, ми просимо вашої згоди на їх розміщення.
                  </li>
                </ul>
              </li>
              <li>
                Соціальні мережі
                <ul>
                  <li>
                    На нашому веб-сайті ми маємо вміст із Facebook для
                    просування окремих сторінок (наприклад, кнопки «закріпити»,
                    «подобається», «поділитися») у соціальних мережах, таких як
                    Facebook. Цей вміст вбудовано за допомогою коду з Facebook і
                    розміщує файли cookie. Цей вміст може зберігати та обробляти
                    певну інформацію для персоналізованих рекламних цілей.
                    Прочитайте заяви про конфіденційність цих соціальних мереж
                    (які можуть регулярно змінюватися), щоб дізнатися, що вони
                    роблять з вашими (особистими) даними, які вони обробляють за
                    допомогою цих файлів cookie. Відновлені дані анонімні, де це
                    можливо. Соціальна мережа Facebook базується в США.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Розміщення файлів cookie
            <ul>
              <li>
                Google Maps
                <ul>
                  <li>Маркетинг/відстеження</li>
                  <li>Згода на обслуговування карт Google</li>
                </ul>
              </li>
              <li>
                Vimeo
                <ul>
                  <li>Статистика</li>
                  <li>Згода на сервіс vimeo</li>
                </ul>
              </li>
              <li>
                YouTube
                <ul>
                  <li>Маркетинг/відстеження, функціональний</li>
                  <li>Згода на сервіс youtube</li>
                </ul>
              </li>
              <li>
                Facebook
                <ul>
                  <li>Маркетинг/відстеження, функціональний</li>
                  <li>Згода на сервіс facebook</li>
                </ul>
              </li>
              <li>
                Різні
                <ul>
                  <li>Ціль розглядається</li>
                  <li>Згода на обслуговування різне</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Згода
            <ul>
              <li>
                Коли ви відвідуєте наш сайт вперше, ми покажемо вам спливаюче
                вікно з поясненнями щодо файлів cookie. Натиснувши «Зберегти
                налаштування», ви погоджуєтеся на використання категорій файлів
                cookie та плагінів, вибраних у спливаючому вікні, як описано в
                цій заяві про файли cookie. Використання файлів cookie можна
                вимкнути у вашому браузері, але зауважте, що наш сайт може
                більше не працювати належним чином.
              </li>
              <li>
                Керуйте налаштуваннями згоди на файли cookie
                <ul>
                  <li>Функціональний - Завжди активний</li>
                  <li>Статистика</li>
                  <li>Маркетинг</li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            Увімкнення/вимкнення та видалення файлів cookie
            <ul>
              <li>
                Ви можете використовувати веб-браузер для автоматичного або
                ручного видалення файлів cookie. Ви також можете вказати, що
                певні файли cookie не можуть бути розміщені. Іншим варіантом є
                зміна налаштувань веб-браузера, щоб ви отримували повідомлення
                кожного разу, коли розміщується файл cookie. Для отримання
                додаткової інформації про ці параметри зверніться до розділу
                довідки вашого браузера.
              </li>
              <li>
                Зверніть увагу, що наш веб-сайт може не працювати належним
                чином, якщо всі файли cookie вимкнено. Якщо ви видалите файли
                cookie у своєму браузері, вони будуть знову розміщені за вашою
                згодою, коли ви знову відвідаєте наші веб-сайти.
              </li>
            </ul>
          </li>
          <li>
            Ваші права щодо персональних даних
            <ul>
              <span>
                Ви маєте наступні права щодо ваших персональних даних:
              </span>
              <li>
                Ви маєте право знати, навіщо потрібні ваші особисті дані, що з
                ними станеться та як довго вони зберігатимуться.
              </li>
              <li>
                Право на доступ: ви маєте право на доступ до відомих нам
                персональних даних.
              </li>
              <li>
                Право на виправлення: ви маєте право на доповнення, виправлення,
                видалення або блокування своїх персональних даних у будь-який
                час.
              </li>
              <li>
                Якщо ви даєте згоду на обробку ваших даних, ви маєте право
                відкликати цю згоду та видалити свої персональні дані.
              </li>
              <li>
                Право на передачу даних: ви маєте право вимагати від контролера
                всі ваші персональні дані та передати їх у повному обсязі іншому
                контролеру.
              </li>
              <li>
                Право на заперечення: ви можете заперечити проти обробки ваших
                даних. Ми будемо дотримуватися цього, якщо не буде вагомих
                законних підстав для обробки.
              </li>
              <br />
              <span>
                Щоб скористатися цими правами, зв’яжіться з нами. Будь ласка,
                зверніться до контактної інформації внизу цієї заяви про файли
                cookie. Якщо у вас є скарга щодо того, як ми обробляємо ваші
                дані, ми хотіли б почути вашу думку, але ви також маєте право
                подати скаргу до наглядового органу (органу захисту даних).
              </span>
            </ul>
          </li>
          <li>
            Контактні дані
            <ul>
              <span>
                Якщо у вас виникли запитання та/або коментарі щодо нашої
                політики щодо файлів cookie та цієї заяви, зв’яжіться з нами за
                такими контактними даними:
              </span>
              <li>
                Jat-med sp z o.o., 85-448 Бидгощ, вул Крушинська 17, Польща
              </li>
              <li>
                Веб-сайт: <a href="https://jat-med.com">jat-med.com</a>
              </li>
              <li>
                Електронна адреса:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                Телефон: <a href="tel:+48518468678">+48518468678</a>
              </li>
              <li>
                Цю Політику щодо файлів cookie було синхронізовано з{" "}
                <a href="https://cookiedatabase.org">cookiedatabase.org</a> 13
                квітня 2023 року.
              </li>
            </ul>
          </li>
        </ol>
      )}
    </article>
  );
};

import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { ReducerKeys } from "../types";
import { axiosBaseQuery, PathBuilder, RequestTypes } from "../../utils";
import { credential, IChangePasswordDto, IMessageResponse } from "../../shared";

export const passwordApi = createApi({
  reducerPath: ReducerKeys.PASSWORD_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.PASSWORD_API_REDUCER_KEY],
  endpoints: (build) => ({
    resetPassword: build.mutation<IMessageResponse, string>({
      query: (params) => ({
        url: PathBuilder.passwordApi.resetPassword(params),
        method: RequestTypes.patch,
      }),
      invalidatesTags: [ReducerKeys.PASSWORD_API_REDUCER_KEY],
    }),
    checkPassword: build.query<IMessageResponse, { password: string }>({
      query: (params) => ({
        url: PathBuilder.passwordApi.checkPassword(),
        method: RequestTypes.get,
        params,
      }),
      providesTags: () => [ReducerKeys.PASSWORD_API_REDUCER_KEY],
    }),
    changePassword: build.mutation<IMessageResponse, IChangePasswordDto>({
      query: (params) => ({
        url: PathBuilder.passwordApi.changePassword(),
        method: RequestTypes.patch,
        data: params,
      }),
      invalidatesTags: [ReducerKeys.PASSWORD_API_REDUCER_KEY],
    }),
  }),
});

import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Buttons";
import { RoutesEnum } from "../../shared";
import styles from "./404.module.scss";
import { useTranslation } from "react-i18next";

export const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={styles.wrap}>
      <h1>404</h1>
      <p>{t("Page not found")}</p>
      <Button
        className="green_outlined"
        onClick={() => {
          navigate(`${RoutesEnum.initialRoute}`);
        }}
        label={t("Go back to home")}
      />
    </div>
  );
};

import userReducer from "./userSlice";
import alertReducer from "./alertSlice";
import excludeTimeReducer from "./excludeTimeSlice";
import dailyExercisesReducer from "./dailyExercisesSlice";

import { combineReducers } from "@reduxjs/toolkit";
import {
  userApi,
  authApi,
  placeApi,
  serviceApi,
  articleApi,
  reviewApi,
  visitApi,
  exerciseApi,
  dailyExerciseApi,
  medicalCardApi,
  stageApi,
  scheduleApi,
  filesApi,
  contactUsApi,
} from "../services";
import { paymentApi } from "../services/paymentApi";

export const rootReducer = combineReducers({
  alertReducer,
  userReducer,
  excludeTimeReducer,
  dailyExercisesReducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [serviceApi.reducerPath]: serviceApi.reducer,
  [placeApi.reducerPath]: placeApi.reducer,
  [articleApi.reducerPath]: articleApi.reducer,
  [reviewApi.reducerPath]: reviewApi.reducer,
  [visitApi.reducerPath]: visitApi.reducer,
  [exerciseApi.reducerPath]: exerciseApi.reducer,
  [dailyExerciseApi.reducerPath]: dailyExerciseApi.reducer,
  [medicalCardApi.reducerPath]: medicalCardApi.reducer,
  [stageApi.reducerPath]: stageApi.reducer,
  [scheduleApi.reducerPath]: scheduleApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [contactUsApi.reducerPath]: contactUsApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
});

import { useTranslation } from "react-i18next";
import { AdminTitleSeparator } from "../../../components/AdminPanel";
import { StripePaymentForm } from "../../../components/Forms";
import { PaymentOptions } from "../../../components/Payment";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { useState } from "react";
import { voucherApi } from "../../../store/services";
import { paymentType, RoutesEnum, userRoles } from "../../../shared";
import { showError, showSuccessAlert } from "../../../store/reducers";
import { ConfirmDialog } from "../../../components/ConfirmDialog";

export const Payment = () => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(
    ({ userReducer }) => userReducer.currentUser
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { voucherId, totalCost, currency } = location.state || {};
  const [isStripePayment, setIsStripePayment] = useState(false);
  const [paymentType, setPaymentType] = useState("");

  const dispatch = useAppDispatch();

  const [handlePublicVoucherPayment] =
    voucherApi.useCreateVoucherPublicPaymentMutation();
  const [handleMarkAsPaid, { isLoading }] = voucherApi.useMarkAsPaidMutation();

  const handlePaymentType = async (paymentType: paymentType) => {
    setPaymentType(paymentType);
  };

  const handleCancelMarkAsPaid = () => {
    setPaymentType("");
  };

  const handleConfirmMarkAsPaid = async () => {
    try {
      await handleMarkAsPaid({
        id: voucherId as string,
        paymentType: paymentType as paymentType,
      }).unwrap();

      dispatch(showSuccessAlert(t("alert.successfulPayment")));
      setPaymentType("");
      if (currentUser?.role === userRoles.admin) {
        navigate(`${RoutesEnum.admin}${RoutesEnum.giftCertificate}`);
      } else {
        navigate(RoutesEnum.initialRoute);
      }
    } catch (error: any) {
      dispatch(
        showError(
          t([`alert.errors.${error.data.message}`, `alert.somethingWentWrong`])
        )
      );
      setPaymentType("");
    }
  };

  return (
    <AdminTitleSeparator title={t("adminPanel.certificatePayment")}>
      <ConfirmDialog
        isOpen={!!paymentType}
        title={`${t("confirmModal.confirmPayment")} ${t(paymentType)}`}
        onConfirm={handleConfirmMarkAsPaid}
        onCancel={handleCancelMarkAsPaid}
        isLoading={isLoading}
      />
      {!isStripePayment && voucherId && (
        <PaymentOptions
          currentUser={currentUser}
          setIsStripePayment={setIsStripePayment}
          onHandleMarkAsPaid={handlePaymentType}
        />
      )}
      {isStripePayment && voucherId && totalCost && currency && (
        <StripePaymentForm
          options={{ amount: totalCost * 100 }}
          price={totalCost}
          shippingCost={0}
          currencySymbol={currency}
          isShippingCost={false}
          singlePayment={true}
          handlePayment={async () =>
            await handlePublicVoucherPayment(voucherId)
          }
          hideRedirectAfterPayment
        />
      )}
    </AdminTitleSeparator>
  );
};

import React from "react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

export const InPersonConsultations = () => {
  const { t } = useTranslation();
  return (
    <article className={styles.policy}>
      <h1>{t("InPersonConsultations.title")}</h1>
      <p>{t("InPersonConsultations.paragraph1")}</p>

      {i18next.resolvedLanguage === "pl" && (
        <ol>
          <li>
            Postanowienia ogólne
            <ul>
              <li>
                Regulamin dotyczy zasad korzystania z usługi świadczonej
                stacjonarnie „Konsultacja Stacjonarna” (dalej „Usługa”),
                podmiotu świadczącego usługę, wynagrodzenia, postępowania
                reklamacyjnego i przetwarzania danych osobowych osoby
                korzystającej z Usługi.
              </li>
              <li>
                Na życzenie Użytkownika lub osoby przystępującej do korzystania
                z Usługi Regulamin może być przesłany droga elektroniczną na
                wskazany przez zainteresowanego adres mailowy.
              </li>
            </ul>
          </li>
          <li>
            Definicje
            <ul>
              <li>
                <b>Administrator</b> – Jat-med sp. z o.o. z siedzibą w
                Bydgoszczy przy ulicy Kruszyńska 17, wpisanej do rejestru
                przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez
                Sąd Rejonowy W BYDGOSZCZY, XIII WYDZIAŁ GOSPODARCZY KRAJOWEGO
                REJESTRU SĄDOWEGO, pod numerem KRS 0001150821, NIP 9671483929,
                REGON 540725908.
              </li>
              <li>
                <b>Serwis</b> – serwis internetowy prowadzony przez
                Administratora pod adresem{" "}
                <a href="https://jat-med.com" aria-label="">
                  jat-med.com
                </a>
                , dokonuje sprzedaży Produktów, udostępnia Pliki zawierające
                materiały do leczenia i profilaktyki schorzeń układu ruchu, a
                także świadczy inne usługi określone w Regulaminie, w tym
                możliwość zawarcia Umowy na Konsultacje On-line, oraz
                Konsultacje Stacjonarne.
              </li>
              <li>
                <b>Użytkownik</b> – osoba fizyczna, osoba prawna oraz jednostka
                organizacyjna nieposiadająca osobowości prawnej, odwiedzająca
                Serwis lub korzystająca z usług Serwisu.
              </li>
              <li>
                <b>Pacjent</b> – osoba fizyczna korzystający bezpośrednio z
                Konsultacji (w tym użytkownik).
              </li>
              <li>
                <b>Specjalista</b> – lekarz, pielęgniarka, fizjoterapeuta lub
                inną osoba niewymienioną powyżej wykonującą czynności w zakresie
                świadczeń zdrowotnych, świadczącą usługi zdrowotne, medyczne lub
                paramedyczne.
              </li>
              <li>
                <b>Usługa Stacjonarna, zwana też Usługą</b> – usługa świadczona
                przez Specjalistę w sposób bezpośredni w gabinecie na zasadach
                określonych w Regulaminie.
              </li>
              <li>
                <b>Umowa na Usługi Stacjonarne</b> – umowa o świadczenie usług w
                postaci Usług Stacjonarnych zawierana albo zawarta między
                Użytkownikiem a Specjalistą za pośrednictwem Serwisu, lub w
                miejscu wykonywania usługi.
              </li>
              <li>
                <b>Regulamin</b> – Regulamin usługi stacjonarnej, świadczonej w
                prowadzonym przez Administratora gabinecie.
              </li>
            </ul>
          </li>
          <li>
            Zasady korzystania z Usług Stacjonarnych
            <ul>
              <li>
                Przystąpienie do korzystania z Usług Stacjonarnych, warunkowane
                jest akceptacją niniejszego Regulaminu przez Pacjenta, oraz
                akceptacją możliwości świadczenia usługi przez Specjalistę.
              </li>
              <li>
                Rodzaje Usług Stacjonarnych:
                <ol>
                  <li>Konsultacje zdrowotne;</li>
                  <li>porada albo;</li>
                  <li>informacja zdrowotna;</li>
                  <li>zabiegi manualne;</li>
                  <li>ćwiczenia zdrowotne;</li>
                </ol>
              </li>
              <li>
                Usługa Stacjonarna jest świadczeniem zdrowotnym, którego
                udzielenie może nastąpić pod warunkiem podania przez Pacjenta
                najpóźniej do terminu umówionej Usługi, następujących danych
                potrzebnych do udzielenia świadczenia i prowadzenia w związku z
                tym dokumentacji medycznej:
                <ol>
                  <li>imię (imiona) i nazwisko (nazwiska),</li>
                  <li>data urodzenia,</li>
                  <li>płeć,</li>
                  <li>obywatelstwo,</li>
                  <li>adres miejsca zamieszkania,</li>
                  <li>
                    w przypadku osoby małoletniej również dane opiekuna
                    prawnego,
                  </li>
                  <li>
                    numer PESEL, jeżeli został nadany lub inny numer ewidencyjny
                    (w przypadku braku numeru PESEL),
                  </li>
                  <li>
                    dane i dokumenty dotyczące stanu zdrowia Pacjenta
                    udzielonych mu dotychczas świadczeniach, niezbędne do
                    realizacji Świadczenia Konsultacji On-Line oraz Konsultacji
                    Stacjonarnej,
                  </li>
                  <li>
                    adres e-mail i numer telefonu kontaktowego (z wyłączeniem
                    numeru telefonu stacjonarnego).
                  </li>
                </ol>
              </li>
              <li>
                Dane, o których mowa w punkcie powyżej, Pacjent może wysłać na
                adres e-mail Administratora lub przekazać osobiście w czasie
                zbierania przez Specjalistę wywiadu osobowego.
              </li>
              <li>
                W uzasadnionych medycznie przypadkach, Specjalista może prosić
                Pacjenta o skierowanie lekarskie na określony zakres usługi.
              </li>
              <li>
                Specjalista prowadzi dokumentację medyczną zgodnie z wytycznymi
                Krajowej Izby Fizjoterapeutów lub innych organów samorządu
                zawodowego dla właściwego zawodu medycznego.
              </li>
              <li>
                Usługa Stacjonarna może być poprzedzona przeprowadzeniem ankiety
                dotyczącej m.in. ogólnego stanu zdrowia Pacjenta,
                dotychczasowego leczenia, wyników badań, przyjmowanych leków czy
                predyspozycji genetycznych.
              </li>
              <li>
                Specjalista przeprowadza badanie Pacjenta, które ma na celu
                ocenę stanu jego zdrowia oraz dobranie właściwych metod
                leczenia.
              </li>
            </ul>
          </li>
          <li>
            Warunki świadczenia Usługi Stacjonarnej
            <ul>
              <li>Usługi Konsultacji są świadczone odpłatnie.</li>
              <li>
                Wysokość opłat określa cennik Usług dostępny na stronie Serwisu
                pod adresem <a href="https://jat-med.com">jat-med.com</a>.
                Usługi medyczne zwolnione są z podatku VAT.
              </li>
              <li>
                Zamówienia Usługi oraz opłaty za nią można dokonać za
                pośrednictwem systemu udostępnionego na stronie Serwisu zgodnie
                z zasadami określonymi w regulaminie Serwisu dostępnego pod
                adresem{" "}
                <Link
                  target="_blank"
                  aria-label="Jat Med regulamin serwisu"
                  to={"https://jat-med.com/policy/website-terms-of-use"}
                >
                  jat-med.com/policy/website-terms-of-use
                </Link>{" "}
              </li>
              <li>
                Opłaty należy dokonać przed wykonaniem usługi, lub bezpośrednio
                po jej zakończeniu.
              </li>
              <li>
                Dostępne formy płatności: przelew bankowy na konto
                Administratora 17 1240 1183 1111 0011 5020 1417 płatność kartą
                płatniczą Visa, Master Card w miejscu wykonania usługi.
              </li>
              <li>
                Realizacja Usługi możliwa jest tylko po uprzedniej rezerwacji
                terminu wizyty zgodnie z poniższą procedurą:
                <ol>
                  <li>
                    rezerwacja terminu wizyty możliwa jest: telefonicznie, w tym
                    celu należy skontaktować się z Administratorem, dzwoniąc na
                    numer <a href="tel:+48518468678">+48518468678</a>; mailowo,
                    w tym celu należy skontaktować się z Administratorem pisząc
                    na adres:{" "}
                    <a href="mailto:jatmedcom2018@gmail.com">
                      jatmedcom2018@gmail.com
                    </a>{" "}
                    lub przez wyszczególnione na stronie internetowej{" "}
                    <a href="https://jat-med.com">jat-med.com</a> formy
                    komunikacji.
                  </li>
                  <li>
                    zmiana terminu wizyty, jest możliwa nieodpłatnie do godziny
                    18 dnia poprzedzającego dzień wizyty, np. wizytę na godzinę
                    12 w dniu 28 lipca 2024 roku, można odwołać nieodpłatnie do
                    godziny 18 dnia 27 lipca 2024 roku;
                  </li>
                  <li>
                    niezachowanie granicznego terminu odwołania wizyty,
                    określonego w pkt. b powyżej, skutkuje koniecznością opłaty
                    50% ceny usługi z tytułu gotowości Specjalisty do
                    świadczenia Usługi;
                  </li>
                  <li>
                    niepoinformowanie o fakcie odwołania lub chęci przełożenia
                    Usługi, skutkuje koniecznością pokrycia 100% opłaty za
                    zarezerwowaną Usługę Stacjonarną;
                  </li>
                  <li>
                    wszelkie zmiany w terminach wizyt należy zgłaszać
                    telefonicznie lub mailowo, na opublikowane na stronie{" "}
                    <a href="https://jat-med.com">jat-med.com</a> dane
                    kontaktowe.
                  </li>
                </ol>
              </li>
              <li>
                Pacjent zobowiązany jest do terminowego wstawienia się w
                umówione miejsce wykonywania Usługi.
                <ol>
                  <li>
                    W przypadku opóźnienia godziny wykonywania Usługi z winy
                    Pacjenta, Specjalista nie ma obowiązku przedłużania
                    zarezerwowanego na poczet wykonania danej usługi czasu i ma
                    prawo do jej zakończenia w terminie wyznaczonym na czas jej
                    realizacji.
                  </li>
                  <li>
                    W przypadku opóźnienia godziny wykonywania Usługi z winy
                    Specjalisty, zobowiązany jest on do wykonania usługi w
                    pełnym czasie, przewidzianym do jej wykonania.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Zasady odpowiedzialności
            <ul>
              <li>
                Administrator/Specjalista i Użytkownik/Pacjent zobowiązani są do
                naprawienia szkody, jaką druga strona Umowy poniosła na skutek
                niewykonania lub nienależytego wykonania przez nich obowiązków
                wynikających z Umowy lub Regulaminu, chyba że ich niewykonanie
                lub nienależyte wykonanie było następstwem okoliczności, za
                które strona ta nie ponosi odpowiedzialności.
              </li>
              <li>
                Administrator/Specjalista nie ponosi odpowiedzialności za
                podanie przez Użytkownika/Pacjenta niekompletnych,
                nieprawdziwych lub nieprawidłowych informacji, zwłaszcza w
                przypadku podania danych osób trzecich bez ich wiedzy lub zgody.
                Za skutki podania błędnych, niekompletnych, nieprawdziwych,
                wprowadzających w błąd lub w inny sposób nieprawidłowych danych
                wyłączna odpowiedzialność spoczywa na Użytkowniku/Pacjencie.
              </li>
              <li>
                Administrator/Specjalista nie ponosi odpowiedzialności za skutki
                korzystania z Usług przez Użytkownika/Pacjenta w sposób
                sprzeczny z Regulaminem.
              </li>
              <li>
                Administrator/Specjalista nie ponosi odpowiedzialności za
                szkody:
                <ol>
                  <li>
                    wyrządzone podmiotom trzecim, powstałe w wyniku korzystania
                    przez Użytkowników/Pacjentów z usług w sposób sprzeczny z
                    Regulaminem lub przepisami prawa,
                  </li>
                  <li>
                    powstałe na skutek braku ciągłości dostarczania Usług,
                    będące następstwem okoliczności, za które
                    Administrator/Specjalista nie ponosi odpowiedzialności, np.
                    wskutek działania siły wyższej, działania i zaniechania osób
                    trzecich, z wyjątkiem osób, za które
                    Administrator/Specjalista ponosi odpowiedzialność na mocy
                    przepisów prawa.
                  </li>
                  <li>
                    powstałe w wyniku podanie przez Użytkownika/Pacjenta
                    nieprawdziwych lub niepełnych informacji przy rejestracji.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Reklamacje
            <ul>
              <li>
                Pacjentowi przysługuje prawo zgłaszania zapytań, uwag i
                reklamacji w związku z wykonywaniem Usługi Stacjonarnej.
                Zapytania, uwagi i reklamacje dotyczące świadczonej usługi
                należy zgłaszać na adres e-mail{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                Termin rozpatrzenia reklamacji wynosi 14 dni roboczych, chyba że
                odpowiedni Regulamin Organizacyjny przewiduje krótszy termin.
              </li>
              <li>
                Treść reklamacji powinna zawierać, co najmniej:
                <ol>
                  <li>
                    dane pozwalające na identyfikację Pacjenta: login
                    Użytkownika, imię i nazwisko Pacjenta, adres e-mail, adres
                    pocztowy (dla reklamacji składanych listownie);
                  </li>
                  <li>określenie przedmiotu reklamacji,</li>
                  <li>określenie ewentualnych żądań Pacjenta,</li>
                  <li>
                    wskazanie daty zaistnienia zdarzenia świadczącego o
                    nienależytym wykonaniu Usługi Stacjonarnej.
                  </li>
                </ol>
              </li>
              <li>
                Postępowanie reklamacyjne nie wyłącza innych uprawnień
                przysługujących Użytkownikowi na mocy przepisów prawa.
              </li>
            </ul>
          </li>
          <li>
            Przetwarzanie danych osobowych
            <ul>
              <li>
                Poprzez zlecenie Konsultacji On-line Użytkownik wyraża zgodę na
                przetwarzanie jego danych osobowych na potrzeby wykonania Usługi
                oraz rozliczeń z tym związanych, jak również na potrzeby
                postępowania reklamacyjnego. Szczegółowe zasady oraz cele
                przetwarzania danych osobowych gromadzonych podczas korzystania
                przez Użytkownika z Serwisu zostały określone w Klauzuli
                dostępnej pod adresem{" "}
                <a
                  target="_blank"
                  aria-label="Jat Med polityka prywatności"
                  href={"https://jat-med.com/policy/personal-data-processing"}
                  rel="noreferrer"
                >
                  https://jat-med.com/policy/personal-data-processing
                </a>
              </li>
            </ul>
          </li>
          <li>
            Postanowienia końcowe
            <ul>
              <li>
                Regulamin jest dostępny na stronie Serwisu oraz w siedzibie
                Administratora. Regulamin – na żądanie Użytkownika przesłane
                pocztą elektroniczną na adres e-mail:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>{" "}
                – zostanie przekazany Użytkownikowi nieodpłatnie w wersji
                elektronicznej na podany przez Użytkownika adres e-mail.
              </li>
              <li>
                Administrator zastrzega sobie prawo do dokonywania zmian
                Regulaminu z ważnych przyczyn to jest: zmiany przepisów prawa,
                zmiany sposobów płatności i dostaw – w zakresie, w jakim te
                zmiany wpływają na realizację postanowień niniejszego
                Regulaminu.
              </li>
              <li>
                O każdej zmianie Administratora poinformuje Użytkownika z co
                najmniej 14 dniowym wyprzedzeniem. W terminie 14 dni od dnia
                otrzymania informacji o zmianie Użytkownik może odmówić jej
                akceptacji – e-mailem przesłanym na adres{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
                . W takim przypadku Konto Użytkownika zostanie zlikwidowane w
                dniu wejścia w życie zmiany.
              </li>
              <li>
                Zamówienia dokonane w Serwisie, które nastąpiły przed dniem
                wejścia w życie zmian podlegają zasadom dotychczasowym.
              </li>
              <li>
                W sprawach nieuregulowanych w Regulaminie mają zastosowanie
                postanowienia regulaminu Serwisu dostępnego pod adresem{" "}
                <a href="https://jat-med.com/policy/website-terms-of-use">
                  jat-med.com/policy/website-terms-of-use
                </a>
                , a także powszechnie obowiązujące przepisy prawa polskiego, w
                szczególności: Kodeksu cywilnego; ustawy o świadczeniu usług
                drogą elektroniczną; ustawy o prawach konsumenta, ustawy o
                ochronie danych osobowych, ustawy i prawach pacjenta i rzeczniku
                praw pacjenta, ustawy o działalności leczniczej oraz inne
                właściwe przepisy prawa.
              </li>
            </ul>
          </li>
        </ol>
      )}
      {i18next.resolvedLanguage === "en" && (
        <ol>
          <li>
            General Provisions
            <ul>
              <li>
                The Regulations concern the principles of using the stationary
                service "Stationary Consultation" (hereinafter "Service"), the
                entity providing the service, remuneration, complaint procedure
                and processing of personal data of the person using the Service.
              </li>
              <li>
                At the request of the User or the person starting to use the
                Service, the Regulations may be sent electronically to the
                e-mail address indicated by the interested party.
              </li>
            </ul>
          </li>
          <li>
            Definitions
            <ul>
              <li>
                <b>Administrator</b> - Jat-med sp. z o.o. with its registered
                office in Bydgoszcz at 17 Kruszyńska Street, entered into the
                register of entrepreneurs of the National Court Register
                maintained by the District Court in BYDGOSZCZ, XIII COMMERCIAL
                DIVISION OF THE NATIONAL COURT REGISTER, under KRS number
                0001150821, NIP 9671483929, REGON 540725908.
              </li>
              <li>
                <b>Service</b> – an internet service run by the Administrator at{" "}
                <a href="https://jat-med.com">jat-med.com</a>, sells Products,
                provides Files containing materials for the treatment and
                prevention of musculoskeletal disorders, and provides other
                services specified in the Regulations, including the possibility
                of concluding an Agreement for Online Consultations and
                Stationary Consultations.
              </li>
              <li>
                <b>User</b> – a natural person, legal person and an
                organizational unit without legal personality, visiting the
                Service or using the services of the Service.
              </li>
              <li>
                <b>Patient</b> – a natural person using the Consultation
                directly (including the user).
              </li>
              <li>
                <b>Specialist</b> – a doctor, nurse, physiotherapist or other
                person not listed above performing activities in the scope of
                health services, providing health, medical or paramedical
                services.
              </li>
              <li>
                <b>Stationary Service, also referred to as Service</b> – a
                service provided by the Specialist directly in the office under
                the terms specified in the Regulations.
              </li>
              <li>
                <b>Stationary Services Agreement</b> – an agreement for the
                provision of services in the form of Stationary Services
                concluded or entered into between the User and the Specialist
                via the Service, or at the place where the service is performed.
              </li>
              <li>
                <b>Regulations</b> – Regulations of the stationary service
                provided in the office run by the Administrator.
              </li>
            </ul>
          </li>
          <li>
            Rules for the use of Stationary Services
            <ul>
              <li>
                The commencement of the use of Stationary Services is
                conditioned by the acceptance of these Regulations by the
                Patient, and the acceptance of the possibility of providing the
                service by the Specialist.
              </li>
              <li>
                Types of Stationary Services:
                <ol>
                  <li>Health consultations;</li>
                  <li>advice or;</li>
                  <li>health information;</li>
                  <li>manual procedures;</li>
                  <li>health exercises;</li>
                </ol>
              </li>
              <li>
                The Stationary Service is a health service that may be provided
                on condition that the Patient provides, no later than the date
                of the agreed Service, the following data necessary to provide
                the service and maintain medical documentation in connection
                therewith:
                <ol>
                  <li>first name(s) and last name(s),</li>
                  <li>date of birth,</li>
                  <li>gender,</li>
                  <li>citizenship,</li>
                  <li>address of residence,</li>
                  <li>
                    in the case of a minor, also the data of the legal guardian,
                  </li>
                  <li>
                    PESEL number, if assigned, or another registration number
                    (in the absence of a PESEL number),
                  </li>
                  <li>
                    data and documents concerning the Patient's health condition
                    in the services provided to him/her so far, necessary to
                    perform the Online Consultation and Stationary Consultation,
                  </li>
                  <li>
                    e-mail address and contact telephone number (excluding
                    landline telephone number).
                  </li>
                </ol>
              </li>
              <li>
                The Patient may send the data referred to in the point above to
                the Administrator's e-mail address or provide it in person when
                the Specialist collects the personal interview.
              </li>
              <li>
                In medically justified cases, the Specialist may ask the Patient
                for a medical referral for a specific scope of service.
              </li>
              <li>
                The Specialist maintains medical records in accordance with the
                guidelines of the National Chamber of Physiotherapists or other
                professional self-government bodies for the relevant medical
                profession.
              </li>
              <li>
                The Stationary Service may be preceded by a survey regarding,
                among others, the Patient's general health condition, previous
                treatment, test results, medications taken or genetic
                predispositions.
              </li>
              <li>
                The Specialist conducts an examination of the Patient, which
                aims to assess the patient's health condition and select
                appropriate treatment methods.
              </li>
            </ul>
          </li>
          <li>
            Conditions of provision of Stationary Service
            <ul>
              <li>Consultation Services are provided for a fee.</li>
              <li>
                The amount of fees is specified in the price list of Services
                available on the Service website at{" "}
                <a href="https://jat-med.com">jat-med.com</a>. Medical services
                are exempt from VAT.
              </li>
              <li>
                The Service can be ordered and paid for via the system available
                on the Service website in accordance with the principles
                specified in the Service regulations available at{" "}
                <a href="https://jat-med.com/policy/website-terms-of-use">
                  jat-med.com/policy/website-terms-of-use
                </a>
              </li>
              <li>
                The fee must be paid before the service is provided, or
                immediately after its completion.
              </li>
              <li>
                Available forms of payment: bank transfer to the Administrator's
                account 17 1240 1183 1111 0011 5020 1417, payment by Visa or
                Master Card at the place of service provision.
              </li>
              <li>
                The Service can only be provided after prior booking of the
                appointment in accordance with the following procedure:
                <ol>
                  <li>
                    booking of the appointment is possible: by phone, to do
                    this, contact the Administrator by calling{" "}
                    <a href="tel:+48518468678">+48518468678</a>; by e-mail, for
                    this purpose, contact the Administrator by writing to the
                    address:{" "}
                    <a href="mailto:jatmedcom2018@gmail.com">
                      jatmedcom2018@gmail.com
                    </a>{" "}
                    or via the forms of communication specified on the website{" "}
                    <a href="https://jat-med.com">jat-med.com</a>.
                  </li>
                  <li>
                    changing the date of the visit is possible free of charge
                    until 6 p.m. on the day preceding the day of the visit, e.g.
                    a visit at 12 p.m. on July 28, 2024, can be canceled free of
                    charge until 6 p.m. on July 27, 2024;
                  </li>
                  <li>
                    failure to meet the deadline for canceling the visit,
                    specified in point b above, results in the necessity to pay
                    50% of the price of the service due to the Specialist's
                    readiness to provide the Service;
                  </li>
                  <li>
                    failure to inform about the fact of cancellation or the
                    desire to postpone the Service, results in the necessity to
                    cover 100% of the fee for the booked Stationary Service;
                  </li>
                  <li>
                    any changes in the dates of visits should be reported by
                    phone or e-mail, to the contact details published on the
                    website <a href="https://jat-med.com">jat-med.com</a>.
                  </li>
                </ol>
              </li>
              <li>
                The Patient is obliged to arrive at the agreed place of
                performance of the Service on time.
                <ol>
                  <li>
                    In the event of a delay in the time of performance of the
                    Service due to the Patient's fault, the Specialist is not
                    obliged to extend the time reserved for the performance of a
                    given service and has the right to complete it within the
                    time specified for its performance.
                  </li>
                  <li>
                    In the event of a delay in the time of performance of the
                    Service due to the Specialist's fault, he is obliged to
                    perform the service in the full time provided for its
                    performance.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Principles of liability
            <ul>
              <li>
                The Administrator/Specialist and the User/Patient are obliged to
                repair the damage that the other party to the Agreement has
                suffered as a result of their failure to perform or improper
                performance of their obligations resulting from the Agreement or
                the Regulations, unless their failure to perform or improper
                performance was a consequence of circumstances for which that
                party is not responsible.
              </li>
              <li>
                The Administrator/Specialist is not liable for the provision of
                incomplete, false or incorrect information by the User/Patient,
                especially in the case of providing data of third parties
                without their knowledge or consent. The User/Patient is solely
                responsible for the consequences of providing incorrect,
                incomplete, untrue, misleading or otherwise incorrect data.
              </li>
              <li>
                The Administrator/Specialist is not responsible for the
                consequences of using the Services by the User/Patient in a
                manner inconsistent with the Regulations.
              </li>
              <li>
                The Administrator/Specialist is not responsible for damages:
                <ol>
                  <li>
                    caused to third parties, resulting from the use of the
                    services by Users/Patients in a manner inconsistent with the
                    Regulations or legal regulations,
                  </li>
                  <li>
                    resulting from the lack of continuity in the provision of
                    Services, resulting from circumstances for which the
                    Administrator/Specialist is not responsible, e.g. as a
                    result of force majeure, actions and omissions of third
                    parties, except for persons for whom the
                    Administrator/Specialist is responsible under the provisions
                    of the law.
                  </li>
                  <li>
                    resulting from the provision by the User/Patient of untrue
                    or incomplete information during registration.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Complaints
            <ul>
              <li>
                The Patient has the right to submit inquiries, comments and
                complaints in connection with the provision of the Stationary
                Service. Inquiries, comments and complaints regarding the
                service provided should be submitted to the e-mail address{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                The deadline for considering the complaint is 14 working days,
                unless the relevant Organizational Regulations provide for a
                shorter period.
              </li>
              <li>
                The content of the complaint should include, at least:
                <ol>
                  <li>
                    data allowing for the identification of the Patient: User
                    login, Patient's name and surname, e-mail address, postal
                    address (for complaints submitted by post);
                  </li>
                  <li>specification of the subject of the complaint,</li>
                  <li>specification of the Patient's possible demands,</li>
                  <li>
                    indication of the date of the event indicating the improper
                    performance of the Stationary Service.
                  </li>
                </ol>
              </li>
              <li>
                The complaint procedure does not exclude other rights to which
                the User is entitled under the provisions of law.
              </li>
            </ul>
          </li>
          <li>
            Processing of personal data
            <ul>
              <li>
                By ordering an Online Consultation, the User consents to the
                processing of his/her personal data for the purposes of
                performing the Service and settlements related to it, as well as
                for the purposes of the complaint procedure. Detailed principles
                and purposes of processing personal data collected during the
                User's use of the Service have been specified in the Clause
                available at{" "}
                <a href="https://jat-med.com/policy/personal-data-processing">
                  https://jat-med.com/policy/personal-data-processing
                </a>
              </li>
            </ul>
          </li>
          <li>
            Final provisions
            <ul>
              <li>
                The Regulations are available on the Service website and at the
                Administrator's office. The Regulations - at the User's request
                sent by e-mail to the e-mail address:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>{" "}
                - will be provided to the User free of charge in electronic form
                to the e-mail address provided by the User.
              </li>
              <li>
                The Administrator reserves the right to make changes to the
                Regulations for important reasons, i.e.: changes in legal
                regulations, changes in payment and delivery methods - to the
                extent to which these changes affect the implementation of the
                provisions of these Regulations.
              </li>
              <li>
                The Administrator will inform the User of any change at least 14
                days in advance. Within 14 days of receiving information about
                the change, the User may refuse to accept it - by e-mail sent to{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
                . In such a case, the User Account will be closed on the day the
                change comes into effect.
              </li>
              <li>
                Orders placed on the Website that occurred before the date the
                change comes into effect are subject to the previous rules.
              </li>
              <li>
                In matters not regulated in the Regulations, the provisions of
                the regulations of the Website available at{" "}
                <a href="https://jat-med.com/policy/website-terms-of-use">
                  jat-med.com/policy/website-terms-of-use
                </a>
                , as well as generally applicable provisions of Polish law, in
                particular: the Civil Code; the Act on the provision of services
                by electronic means; the Act on consumer rights, the Act on the
                protection of personal data, the Act and the rights of the
                patient and the patient's advocate, the Act on medical activity
                and other relevant provisions of law.
              </li>
            </ul>
          </li>
        </ol>
      )}
      {i18next.resolvedLanguage === "uk" && (
        <ol>
          <li>
            Загальні положення
            <ul>
              <li>
                Положення поширюється на принципи користування стаціонарною
                послугою «Стаціонарна консультація» (далі «Сервіс»), суб’єкта
                надання послуги, оплату праці, порядок розгляду скарг та обробку
                персональних даних особи, яка користується Сервісом.
              </li>
              <li>
                За бажанням Користувача або особи, яка починає користуватися
                Сервісом, Правила можуть бути надіслані в електронному вигляді
                на електронну адресу, вказану зацікавленою особою.
              </li>
            </ul>
          </li>
          <li>
            Визначення
            <ul>
              <li>
                <b>Адміністратор</b> – Jat-med sp. з о.о. зареєстрований у м.
                Бидгощ на вул. Крушинська 17, внесений до реєстру підприємців
                Національного судового реєстру, який веде Окружний суд у
                БИДГОЩІ, XIII КОМЕРЦІЙНИЙ ВІДДІЛ НАЦІОНАЛЬНОГО СУДОВОГО РЕЄСТРУ,
                під номером KRS 0001150821, NIP 9671483929, REGON 540725908.
              </li>
              <li>
                <b>Веб-сайт</b> – веб-сайт, керований Адміністратором за адресою{" "}
                <a href="https://jat-med.com">jat-med.com</a>, який реалізує
                Продукцію, надає Файли, що містять матеріали для лікування та
                профілактики захворювань опорно-рухового апарату, а також надає
                інші послуги, зазначені в Регламенті, включаючи можливість
                укладення Договору на онлайн-консультації та стаціонарні
                консультації.
              </li>
              <li>
                <b>Користувач</b> – фізична особа, юридична особа та
                організаційна одиниця без статусу юридичної особи, які
                відвідують Веб-сайт або використовують послуги Веб-сайту.
              </li>
              <li>
                <b>Пацієнт</b> – фізична особа, яка безпосередньо користується
                Консультацією (в тому числі користувач).
              </li>
              <li>
                <b>Спеціаліст</b> – лікар, медична сестра, фізіотерапевт або
                інша особа, не зазначена вище, яка здійснює діяльність у сфері
                охорони здоров’я, надає медичні, медичні чи фельдшерські
                послуги.
              </li>
              <li>
                <b>Стаціонарна послуга, також Послуга</b> – послуга, що
                надається Спеціалістом безпосередньо в офісі на умовах,
                визначених Регламентом.
              </li>
              <li>
                <b>Договір про надання стаціонарних послуг</b> – договір про
                надання послуг у формі стаціонарних послуг, що укладається або
                укладається між Користувачем і Спеціалістом через Сайт або в
                місці надання послуг.
              </li>
              <li>
                <b>Положення</b> – Положення стаціонарної послуги, що надаються
                офісі, яким керує Адміністратор.
              </li>
            </ul>
          </li>
          <li>
            Правила користування стаціонарними послугами
            <ul>
              <li>
                Використання Стаціонарних послуг обумовлюється прийняттям
                Пацієнтом цих Правил та згодою на можливість надання послуги
                Спеціалістом.
              </li>
              <li>
                Види стаціонарних послуг:
                <ol>
                  <li>Медичні консультації;</li>
                  <li>Порада або;</li>
                  <li>Інформація про стан здоров'я;</li>
                  <li>Мануальні обробки;</li>
                  <li>Оздоровчі вправи;</li>
                </ol>
              </li>
              <li>
                Стаціонарна послуга – це медична послуга, яка може надаватися за
                умови надання пацієнтом наступних даних, необхідних для надання
                послуги та ведення медичної документації у зв’язку з нею, не
                пізніше дати узгодженої послуги:
                <ol>
                  <li>ім'я (імена) та прізвище (імена),</li>
                  <li>дата народження,</li>
                  <li>стать,</li>
                  <li>громадянство,</li>
                  <li>адреса проживання,</li>
                  <li>
                    у випадку неповнолітньої особи також відомості про законного
                    опікуна,
                  </li>
                  <li>
                    Номер PESEL, якщо його присвоєно, або інший реєстраційний
                    номер (за відсутності номера PESEL),
                  </li>
                  <li>
                    дані та документи щодо стану здоров’я Пацієнта та наданих
                    йому/їй послуг до цього часу, необхідних для надання он-лайн
                    консультації та стаціонарної консультації,
                  </li>
                  <li>
                    адреса електронної пошти та контактний телефон (крім
                    стаціонарного).
                  </li>
                </ol>
              </li>
              <li>
                Дані, зазначені в пункті вище, можуть бути надіслані Пацієнтом
                на електронну адресу Адміністратора або надані особисто під час
                збору особистої інформації Спеціалістом.
              </li>
              <li>
                У медичних обґрунтованих випадках Спеціаліст може вимагати у
                Пацієнта направлення на певний обсяг послуг.
              </li>
              <li>
                Спеціаліст веде медичну документацію відповідно до вказівок
                Національної палати фізіотерапевтів або інших органів
                професійного самоврядування відповідної медичної професії.
              </li>
              <li>
                Стаціонарній службі може передувати опитування щодо, зокрема:
                загальний стан здоров’я пацієнта, попередні лікування,
                результати аналізів, прийняті ліки або генетична схильність.
              </li>
              <li>
                Спеціаліст проводить огляд пацієнта, метою якого є оцінка стану
                його здоров'я та вибір відповідних методів лікування.
              </li>
            </ul>
          </li>
          <li>
            Умови надання Стаціонарної послуги
            <ul>
              <li>Консультаційні послуги надаються за плату.</li>
              <li>
                Сума гонорару вказана в прейскуранті Послуг, доступному на
                веб-сайті Сервісу за адресою{" "}
                <a href="https://jat-med.com">jat-med.com</a>. Медичні послуги
                звільняються від ПДВ.
              </li>
              <li>
                Замовлення Послуги та оплата за неї можуть здійснюватися через
                систему, доступну на веб-сайті Послуги, відповідно до принципів,
                викладених у положеннях про Послугу, доступних на сайті{" "}
                <a href="jat-med.com/policy/website-terms-of-use">
                  jat-med.com/policy/website-terms-of-use
                </a>
              </li>
              <li>
                Оплата повинна бути здійснена до надання послуги або відразу
                після її виконання.
              </li>
              <li>
                Доступні способи оплати: банківський переказ на рахунок
                Адміністратора 17 1240 1183 1111 0011 5020 1417, оплата картами
                Visa, Master Card за місцем надання послуг.
              </li>
              <li>
                Послугу можна надати лише після запису на прийом відповідно до
                такої процедури:
                <ol>
                  <li>
                    Ви можете записатися на візит по телефону: для цього
                    зв'яжіться з адміністратором за телефоном{" "}
                    <a href="tel:+48518468678">+48518468678</a>; електронною
                    поштою, для цього зв’яжіться з Адміністратором, написавши на
                    адресу{" "}
                    <a href="mailto:jatmedcom2018@gmail.com">
                      jatmedcom2018@gmail.com
                    </a>{" "}
                    або за допомогою форм зв’язку, зазначених на сайті{" "}
                    <a href="https://jat-med.com">jat-med.com</a>.
                  </li>
                  <li>
                    Зміна дати візиту безкоштовна до 18.00, наприклад, запис на
                    28.07.2024.
                  </li>
                  <li>
                    Недотримання терміну скасування візиту, зазначеного в п.п b
                    вище, призводить до необхідності сплати 50% вартості послуги
                    у зв’язку з готовністю Спеціаліста надати Послугу;
                  </li>
                  <li>
                    Неповідомлення про факт скасування або про намір перенести
                    Послугу призведе до необхідності оплати 100% вартості
                    замовленої Стаціонарної послуги;
                  </li>
                  <li>
                    Про будь-які зміни дат прийому необхідно повідомляти по
                    телефону або електронною поштою за контактними даними,
                    розміщеними на сайті{" "}
                    <a href="https://jat-med.com">jat-med.com</a>.
                  </li>
                </ol>
              </li>
              <li>
                Пацієнт зобов'язаний вчасно прибути в узгоджене місце надання
                Послуги.
                <ol>
                  <li>
                    У разі затримки терміну виконання Послуги з вини Пацієнта,
                    Спеціаліст не зобов'язаний продовжувати час, відведений для
                    виконання даної послуги, і має право завершити її у
                    встановлений для її виконання термін.
                  </li>
                  <li>
                    У разі затримки терміну виконання Послуги з вини
                    Спеціаліста, він зобов’язаний виконати послугу в повний
                    термін, передбачений для її виконання.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Принципи відповідальності
            <ul>
              <li>
                Адміністратор/спеціаліст і користувач/пацієнт зобов’язані
                відшкодувати будь-яку шкоду, заподіяну іншою стороною Угоди
                внаслідок невиконання або неналежного виконання ними своїх
                зобов’язань за Угодою чи Правилами, за винятком випадків, коли
                невиконання або неналежне виконання ними було наслідком
                обставин, за які ця сторона не несе відповідальності.
              </li>
              <li>
                Адміністратор/Спеціаліст не несе відповідальності за будь-яку
                неповну, неправдиву або невірну інформацію, надану
                Користувачем/Пацієнтом, особливо у разі надання даних третіх
                осіб без їх відома чи згоди. Користувач/Пацієнт несе виключну
                відповідальність за наслідки надання невірних, неповних,
                неправдивих, оманливих чи інших невірних даних.
              </li>
              <li>
                Адміністратор/Спеціаліст не несе відповідальності за наслідки
                використання Користувачем/Пацієнтом Сервісів у спосіб, що не
                відповідає Правилам.
              </li>
              <li>
                Адміністратор/Спеціаліст не несе відповідальності за будь-які
                збитки:
                <ol>
                  <li>
                    заподіяні третім сторонам у результаті використання послуг
                    Користувачами/Пацієнтами у спосіб, що не відповідає Правилам
                    або правовим положенням,
                  </li>
                  <li>
                    виникають внаслідок відсутності безперервності в наданні
                    Послуг внаслідок обставин, за які Адміністратор/Спеціаліст
                    не несе відповідальності, наприклад, внаслідок форс-мажорних
                    обставин, дій та бездіяльності третіх осіб, за винятком
                    осіб, за яких Адміністратор/Спеціаліст несе відповідальність
                    згідно із законом.
                  </li>
                  <li>
                    внаслідок надання Користувачем/Пацієнтом неправдивої або
                    неповної інформації під час реєстрації.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Скарги
            <ul>
              <li>
                Пацієнт має право подавати запити, зауваження та скарги у
                зв'язку з наданням Стаціонарної послуги. Запитання, зауваження
                та скарги щодо наданої послуги просимо надсилати на електронну
                адресу{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                Строк розгляду скарги становить 14 робочих днів, якщо
                відповідним Положенням про Організацію не встановлено менший
                термін.
              </li>
              <li>
                Зміст скарги має містити щонайменше:
                <ol>
                  <li>
                    дані, що дозволяють ідентифікувати Пацієнта: Логін
                    користувача, ім’я та прізвище Пацієнта, адреса електронної
                    пошти, поштова адреса (для скарг, які надсилаються поштою);
                  </li>
                  <li>опис предмета скарги,</li>
                  <li>визначення будь-яких запитів Пацієнта,</li>
                  <li>
                    зазначення дати події, що свідчить про неналежне виконання
                    Стаціонарної послуги.
                  </li>
                </ol>
              </li>
              <li>
                Процедура оскарження не виключає інших прав, які Користувач має
                згідно із законом.
              </li>
            </ul>
          </li>
          <li>
            Обробка персональних даних
            <ul>
              <li>
                Замовляючи онлайн-консультацію, Користувач дає згоду на обробку
                його персональних даних для цілей надання Сервісу та пов’язаних
                з цим розрахунків, а також для цілей розгляду скарг. Детальні
                принципи та цілі обробки персональних даних, зібраних під час
                використання Користувачем Сервісу, описані в пункті, доступному
                на сайті{" "}
                <a href="https://jat-med.com/policy/personal-data-processing">
                  https://jat-med.com/policy/personal-data-processing
                </a>
              </li>
            </ul>
          </li>
          <li>
            Прикінцеві положення
            <ul>
              <li>
                З Регламентом можна ознайомитись на Сайті та в офісі
                Адміністратора. Правила – за бажанням Користувача, надісланим
                електронною поштою на електронну адресу:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>{" "}
                – будуть безоплатно надіслані Користувачеві в електронному
                вигляді на вказану Користувачем адресу електронної пошти.
              </li>
              <li>
                Адміністратор залишає за собою право вносити зміни в Правила з
                важливих причин, наприклад, зміни в правових положеннях, зміни в
                методах оплати та доставки – у тій мірі, в якій ці зміни
                впливають на виконання положень цих Правил.
              </li>
              <li>
                Адміністратор повідомить Користувача про будь-які зміни
                принаймні за 14 днів. Протягом 14 днів з моменту отримання
                інформації про зміну Користувач може відмовитися від її
                прийняття – електронною поштою на адресу{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
                . У такому випадку обліковий запис користувача буде закрито в
                день, коли зміни набудуть чинності.
              </li>
              <li>
                Замовлення, розміщені на Веб-сайті до набрання чинності змінами,
                регулюються існуючими правилами.
              </li>
              <li>
                У питаннях, не врегульованих Регламентом, застосовуються
                положення регламенту Веб-сайту, доступного на{" "}
                <a href="https://jat-med.com/policy/website-terms-of-use">
                  jat-med.com/policy/website-terms-of-use
                </a>
                , а також загальноприйнятні положення польського законодавства,
                зокрема: Цивільного кодексу; Акт про надання послуг засобами
                електронного зв'язку; Закон про права споживачів, Закон про
                захист персональних даних, Закон про права пацієнтів та
                омбудсмена пацієнтів, Закон про медичну діяльність та інші
                відповідні законодавчі положення.
              </li>
            </ul>
          </li>
        </ol>
      )}
    </article>
  );
};

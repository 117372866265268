import React, { FC, useEffect, useState } from "react";
import { AdminSidebar } from "../AdminSidebar";
import { AdminPanelHeader } from "../AdminPanelHeader";
import { UserBadge } from "../../UserBadge";
import styles from "./adminPanelLayout.module.scss";
import { IAdminPanelLayout } from "./adminPanelLayout.interface";
import { Link, Outlet } from "react-router-dom";
import { adminMenuData, patientMenuData, userRoles } from "../../../shared";
import { IAdminMenu } from "../AdminMenuItem/adminMenuItem.interface";

import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../../ConfirmDialog";
import { authApi, userApi } from "../../../store/services";

export const AdminPanelLayout: FC<IAdminPanelLayout> = ({
  handleLogoutRequest,
  currentUser,
}) => {
  const [sidebarMenuData, setSidebarMenuData] = useState<IAdminMenu[]>([]);
  const [isSidebarClose, setIsSidebarClose] = useState<boolean>(false);
  const { t } = useTranslation();

  const [handleAcceptPolicy] = userApi.useAcceptPolicyMutation();
  const [handleLogOut] = authApi.useLogoutMutation();

  const handlePolicyAccept = async () => {
    try {
      await handleAcceptPolicy().unwrap();
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (currentUser?.role === userRoles.patient) {
      setSidebarMenuData(patientMenuData);
    } else {
      setSidebarMenuData(adminMenuData);
    }
  }, [currentUser]);
  return (
    <div className={`${styles.admin_panel_container}`}>
      <AdminSidebar
        sidebarMenuData={sidebarMenuData}
        isSidebarClose={isSidebarClose}
      />
      <div
        className={`${styles.main_part} ${
          isSidebarClose ? styles.closeSidebar : styles.openSidebar
        }`}
      >
        <div className={`${styles.header_wrap}`}>
          <AdminPanelHeader
            setIsSidebarClose={setIsSidebarClose}
            isSidebarClose={isSidebarClose}
          />
          <UserBadge handleLogoutRequest={handleLogoutRequest} />
        </div>
        <Outlet />
      </div>
      <ConfirmDialog
        isOpen={!!currentUser && !currentUser?.isPolicyAccepted}
        title={t("confirmModal.ConfirmationTitle")}
        children={
          <p className={styles.confirmation_description}>
            {t("confirmModal.ConfirmationDescription")}{" "}
            <Link to="/policy/website-terms-of-use" aria-label="Private Policy">
              {t("confirmModal.PrivacyPolicy")}
            </Link>
          </p>
        }
        onConfirm={handlePolicyAccept}
        onCancel={() => handleLogOut()}
      />
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { userRoles } from "../../../shared";
import { credit_card_payment, money_cash, stripe } from "@jat-med/assets";
import { paymentType, PaymentTypesEnum } from "../../../shared/types/payment";

import styles from "./paymentOptions.module.scss";
import VoucherPayment from "../VoucherPayment/VoucherPayment";

interface PaymentOptionsProps {
  currentUser: any;
  setIsStripePayment: (value: boolean) => void;
  onHandleMarkAsPaid: (paymentType: paymentType) => void;
  onHandleVoucherPayment?: (voucherCode: string) => void;
  onValidateVoucher?: (voucherCode: string) => Promise<boolean>;
}

export const PaymentOptions = ({
  currentUser,
  setIsStripePayment,
  onHandleMarkAsPaid,
  onHandleVoucherPayment,
  onValidateVoucher,
}: PaymentOptionsProps) => {
  const { t } = useTranslation();
  const isPatient = currentUser?.role === userRoles.patient;
  const isAdmin = currentUser?.role === userRoles.admin;

  return (
    <div className={styles.payment_container}>
      <div>
        <h2>{t("adminPanel.online")}</h2>
        <button
          className={styles.payment_button}
          onClick={() => setIsStripePayment(true)}
        >
          <img src={stripe} alt={t("buttons.paymentMethodStripe")} />
          {t("buttons.paymentMethodStripe")}
        </button>
        {onHandleVoucherPayment && (
          <VoucherPayment
            onHandleVoucherPayment={onHandleVoucherPayment}
            onValidateVoucher={onValidateVoucher}
          />
        )}
      </div>
      <div>
        <h2>
          {t("adminPanel.cashDesk")}{" "}
          {(isPatient || !currentUser) && (
            <span>{t("availableAtHospital")}</span>
          )}
        </h2>
        <button
          disabled={isPatient || !currentUser}
          className={`${styles.payment_button} ${
            (isPatient || !currentUser) && styles.disabled
          }`}
          onClick={() => onHandleMarkAsPaid(PaymentTypesEnum.CASH)}
        >
          <img src={money_cash} alt="" />
          {t("buttons.paymentMethodCash")}
        </button>
        <button
          disabled={isPatient || !currentUser}
          className={`${styles.payment_button} ${
            (isPatient || !currentUser) && styles.disabled
          }`}
          onClick={() => onHandleMarkAsPaid(PaymentTypesEnum.CREDIT_CARD)}
        >
          <img src={credit_card_payment} alt="" />
          {t("buttons.paymentMethodCard")}
        </button>
      </div>
    </div>
  );
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `article {
  max-width: 1024px;
  margin: 0 auto;
  padding: 20px;
}
article h1 {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 400;
  text-align: center;
}
article p {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
}
article ol {
  font-size: 18px;
  line-height: 1.4;
}
article ol > li {
  font-weight: 700;
  margin-top: 20px;
}
article ol ul li {
  font-weight: 400;
  margin-top: 10px;
}
article ol ul span {
  font-weight: 400;
}

.styles_notDecorated__PqeeS {
  list-style-type: none;
  font-weight: 700;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/Policy/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;EACA,aAAA;AACF;AACE;EACE,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AAEE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;AADJ;AAEI;EACE,gBAAA;EACA,gBAAA;AAAN;AAGM;EACE,gBAAA;EACA,gBAAA;AADR;AAGM;EACE,gBAAA;AADR;;AAOA;EACE,qBAAA;EACA,gBAAA;EACA,gBAAA;AAJF","sourcesContent":["article {\n  max-width: 1024px;\n  margin: 0 auto;\n  padding: 20px;\n\n  h1 {\n    font-size: 32px;\n    margin-bottom: 20px;\n    font-weight: 400;\n    text-align: center;\n  }\n\n  p {\n    font-size: 24px;\n    line-height: 1.5;\n    font-weight: 700;\n    margin-bottom: 20px;\n    text-align: center;\n  }\n\n  ol {\n    font-size: 18px;\n    line-height: 1.4;\n    & > li {\n      font-weight: 700;\n      margin-top: 20px;\n    }\n    ul {\n      li {\n        font-weight: 400;\n        margin-top: 10px;\n      }\n      span {\n        font-weight: 400;\n      }\n    }\n  }\n}\n\n.notDecorated {\n  list-style-type: none;\n  font-weight: 700;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notDecorated": `styles_notDecorated__PqeeS`
};
export default ___CSS_LOADER_EXPORT___;

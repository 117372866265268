import React, { FC, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { IUserDataForm } from "./userDataForm.interface";
import {
  IChangePasswordDto,
  IContactUsDto,
  ILoginDto,
  IRegisterDto,
  IUserDto,
  userRoles,
  userRolesLocales,
} from "../../shared";
import { useAppSelector } from "../../hooks";
import styles from "./userDataForm.module.scss";
import { Link, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import { eye, eye_off } from "@jat-med/assets";

type PasswordFieldProps = {
  input: any;
  meta: any;
  item: any;
  styles: any;
};

const PasswordField = ({ input, meta, item, styles }: PasswordFieldProps) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className={`${styles.input_wrap} `}>
      {!!item.label && <label>{t(item.label)}</label>}
      <input
        {...input}
        type={showPassword ? "text" : item.type}
        placeholder={t(item.placeholder || "")}
        className={`${
          meta.error && meta.touched ? styles.form_input_error : ""
        }`}
      />
      {meta.error && meta.touched && (
        <span className={styles.error_message}>{t(meta.error)}</span>
      )}
      <button
        type="button"
        onClick={handleTogglePassword}
        className={styles.eye_button}
      >
        <img src={showPassword ? eye : eye_off} alt="eye_icon" />
      </button>
    </div>
  );
};

export const UserDataForm: FC<
  IUserDataForm<
    IRegisterDto | ILoginDto | IUserDto | IContactUsDto | IChangePasswordDto
  >
> = ({
  onSubmit,
  formData,
  selectDefaultValue,
  initialValues,
  buttonLabel,
  customStyles,
  hasPasswordValidation = false,
  children,
}) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const currentUser = useAppSelector(
    ({ userReducer }) => userReducer.currentUser
  );
  const isDisabledOption =
    currentUser?.role === userRoles.rehabilitator ||
    currentUser?.role === userRoles.doctor;

  const [selectRoleDefaultValue] = useState(
    userRolesLocales.find((item) => item.value === searchParams.get("role"))
      ?.value
  );

  const getLanguageString = (language: string) =>
    language.startsWith("uk") ? "ua" : language.split("-")[0];

  const validatePasswordsMatch = (values: any) => {
    const errors: any = {};
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = t("adminPanel.form.passwordsDoNotMatch");
    }

    if (values.oldPassword === values.password) {
      errors.password = t("adminPanel.form.passwordsMatchWithOld");
    }

    return errors;
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(e, form) => onSubmit(e as any, form)}
      {...(hasPasswordValidation && {
        validate: validatePasswordsMatch,
      })}
      render={({
        handleSubmit,
        form,
        submitting,
        pristine,
        values,
        hasValidationErrors,
      }) => (
        <form onSubmit={handleSubmit} className={customStyles}>
          {!!formData.length &&
            formData.map((item) => (
              <div key={item.name} className="main_wrap">
                {item.component === "select" && (
                  <div className={`${styles.select_wrap} select_wrap`}>
                    {!!item.label && <label>{t(item.label)}</label>}
                    <Field
                      name={item.name}
                      component={item.component}
                      defaultValue={
                        item.name === "role" && selectDefaultValue
                          ? selectDefaultValue
                          : item.name === "role" && selectRoleDefaultValue
                          ? selectRoleDefaultValue
                          : item.name === "language"
                          ? getLanguageString(i18next.resolvedLanguage || "")
                          : ""
                      }
                      validate={item.validate}
                      disabled={isDisabledOption}
                    >
                      {(item.options || []).map((option) => (
                        <option key={option.value} value={option.value}>
                          {t(option.title)}
                        </option>
                      ))}
                    </Field>
                    <Field
                      name={item.name}
                      subscription={{ error: true }}
                      render={({ meta }) =>
                        meta.error && (
                          <span className={styles.error_message}>
                            {meta.error}
                          </span>
                        )
                      }
                      className={styles.error_message}
                    />
                  </div>
                )}
                {item.component === "input" &&
                  (item.type === "text" ||
                    item.type === "password" ||
                    item.type === "checkbox") && (
                    <>
                      <Field
                        key={item.name}
                        type={item.type}
                        name={item.name}
                        component={item.component}
                        validate={item.validate}
                        {...(item?.parse && { parse: item.parse })}
                      >
                        {({ input, meta }) => (
                          <>
                            {item.type === "password" ? (
                              <PasswordField
                                input={input}
                                meta={meta}
                                item={item}
                                styles={styles}
                              />
                            ) : (
                              <div
                                className={`${styles.input_wrap} ${
                                  item.type === "checkbox" &&
                                  styles.checkbox_wrap
                                }`}
                              >
                                {!!item.label && (
                                  <label
                                    className={`${
                                      item.type === "checkbox" &&
                                      styles.checkbox_label
                                    }`}
                                  >
                                    {t(item.label)}{" "}
                                    {item.type === "checkbox" && (
                                      <Link
                                        to="/policy/personal-data-processing"
                                        target="_blank"
                                        className={styles.policy_link}
                                      >
                                        {t("entriesForms.policyLink")}
                                      </Link>
                                    )}
                                  </label>
                                )}
                                <input
                                  {...input}
                                  type={item.type}
                                  placeholder={t(item.placeholder || "")}
                                  className={`${
                                    meta.error && meta.touched
                                      ? styles.form_input_error
                                      : ""
                                  }`}
                                />
                                {meta.error && meta.touched && (
                                  <span className={styles.error_message}>
                                    {t(meta.error)}
                                  </span>
                                )}
                              </div>
                            )}
                          </>
                        )}
                      </Field>
                    </>
                  )}
                {item.component === "input" && item.type === "tel" && (
                  <>
                    <Field
                      key={item.name}
                      type={item.type}
                      name={item.name}
                      component={item.component}
                      validate={item.validate}
                    >
                      {({ input, meta }) => (
                        <>
                          <div className={`${styles.input_wrap} `}>
                            {!!item.label && <label>{t(item.label)}</label>}
                            <input
                              {...input}
                              type={item.type}
                              placeholder={t(item.placeholder || "")}
                              className={`${
                                meta.error && meta.touched
                                  ? styles.form_input_error
                                  : ""
                              }`}
                              onFocus={(e) => {
                                if (e.target.value === "") {
                                  form.change(item.name, "+");
                                }
                              }}
                            />
                            {meta.error && meta.touched && (
                              <span className={styles.error_message}>
                                {t(meta.error)}
                              </span>
                            )}
                          </div>
                        </>
                      )}
                    </Field>
                  </>
                )}
                {!item.component && item.type === "file" && (
                  <Field type="file" name="image">
                    {({ input: { value, onChange, ...input }, meta }) => (
                      <div className={styles.input_file_wrap}>
                        <label>{t("adminPanel.form.photoLabel")}</label>
                        <input
                          {...input}
                          onChange={({ target }) => onChange(target.files)}
                          type="file"
                          id="image"
                          name="image"
                          accept="image/*"
                        />
                      </div>
                    )}
                  </Field>
                )}
              </div>
            ))}
          {children}
          <div className={styles.buttom_wrap}>
            <button
              type="submit"
              className={`${styles.form_button} ${styles.form_button_save} ${
                submitting && styles.disabled
              } submit_button`}
              disabled={submitting || hasValidationErrors}
            >
              {buttonLabel}
            </button>
          </div>
        </form>
      )}
    />
  );
};

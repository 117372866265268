// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollToTop_container__z\\+HUi {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  cursor: pointer;
  background-color: #32976b;
  color: #fff;
  border-radius: 50%;
  border: none;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.scrollToTop_container__z\\+HUi:hover {
  background-color: #2c7a5f;
  transform: scale(1.1);
}
.scrollToTop_container__z\\+HUi:active {
  transform: scale(0.9);
}

.scrollToTop_hidden__ijiUb {
  transform: translateY(100px);
}`, "",{"version":3,"sources":["webpack://./src/app/components/StrollToTop/scrollToTop.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,eAAA;EACA,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,oBAAA;AACF;AACE;EACE,yBAAA;EACA,qBAAA;AACJ;AAEE;EACE,qBAAA;AAAJ;;AAIA;EACE,4BAAA;AADF","sourcesContent":[".container {\n  position: fixed;\n  bottom: 20px;\n  right: 20px;\n  z-index: 1000;\n  cursor: pointer;\n  background-color: #32976b;\n  color: #fff;\n  border-radius: 50%;\n  border: none;\n  width: 35px;\n  height: 35px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: all 0.3s;\n\n  &:hover {\n    background-color: #2c7a5f;\n    transform: scale(1.1);\n  }\n\n  &:active {\n    transform: scale(0.9);\n  }\n}\n\n.hidden {\n  transform: translateY(100px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `scrollToTop_container__z+HUi`,
	"hidden": `scrollToTop_hidden__ijiUb`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminTitleSeparator_admin_title_separator__bmXvD {
  padding: 30px 40px;
  background-color: #fafbfd;
  height: calc(100% - 73px);
  overflow-y: scroll;
}
.adminTitleSeparator_admin_title_separator__bmXvD .adminTitleSeparator_title__TcmZZ {
  font-weight: 700;
  font-size: 36px;
  line-height: 1;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  color: #32976b;
  margin-bottom: 5px;
}
@media (max-width: 1440px) {
  .adminTitleSeparator_admin_title_separator__bmXvD .adminTitleSeparator_title__TcmZZ {
    font-size: 34px;
  }
}
@media (max-width: 991px) {
  .adminTitleSeparator_admin_title_separator__bmXvD {
    padding: 30px;
  }
  .adminTitleSeparator_admin_title_separator__bmXvD .adminTitleSeparator_title__TcmZZ {
    font-size: 26px;
  }
}
@media (max-width: 800px) {
  .adminTitleSeparator_admin_title_separator__bmXvD {
    padding: 20px;
    height: calc(100% - 52px);
  }
  .adminTitleSeparator_admin_title_separator__bmXvD .adminTitleSeparator_title__TcmZZ {
    font-size: 22px;
  }
}

.adminTitleSeparator_fade_in__Rb787 {
  animation: adminTitleSeparator_fadeIn__HrhpO 0.3s ease-in forwards;
}

@keyframes adminTitleSeparator_fadeIn__HrhpO {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/AdminPanel/AdminTitleSeparator/adminTitleSeparator.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,yBAAA;EACA,yBAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,wBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;AACJ;AAEE;EACE;IACE,eAAA;EAAJ;AACF;AAGE;EAtBF;IAuBI,aAAA;EAAF;EAEE;IACE,eAAA;EAAJ;AACF;AAGE;EA9BF;IA+BI,aAAA;IACA,yBAAA;EAAF;EACE;IACE,eAAA;EACJ;AACF;;AAGA;EACE,kEAAA;AAAF;;AAGA;EACE;IACE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF","sourcesContent":[".admin_title_separator {\n  padding: 30px 40px;\n  background-color: #fafbfd;\n  height: calc(100% - 73px);\n  overflow-y: scroll;\n\n  .title {\n    font-weight: 700;\n    font-size: 36px;\n    line-height: 1;\n    letter-spacing: -0.015em;\n    text-transform: uppercase;\n    color: #32976b;\n    margin-bottom: 5px;\n  }\n\n  @media (max-width: 1440px) {\n    .title {\n      font-size: 34px;\n    }\n  }\n\n  @media (max-width: 991px) {\n    padding: 30px;\n\n    .title {\n      font-size: 26px;\n    }\n  }\n\n  @media (max-width: 800px) {\n    padding: 20px;\n    height: calc(100% - 52px);\n    .title {\n      font-size: 22px;\n    }\n  }\n}\n\n.fade_in {\n  animation: fadeIn 0.3s ease-in forwards;\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin_title_separator": `adminTitleSeparator_admin_title_separator__bmXvD`,
	"title": `adminTitleSeparator_title__TcmZZ`,
	"fade_in": `adminTitleSeparator_fade_in__Rb787`,
	"fadeIn": `adminTitleSeparator_fadeIn__HrhpO`
};
export default ___CSS_LOADER_EXPORT___;

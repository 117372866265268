import React, { FC, useState } from "react";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { IUserDataForm } from "./userDataForm.interface";
import {
  IContactUsDto,
  ILoginDto,
  IRegisterDto,
  IUserDto,
  userRoles,
  userRolesLocales,
} from "../../shared";
import { useAppSelector } from "../../hooks";
import styles from "./userDataForm.module.scss";
import { useSearchParams } from "react-router-dom";
import i18next from "i18next";

export const UserDataForm: FC<
  IUserDataForm<IRegisterDto | ILoginDto | IUserDto | IContactUsDto>
> = ({
  onSubmit,
  formData,
  selectDefaultValue,
  initialValues,
  buttonLabel,
  customStyles,
  children,
}) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const currentUser = useAppSelector(
    ({ userReducer }) => userReducer.currentUser
  );
  const isDisabledOption =
    currentUser?.role === userRoles.rehabilitator ||
    currentUser?.role === userRoles.doctor;

  const [selectRoleDefaultValue] = useState(
    userRolesLocales.find((item) => item.value === searchParams.get("role"))
      ?.value
  );

  const getLanguageString = (language: string) =>
    language.startsWith("uk") ? "ua" : language.split("-")[0];

  return (
    <Form
      initialValues={initialValues}
      onSubmit={(e, form) => onSubmit(e as any, form)}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit} className={customStyles}>
          {!!formData.length &&
            formData.map((item) => (
              <div key={item.name} className="main_wrap">
                {item.component === "select" && (
                  <div className={`${styles.select_wrap} select_wrap`}>
                    {!!item.label && <label>{t(item.label)}</label>}
                    <Field
                      name={item.name}
                      component={item.component}
                      defaultValue={
                        item.name === "role" && selectDefaultValue
                          ? selectDefaultValue
                          : item.name === "role" && selectRoleDefaultValue
                          ? selectRoleDefaultValue
                          : item.name === "language"
                          ? getLanguageString(i18next.resolvedLanguage || "")
                          : ""
                      }
                      validate={item.validate}
                      disabled={isDisabledOption}
                    >
                      {(item.options || []).map((option) => (
                        <option key={option.value} value={option.value}>
                          {t(option.title)}
                        </option>
                      ))}
                    </Field>
                    <Field
                      name={item.name}
                      subscription={{ error: true }}
                      render={({ meta }) =>
                        meta.error && (
                          <span className={styles.error_message}>
                            {meta.error}
                          </span>
                        )
                      }
                      className={styles.error_message}
                    />
                  </div>
                )}
                {item.component === "input" &&
                  (item.type === "text" || item.type === "password") && (
                    <>
                      <Field
                        key={item.name}
                        type={item.type}
                        name={item.name}
                        component={item.component}
                        validate={item.validate}
                        {...(item?.parse && { parse: item.parse })}
                      >
                        {({ input, meta }) => (
                          <>
                            <div className={`${styles.input_wrap} `}>
                              {!!item.label && <label>{t(item.label)}</label>}
                              <input
                                {...input}
                                type={item.type}
                                placeholder={t(item.placeholder || "")}
                                className={`${
                                  meta.error && meta.touched
                                    ? styles.form_input_error
                                    : ""
                                }`}
                              />
                              {meta.error && meta.touched && (
                                <span className={styles.error_message}>
                                  {t(meta.error)}
                                </span>
                              )}
                              {/* {!!(input.value as string)?.length &&
                              !isFieldDisabled && (
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    handleResetField(e, form, input);
                                  }}
                                >
                                  &times;
                                </button>
                              )} */}
                            </div>
                          </>
                        )}
                      </Field>
                    </>
                  )}
                {item.component === "input" && item.type === "tel" && (
                  <>
                    <Field
                      key={item.name}
                      type={item.type}
                      name={item.name}
                      component={item.component}
                      validate={item.validate}
                    >
                      {({ input, meta }) => (
                        <>
                          <div className={`${styles.input_wrap} `}>
                            {!!item.label && <label>{t(item.label)}</label>}
                            <input
                              {...input}
                              type={item.type}
                              placeholder={t(item.placeholder || "")}
                              className={`${
                                meta.error && meta.touched
                                  ? styles.form_input_error
                                  : ""
                              }`}
                              onFocus={(e) => {
                                if (e.target.value === "") {
                                  form.change(item.name, "+");
                                }
                              }}
                            />
                            {meta.error && meta.touched && (
                              <span className={styles.error_message}>
                                {t(meta.error)}
                              </span>
                            )}
                          </div>
                        </>
                      )}
                    </Field>
                  </>
                )}
                {!item.component && item.type === "file" && (
                  <Field type="file" name="image">
                    {({ input: { value, onChange, ...input }, meta }) => (
                      <div className={styles.input_file_wrap}>
                        <label>{t("adminPanel.form.photoLabel")}</label>
                        <input
                          {...input}
                          onChange={({ target }) => onChange(target.files)}
                          type="file"
                          id="image"
                          name="image"
                          accept="image/*"
                        />
                      </div>
                    )}
                  </Field>
                )}
              </div>
            ))}
          {children}
          <div className={styles.buttom_wrap}>
            <button
              type="submit"
              className={`${styles.form_button} ${styles.form_button_save} ${
                submitting && styles.disabled
              } submit_button`}
              disabled={submitting}
            >
              {buttonLabel}
            </button>
          </div>
        </form>
      )}
    />
  );
};

import {
  AnyAction,
  EmptyObject,
  EnhancedStore,
  configureStore,
} from "@reduxjs/toolkit";
import { ThunkMiddlewareFor } from "@reduxjs/toolkit/dist/getDefaultMiddleware";
import storage from "redux-persist/lib/storage";

import { rootReducer } from "./reducers";
import {
  userApi,
  authApi,
  placeApi,
  serviceApi,
  articleApi,
  reviewApi,
  visitApi,
  exerciseApi,
  dailyExerciseApi,
  medicalCardApi,
  stageApi,
  scheduleApi,
  filesApi,
  contactUsApi,
} from "./services";
import { persistReducer } from "redux-persist";
import moment from "moment";
import { paymentApi } from "./services/paymentApi";

export type SetupStoreType = EnhancedStore<
  EmptyObject,
  AnyAction,
  [ThunkMiddlewareFor<EmptyObject>]
>;

const persistConfig = {
  storage,
  key: moment().format("YYYY-MM-DD"),
  whitelist: ["dailyExercisesReducer"],
  keyPrefix: "jat-met-exercises:",
};

export const getStorageKey = () => {
  return persistConfig.key;
};

export const getClearStorage = () => {
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (
      key &&
      key.startsWith(`${persistConfig.keyPrefix}`) &&
      key !== `${persistConfig.keyPrefix}${persistConfig.key}`
    ) {
      localStorage.removeItem(key);
    }
  }
  return;
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = () =>
  configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        authApi.middleware,
        userApi.middleware,
        serviceApi.middleware,
        placeApi.middleware,
        articleApi.middleware,
        reviewApi.middleware,
        visitApi.middleware,
        exerciseApi.middleware,
        dailyExerciseApi.middleware,
        medicalCardApi.middleware,
        stageApi.middleware,
        scheduleApi.middleware,
        filesApi.middleware,
        contactUsApi.middleware,
        paymentApi.middleware
      ),
  });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

import React, { FC } from "react";
import { IConfirmDialogProps } from "./confirmDialog.interface";
import styles from "./confirmDialog.module.scss";
import { useTranslation } from "react-i18next";
import { Portal } from "../Portal/Portal";
import { Loader } from "../Loader";

export const ConfirmDialog: FC<IConfirmDialogProps> = ({
  isOpen,
  title,
  message,
  onConfirm,
  onCancel,
  isLoading,
  children,
}) => {
  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <div className={`${styles.confirm_wrap}`}>
        <div className={styles.confirm_dialog}>
          <h2 className={styles.title}>{title}</h2>
          {message && <p className={styles.message}>{message}</p>}
          {children}
          <div className={styles.button_wrap}>
            <button
              className={styles.confirm_button}
              onClick={onConfirm}
              disabled={isLoading}
            >
              {t("confirmModal.confirm")}
            </button>
            <button
              className={styles.cancel_button}
              onClick={onCancel}
              disabled={isLoading}
            >
              {t("confirmModal.cancel")}
            </button>
          </div>
          {isLoading && (
            <div className={styles.loader}>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
};

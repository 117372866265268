import React, { FC, useState } from "react";
import styles from "./adminPanelHeader.module.scss";
import { LanguageMenu } from "../../LanguageMenu";
import { IAdminPanelHeader } from "./adminPanelHeader.interface";
import { hamburger } from "@jat-med/assets";
import i18n from "i18next";
export const AdminPanelHeader: FC<IAdminPanelHeader> = ({
  setIsSidebarClose,
  isSidebarClose,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuItemClick = () => {
    setIsMenuOpen(false);
  };
  return (
    <div className={styles.admin_header_wrap}>
      <div className={styles.left_part}>
        <div
          className={`${styles.icon_menu} ${
            isSidebarClose && styles.rotate_icon
          }`}
          onClick={() => setIsSidebarClose(!isSidebarClose)}
        >
          <img src={hamburger} alt="menu_icon" />
        </div>
      </div>
      <div className={styles.right_part}>
        <LanguageMenu />
      </div>

      <div
        className={styles.language_dropdown}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <p>
          {i18n.resolvedLanguage === "uk"
            ? "UA"
            : i18n.resolvedLanguage?.toLocaleUpperCase()}
        </p>
        {isMenuOpen && <LanguageMenu onMenuItemClick={handleMenuItemClick} />}
      </div>
    </div>
  );
};

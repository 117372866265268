export const composeValidators =
  (...validators: any[]) =>
  (value: any) =>
    validators.reduce(
      (error, validator) => error || validator(value),
      undefined
    );

export const isEmail = (email: string) => {
  let re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return re.test(email) ? undefined : "formErrors.mustBeEmail";
};

export const isPhoneNumber = (value: string) => {
  const re = /^\+\d{1,3}\d{9,}$/;
  return re.test(value) ? undefined : "formErrors.mustBePhoneNumber";
};

export const isRequired = (value: any) =>
  value ? undefined : "formErrors.required";

export const isTooLargeFileSize = (value: any) => {
  return value && value[0]?.size < 1024 * 1024 * 10
    ? undefined
    : "formErrors.tooLarge";
};

export const isCorrectPesel = (value: string) => {
  const re = new RegExp(/^[0-9]{11}$/);
  return re.test(value) ? undefined : "formErrors.mustBePesel";
};

export const isCorrectPasswordLength = (value: string) => {
  const re = new RegExp(/^.{8,}$/);
  return re.test(value) ? undefined : "formErrors.passwordLength";
};

export const isCorrectVoucherCode = (value: string) => {
  const re = new RegExp(/^.{8}$/);
  return re.test(value) ? true : false;
};

import React, { FC } from "react";
import { IAdminTitleSeparator } from "./adminTitleSeparator.interface";
import styles from "./adminTitleSeparator.module.scss";

export const AdminTitleSeparator: FC<IAdminTitleSeparator> = ({
  title,
  children,
  custoStyle,
}) => {
  return (
    <div
      className={`${styles.admin_title_separator} ${styles.fade_in} ${custoStyle}`}
    >
      <h1 className={styles.title}>{title}</h1>
      {children}
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { Button } from "../../Buttons";
import { useState } from "react";
import { isCorrectVoucherCode, userRoles } from "../../../shared";
import { useAppSelector } from "../../../hooks";
import { voucherApi } from "../../../store/services";
import { searchIcon, gift } from "@jat-med/assets";
import styles from "./voucherPayment.module.scss";
import { useParams } from "react-router-dom";
import { VOUCHER_STATUSES } from "../../../pages/AdminPanel/Vouchers/VouchersTable/vouchers.interface";

interface VoucherPaymentProps {
  onHandleVoucherPayment: (voucherCode: string) => void;
  onValidateVoucher?: (voucherCode: string) => Promise<boolean>;
}

export default function VoucherPayment({
  onHandleVoucherPayment,
  onValidateVoucher,
}: VoucherPaymentProps) {
  const currentUser = useAppSelector(
    ({ userReducer }) => userReducer.currentUser
  );
  const { visitId: queryVisitId } = useParams();
  const { t } = useTranslation();
  const [voucherCode, setVoucherCode] = useState("");
  const [isValidVoucherCode, setIsValidVoucherCode] = useState(false);
  const [isVoucherPaymentActive, setIsVoucherPaymentActive] = useState(false);

  const isPatient = currentUser?.role === userRoles.patient;

  const { data: patientVouchers } = voucherApi.useFetchVoucherListByUserQuery(
    {},
    {
      skip: !isPatient,
      refetchOnMountOrArgChange: true,
    }
  );

  return (
    <div className={styles.voucher_payment_wrapper}>
      <button
        className={styles.payment_button}
        onClick={() => setIsVoucherPaymentActive(true)}
      >
        <img src={gift} alt={t("buttons.paymentMethodVoucher")} />
        {t("buttons.paymentMethodVoucher")}
      </button>
      {isVoucherPaymentActive && (
        <>
          <div className={styles.search_wrapper}>
            <input
              type="text"
              placeholder={t("voucherCode")}
              maxLength={8}
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
            />
            <button
              type="button"
              disabled={!isCorrectVoucherCode(voucherCode)}
              onClick={async () => {
                const result =
                  onValidateVoucher && (await onValidateVoucher(voucherCode));

                if (result) {
                  setIsValidVoucherCode(true);
                }
              }}
            >
              <img src={searchIcon} alt="Search Icon" />
            </button>
          </div>
          <Button
            disabled={!isValidVoucherCode || !isCorrectVoucherCode(voucherCode)}
            onClick={() => {
              onHandleVoucherPayment(voucherCode);
              setIsValidVoucherCode(false);
              setVoucherCode("");
              setIsVoucherPaymentActive(false);
            }}
            className="green_outlined"
            customStyle={styles.voucher_payment_button}
            label={t("buttons.pay")}
          />
        </>
      )}
      {isPatient && patientVouchers?.items.length && (
        <div className={styles.voucher_list}>
          <h3>{t("adminMenu.certificates")}</h3>
          <ul>
            {patientVouchers.items
              .filter((voucher) => voucher.status === VOUCHER_STATUSES.active)
              .map((voucher) => (
                <li key={voucher._id}>
                  <span>{voucher.key}</span>
                  {isVoucherPaymentActive && (
                    <button
                      onClick={async () => {
                        const result =
                          onValidateVoucher &&
                          (await onValidateVoucher(voucher.key));
                        if (result) {
                          setIsValidVoucherCode(true);
                          setVoucherCode(voucher.key);
                        }
                      }}
                    >
                      {t("buttons.use")}
                    </button>
                  )}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}

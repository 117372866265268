import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
export const OnlineConsultations = () => {
  const { t } = useTranslation();
  return (
    <article className={styles.policy}>
      <h1>{t("OnlineConsultations.title")}</h1>
      <p>{t("OnlineConsultations.paragraph1")}</p>

      {i18next.resolvedLanguage === "pl" && (
        <ol>
          <li>Postanowienia ogólne</li>
          <ul>
            <li>
              Regulamin dotyczy zasad korzystania z usługi świadczonej drogą
              elektroniczną „Konsultacja On Line” (dalej „Usługa”), podmiotu
              świadczącego usługę, wynagrodzenia, postępowania reklamacyjnego i
              przetwarzania danych osobowych osoby korzystającej z Usługi.
            </li>
            <li>
              Na życzenie Użytkownika lub osoby przystępującej do korzystania z
              Usługi Regulamin może być przesłany droga elektroniczną na
              wskazany przez zainteresowanego adres mailowy.
            </li>
          </ul>
          <li>Definicje</li>
          <ul>
            <li>
              <b>Administrator</b> - Jat-med sp. z o.o. z siedzibą w Bydgoszczy
              przy ulicy Kruszyńska 17, wpisanej do rejestru przedsiębiorców
              Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy W
              BYDGOSZCZY, XIII WYDZIAŁ GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO,
              pod numerem KRS 0001150821, NIP 9671483929, REGON 540725908.
            </li>
            <li>
              <b>Serwis</b> - serwis internetowy prowadzony przez Administratora
              pod adresem jat-med.com, dokonuje sprzedaży Produktów, udostępnia
              Pliki zawierające materiały do leczenia i profilaktyki schorzeń
              układu ruchu, a także świadczy inne usługi określone w
              Regulaminie, w tym możliwość zawarcia Umowy na Konsultacje On-line{" "}
              <Link
                to="https://jat-med.com/services/6666bd1fecaf02e9172bbef9"
                target="_blank"
                aria-label="Jat Med Online Consultation"
              >
                jat-med.com
              </Link>{" "}
            </li>
            <li>
              <b>Użytkownik</b> - osoba fizyczna osoba prawna oraz jednostka
              organizacyjna nieposiadająca osobowości prawnej, odwiedzająca
              Serwis lub korzystająca z usług Serwisu.{" "}
            </li>
            <li>
              <b>Pacjent</b> - osoba fizyczna korzystający bezpośrednio z
              Konsultacji On-line (w tym użytkownik).{" "}
            </li>
            <li>
              <b>Specjalista</b> - lekarz, pielęgniarka, fizjoterapeuta lub inną
              osoba niewymienioną powyżej wykonującą czynności w zakresie
              świadczeń zdrowotnych, świadczącą usługi zdrowotne, medyczne lub
              paramedyczne.{" "}
            </li>
            <li>
              <b>Konsultacja on-line, zwana też Usługą</b> - usługa świadczona
              przez Specjalistę za pośrednictwem Serwisu (zapewnienie
              konsultacji ze Specjalistą) drogą elektroniczną na zasadach
              określonych w Regulaminie.{" "}
            </li>
            <li>
              <b>Usługa</b> - umowa o świadczenie usług w postaci Konsultacji
              On-line zawierana albo zawarta między Użytkownikiem a Specjalistą
              za pośrednictwem Serwisu.{" "}
            </li>
            <li>
              <b>Regulamin</b> - Regulamin usługi świadczonej drogą
              elektroniczną „Konsultacja On-Line”
            </li>
          </ul>

          <li>
            Zasady korzystania z Konsultacji On-line
            <ul>
              <li>
                Przystąpienie do korzystania z Konsultacji On-Line, warunkowane
                jest akceptacją niniejszego Regulaminu przez Pacjenta, oraz
                akceptacją możliwości świadczenia usługi Konsultacji On-Line
                przez Specjalistę.
              </li>
              <li>
                Rodzaje Konsultacji On-Line:
                <ol>
                  <li>konsultacja on-line;</li>
                  <li>porada albo;</li>
                  <li>informacja zdrowotna.</li>
                </ol>
              </li>
              <li>
                Formy komunikacji pomiędzy Pacjentem a Specjalistą:
                <ol>
                  <li>
                    czat – wymiany krótkich wiadomości tekstowych między
                    Pacjentem a Specjalistą,
                  </li>
                  <li>
                    telekonferencja – przekaz głosowy, pomiędzy Pacjentem a
                    Specjalistą,
                  </li>
                  <li>
                    wideokonferencja – przekaz audiowizualny, pomiędzy Pacjentem
                    a Specjalistą,
                  </li>
                  <li>wymiana wiadomości email.</li>
                </ol>
              </li>
              <li>
                Wybór poszczególnego rodzaju Konsultacji On-Line oraz formy
                komunikacji uzależniony jest od indywidualnych ustaleń pomiędzy
                Pacjentem a Specjalistą. Podczas pierwszej konsultacji.
                Specjalista, może samodzielnie zdecydować o formie komunikacji,
                mając na względzie najwyższe dobro Pacjenta, uwarunkowania
                techniczne oraz inne okoliczności mogące mieć wpływ na przebieg
                i jakość konsultacji.
              </li>
              <li>
                Konsultacja on-line jest świadczeniem zdrowotnym, którego
                udzielenie może nastąpić pod warunkiem podania przez Pacjenta
                najpóźniej do terminu umówionej Konsultacji On-Line,
                następujących danych potrzebnych do udzielenia świadczenia i
                prowadzenia w związku z tym dokumentacji medycznej:
                <ol>
                  <li>imię (imiona) i nazwisko (nazwiska),</li>
                  <li>data urodzenia,</li>
                  <li>płeć,</li>
                  <li>obywatelstwo,</li>
                  <li>adres miejsca zamieszkania,</li>
                  <li>
                    w przypadku osoby małoletniej również dane opiekuna
                    prawnego,
                  </li>
                  <li>
                    numer PESEL, jeżeli został nadany lub inny numer ewidencyjny
                    (w przypadku braku numeru PESEL),
                  </li>
                  <li>
                    dane i dokumenty dotyczące stanu zdrowia Pacjenta
                    udzielonych mu dotychczas świadczeniach, niezbędne do
                    realizacji Świadczenia Konsultacji On-Line,
                  </li>
                  <li>
                    adres e-mail i numer telefonu kontaktowego (z wyłączeniem
                    numeru telefonu stacjonarnego).
                  </li>
                </ol>
              </li>
              <li>
                Dane, o których mowa w punkcie powyżej, Pacjent może wysłać na
                adres e-mail Administratora lub przekazać osobiście w czasie
                zbierania przez Specjalistę wywiadu osobowego.
              </li>
              <li>
                Świadczenie Konsultacji On-Line udzielane przez Specjalistę nie
                obejmuje świadczeń zdrowotnych wymagających osobistego,
                bezpośredniego kontaktu ze specjalistą medycznym.
              </li>
              <li>
                Konsultacje On-line mają ograniczony charakter i mogą nie
                zawierać wszystkich elementów badania. Specjalista konsultujący
                może skierować pacjenta na wizytę stacjonarną, jeżeli takie
                postępowanie uzna za zasadne i konieczne.
              </li>
              <li>
                W uzasadnionych medycznie przypadkach, Specjalista może prosić
                Pacjenta o skierowanie lekarskie na określony zakres
                fizjoterapii.
              </li>
              <li>
                Specjalista prowadzi dokumentację medyczną zgodnie z wytycznymi
                Krajowej Izby Fizjoterapeutów lub innych organów samorządu
                zawodowego dla właściwego zawodu medycznego.
              </li>
              <li>
                Konsultacja On-line może być poprzedzona przeprowadzeniem
                ankiety dotyczącej m.in. ogólnego stanu zdrowia Pacjenta,
                dotychczasowego leczenia, wyników badań, przyjmowanych leków czy
                predyspozycji genetycznych.
              </li>
              <li>
                Specjalista przeprowadza badanie Pacjenta, które ma na celu
                ocenę stanu jego zdrowia oraz dobranie właściwych metod
                leczenia.
              </li>
            </ul>
          </li>
          <li>
            Warunki świadczenia Konsultacji On-line
            <ul>
              <li>Usługi Konsultacji On-Line są świadczone odpłatnie.</li>
              <li>
                Wysokość opłat określa cennik Usług On-Line dostępny na stronie
                Serwisu pod adresem{" "}
                <Link
                  target="_blank"
                  aria-label="Jat Med Website"
                  to={"https://jat-med.com/appointment"}
                >
                  jat-med.com/appointment
                </Link>{" "}
                Usługi medyczne zwolnione są z podatku VAT.
              </li>
              <li>
                Zamówienia Usługi oraz opłaty za nią można dokonać za
                pośrednictwem systemu udostępnionego na stronie Serwisu zgodnie
                z zasadami określonymi w regulaminie Serwisu dostępnego pod
                adresem{" "}
                <Link
                  target="_blank"
                  aria-label="Jat Med regulamin serwisu"
                  to={"https://jat-med.com/policy/website-terms-of-use"}
                >
                  jat-med.com/policy/website-terms-of-use
                </Link>
              </li>
              <li>Opłaty należy dokonać przed odbyciem konsultacji.</li>
              <li>
                Realizacja Usługi możliwa jest tylko po uprzedniej rezerwacji
                terminu wizyty zgodnie z poniższą procedurą:
                <ol>
                  <li>
                    rezerwacja terminu wizyty możliwa jest: telefonicznie, w tym
                    celu należy skontaktować się z Administratorem, dzwoniąc na
                    numer{" "}
                    <a href={`tel:+48518468678`} aria-label="Jat Med Phone">
                      +48518468678
                    </a>
                    ; mailowo, w tym celu należy skontaktować się z
                    Administratorem pisząc na adres: jatmedcom2018@gmail.com lub
                    przez wyszczególnione na stronie internetowej{" "}
                    <Link
                      target="_blank"
                      aria-label="Jat Med Website"
                      to={"https://jat-med.com"}
                    >
                      https://jat-med.com
                    </Link>{" "}
                    formy komunikacji.
                  </li>
                  <li>
                    zmiana terminu wizyty, jest możliwa nieodpłatnie do godziny
                    18 dnia poprzedzającego dzień wizyty, np. wizytę na godzinę
                    12 w dniu 28 lipca 2024 roku, można odwołać nieodpłatnie do
                    godziny 18 dnia 27 lipca 2024 roku;
                  </li>
                  <li>
                    niezachowanie granicznego terminu odwołania wizyty,
                    określonego w pkt. b powyżej, skutkuje koniecznością opłaty
                    50% ceny usługi z tytułu gotowości Specjalisty do
                    świadczenia Usługi;
                  </li>
                  <li>
                    niepoinformowanie o fakcie odwołania lub chęci przełożenia
                    Konsultacji, skutkuje koniecznością pokrycia 100% opłaty za
                    zarezerwowaną Konsultację On-Line;
                  </li>
                  <li>
                    wszelkie zmiany w terminach wizyt należy zgłaszać
                    telefonicznie lub mailowo, na opublikowane na stronie{" "}
                    <Link
                      target="_blank"
                      aria-label="Jat Med Website"
                      to={"https://jat-med.com"}
                    >
                      https://jat-med.com
                    </Link>
                    , dane kontaktowe.
                  </li>
                </ol>
              </li>
              <li>
                O wyznaczonej godzinie Pacjent musi być osiągalny za
                pośrednictwem ustalonego z Administratorem lub Specjalistą
                środka komunikacji.
              </li>
            </ul>
          </li>
          <li>
            Wymagania techniczne dla korzystania z Konsultacji On-line
            <ul>
              <li>
                Konsultacje On-Line świadczone są na odległość, za pośrednictwem
                następujących kanałów komunikacji: telefon, czat, wideo.
              </li>
              <li>
                Komunikacja może odbywać się na wszystkich urządzeniach
                elektronicznych z dostępem do Internetu, które posiadają sprawny
                komunikator dający w zależności od charakteru konsultacji
                możliwość połączenia w formie czatu, wideokonferencji lub
                telefonicznie, a także umożlwiający odtwarzanie i przesyłanie
                plików tekstowych, zdjęć, plików wideo.
              </li>
              <li>
                Wymagania techniczne. W zależności od przyjętej formy
                komunikacji:
                <ol>
                  <li>konsultacja telefoniczna: sprawny telefon;</li>
                  <li>czat: komunikator z dostępem do Internetu;</li>
                  <li>
                    wideokonferencja: komunikator z dostępem do Internetu,
                    umożliwiający połączenia audio-wideo, sprawny mikrofon,
                    sprawne głośniki, sprawna kamera internetowa;
                  </li>
                  <li>
                    preferowane komunikatory: Skype, Zoom, Whatsapp, Facebook
                    Messneger. Google Hangouts.
                  </li>
                </ol>
              </li>
              <li>
                Formę komunikacji ustala Specjalista podczas pierwszego kontaktu
                konsultacyjnego.
              </li>
              <li>
                Specjalista może odmówić udzielenia Konsultacji On-Line, gdy
                uzna, że uwarunkowania techniczne lub inne okoliczności losowe
                uniemożliwiają sprawne przeprowadzenie konsultacji.
              </li>
              <li>
                Administrator nie ponosi odpowiedzialności za mankamenty
                techniczne, leżące po stronie pacjenta i uniemożliwiające
                sprawne odbycie Konsultacji On-Line.
              </li>
              <li>
                Przerwanie przez Pacjenta połączenia z przyczyn technicznych lub
                innych okoliczności losowych nie leżących po stronie
                Administratora, nie zwalnia Pacjenta z dokonania opłaty za
                Konsultację On-Line.
              </li>
              <li>
                Wizytę przerwaną przez Specjalistę z przyczyn technicznych lub
                okoliczności losowych, leżących po stronie Administratora lub
                Specjalisty, uznaje się za nieodbytą. Pacjentowi przysługuje
                prawo do przełożenia nieodbytej wizyty na inny termin lub zwrot
                wpłaconej za Konsultację należności.
              </li>
            </ul>
          </li>
          <li>
            Zasady odpowiedzialności
            <ul>
              <li>
                Administrator/Specjalista i Użytkownik/Pacjent zobowiązani są do
                naprawienia szkody, jaką druga strona Umowy poniosła na skutek
                niewykonania lub nienależytego wykonania przez nich obowiązków
                wynikających z Umowy lub Regulaminu, chyba że ich niewykonanie
                lub nienależyte wykonanie było następstwem okoliczności, za
                które strona ta nie ponosi odpowiedzialności.
              </li>
              <li>
                Administrator/Specjalista nie ponosi odpowiedzialności za
                podanie przez Użytkownika/Pacjenta niekompletnych,
                nieprawdziwych lub nieprawidłowych informacji, zwłaszcza w
                przypadku podania danych osób trzecich bez ich wiedzy lub zgody.
                Za skutki podania błędnych, niekompletnych, nieprawdziwych,
                wprowadzających w błąd lub w inny sposób nieprawidłowych danych
                wyłączna odpowiedzialność spoczywa na Użytkowniku/Pacjencie.
              </li>
              <li>
                Administrator/Specjalista nie ponosi odpowiedzialności za skutki
                korzystania z Usług przez Użytkownika/Pacjenta w sposób
                sprzeczny z Regulaminem.
              </li>
              <li>
                Administrator/Specjalista nie ponosi odpowiedzialności za
                szkody:
                <ol>
                  <li>
                    wyrządzone podmiotom trzecim, powstałe w wyniku korzystania
                    przez Użytkowników/Pacjentów z usług w sposób sprzeczny z
                    Regulaminem lub przepisami prawa,
                  </li>
                  <li>
                    powstałe na skutek braku ciągłości dostarczania Usług,
                    będące następstwem okoliczności, za które
                    Administrator/Specjalista nie ponosi odpowiedzialności, np.
                    wskutek działania siły wyższej, działania i zaniechania osób
                    trzecich, z wyjątkiem osób, za które
                    Administrator/Specjalista ponosi odpowiedzialność na mocy
                    przepisów prawa.
                  </li>
                  <li>
                    powstałe w wyniku podanie przez Użytkownika/Pacjenta
                    nieprawdziwych lub niepełnych informacji przy rejestracji.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Odstąpienie od umowy na Konsultację On-line
            <ul>
              <li>
                Użytkownikowi będącemu konsumentem przysługuje prawo do
                odstąpienia od umowy, w terminie 14 dni od dnia jej zawarcia,
                bez podania przyczyny zgodnie z zasadami określonymi w
                regulaminie Serwisu dostępnego pod adresem{" "}
                <Link
                  target="_blank"
                  aria-label="Jat Med Website"
                  to={"https://jat-med.com"}
                >
                  https://jat-med.com
                </Link>{" "}
              </li>
              <li>
                Ponadto każda ze stron może wypowiedzieć Umowę ze skutkiem
                natychmiastowym w przypadku istotnego naruszenia przez drugą
                stronę warunków Umowy, przepisów prawa lub praw drugiej strony
                lub osoby trzeciej.
              </li>
            </ul>
          </li>
          <li>
            Reklamacje
            <ul>
              <li>
                Pacjentowi przysługuje prawo zgłaszania zapytań, uwag i
                reklamacji w związku z wykonywaniem Konsultacji On-Line.
                Zapytania, uwagi i reklamacje dotyczące działania Serwisu i
                związanych z nim kwestii natury technicznej należy zgłaszać na
                adres{" "}
                <a
                  href={`mailto:e-mail: jatmedcom2018@gmail.com`}
                  aria-label="Jat Med Email"
                >
                  {" "}
                  e-mail: jatmedcom2018@gmail.com
                </a>{" "}
              </li>
              <li>
                Termin rozpatrzenia reklamacji wynosi 14 dni roboczych, chyba że
                odpowiedni Regulamin Organizacyjny przewiduje krótszy termin.
              </li>
              <li>
                Treść reklamacji powinna zawierać, co najmniej:
                <ol>
                  <li>
                    dane pozwalające na identyfikację Pacjenta: login
                    Użytkownika, imię i nazwisko Pacjenta, adres e-mail, adres
                    pocztowy (dla reklamacji składanych listownie);
                  </li>
                  <li>określenie przedmiotu reklamacji,</li>
                  <li>określenie ewentualnych żądań Pacjenta,</li>
                  <li>
                    wskazanie daty zaistnienia zdarzenia świadczącego o
                    nienależytym wykonaniu Usługi Konsultacji On-Line.
                  </li>
                </ol>
              </li>
              <li>
                Postępowanie reklamacyjne nie wyłącza innych uprawnień
                przysługujących Użytkownikowi na mocy przepisów prawa.
              </li>
            </ul>
          </li>
          <li>
            Przetwarzanie danych osobowych
            <ul>
              <li>
                Poprzez zlecenie Konsultacji On-line Użytkownik wyraża zgodę na
                przetwarzanie jego danych osobowych na potrzeby wykonania Usługi
                oraz rozliczeń z tym związanych, jak również na potrzeby
                postępowania reklamacyjnego. Szczegółowe zasady oraz cele
                przetwarzania danych osobowych gromadzonych podczas korzystania
                przez Użytkownika z Serwisu zostały określone w Klauzuli
                informacyjna w związku z lekarską „konsultacją on-line"
                (świadczenie zdrowotne) dostępnej pod adresem{" "}
                <Link
                  target="_blank"
                  aria-label="Jat Med Website"
                  to={"https://jat-med.com/policy/personal-data-processing"}
                >
                  https://jat-med.com/policy/personal-data-processing
                </Link>{" "}
              </li>
            </ul>
          </li>
          <li>
            Postanowienia końcowe
            <ul>
              <li>
                Regulamin jest dostępny na stronie Serwisu oraz w siedzibie
                Administratora. Regulamin – na żądanie Użytkownika przesłane
                pocztą elektroniczną na adres{" "}
                <a
                  href={`mailto:e-mail: jatmedcom2018@gmail.com`}
                  aria-label="Jat Med Email"
                >
                  {" "}
                  e-mail: jatmedcom2018@gmail.com
                </a>{" "}
                – zostanie przekazany Użytkownikowi nieodpłatnie w wersji
                elektronicznej na podany przez Użytkownika adres e-mail.
              </li>
              <li>
                Administrator zastrzega sobie prawo do dokonywania zmian
                Regulaminu z ważnych przyczyn to jest: zmiany przepisów prawa,
                zmiany sposobów płatności i dostaw – w zakresie, w jakim te
                zmiany wpływają na realizację postanowień niniejszego
                Regulaminu.
              </li>
              <li>
                O każdej zmianie Administratora poinformuje Użytkownika z co
                najmniej 14 dniowym wyprzedzeniem. W terminie 14 dni od dnia
                otrzymania informacji o zmianie Użytkownik może odmówić jej
                akceptacji – e-mailem przesłanym na adres
                jatmedcom2018@gmail.com. W takim przypadku Konto Użytkownika
                zostanie zlikwidowane w dniu wejścia w życie zmiany.
              </li>
              <li>
                Zamówienia dokonane w Serwisie, które nastąpiły przed dniem
                wejścia w życie zmian podlegają zasadom dotychczasowym.
              </li>
              <li>
                W sprawach nieuregulowanych w Regulaminie mają zastosowanie
                postanowienia regulaminu Serwisu dostępnego pod adresem{" "}
                <Link
                  target="_blank"
                  aria-label="Jat Med Website"
                  to={"https://jat-med.com/policy/website-terms-of-use"}
                >
                  https://jat-med.com/policy/website-terms-of-use
                </Link>{" "}
                , a także powszechnie obowiązujące przepisy prawa polskiego, w
                szczególności: Kodeksu cywilnego; ustawy o świadczeniu usług
                drogą elektroniczną; ustawy o prawach konsumenta, ustawy o
                ochronie danych osobowych, ustawy i prawach pacjenta i rzeczniku
                praw pacjenta, ustawy o działalności leczniczej oraz inne
                właściwe przepisy prawa.
              </li>
            </ul>
          </li>
        </ol>
      )}
      {i18next.resolvedLanguage === "en" && (
        <ol>
          <li>
            General Provisions
            <ul>
              <li>
                The Terms and Conditions apply to the principles of using the
                electronically provided service "On-Line Consultation"
                (hereinafter referred to as the "Service"), the entity providing
                the service, remuneration, complaint procedure and processing of
                personal data of the person using the Service.
              </li>
              <li>
                At the request of the User or the person starting to use the
                Service, the Terms and Conditions may be sent electronically to
                the e-mail address indicated by the interested party.
              </li>
            </ul>
          </li>
          <li>
            Definitions
            <ul>
              <li>
                <b>Administrator</b> – Jat-med sp. z o.o. with its registered
                office in Bydgoszcz at 17 Kruszyńska Street, entered into the
                register of entrepreneurs of the National Court Register
                maintained by the District Court in BYDGOSZCZ, XIII COMMERCIAL
                DIVISION OF THE NATIONAL COURT REGISTER, under KRS number
                0001150821, NIP 9671483929, REGON 540725908.
              </li>
              <li>
                <b>Service</b> – an internet service run by the Administrator at{" "}
                <a href="https://jat-med.com">jat-med.com</a>, sells Products,
                provides Files containing materials for the treatment and
                prevention of musculoskeletal disorders, and also provides other
                services specified in the Regulations, including the possibility
                of concluding an Agreement for Online Consultations{" "}
                <a href="https://jat-med.com">jat-med.com</a>
              </li>
              <li>
                <b>User</b> – a natural person, legal person and an
                organizational unit without legal personality, visiting the
                Service or using the services of the Service.
              </li>
              <li>
                <b>Patient</b> – a natural person directly using the Online
                Consultation (including the user).
              </li>
              <li>
                <b>Specialist</b> – a doctor, nurse, physiotherapist or other
                person not listed above performing activities in the field of
                health services, providing health, medical or paramedical
                services.
              </li>
              <li>
                <b>Online Consultation, also referred to as the Service</b> – a
                service provided by the Specialist via the Service (providing
                consultation with the Specialist) electronically on the
                principles specified in the Regulations.
              </li>
              <li>
                <b>Online Consultation Agreement</b> – an agreement for the
                provision of services in the form of Online Consultation
                concluded or entered into between the User and the Specialist
                via the Service.
              </li>
              <li>
                <b>Regulations</b> – Regulations of the service provided
                electronically “Online Consultation”
              </li>
            </ul>
          </li>
          <li>
            Rules for using the Online Consultation
            <ul>
              <li>
                Starting to use the Online Consultation is conditioned by the
                acceptance of these Regulations by the Patient, and the
                acceptance of the possibility of providing the Online
                Consultation service by the Specialist.
              </li>
              <li>
                Types of Online Consultations:
                <ol>
                  <li>online consultation;</li>
                  <li>advice or;</li>
                  <li>health information.</li>
                </ol>
              </li>
              <li>
                Forms of communication between the Patient and the Specialist:
                <ol>
                  <li>
                    chat – exchange of short text messages between the Patient
                    and the Specialist,
                  </li>
                  <li>
                    teleconference – voice transmission, between the Patient and
                    the Specialist,
                  </li>
                  <li>
                    videoconference – audiovisual transmission, between the
                    Patient and the Specialist,
                  </li>
                  <li>exchange of email messages.</li>
                </ol>
              </li>
              <li>
                The choice of a particular type of Online Consultation and the
                form of communication depends on individual arrangements between
                the Patient and the Specialist. During the first consultation,
                the Specialist may independently decide on the form of
                communication, taking into account the highest good of the
                Patient, technical conditions and other circumstances that may
                affect the course and quality of the consultation.
              </li>
              <li>
                Online Consultation is a health service that may be provided on
                condition that the Patient provides, no later than the date of
                the scheduled Online Consultation, the following data required
                to provide the service and maintain medical documentation in
                connection therewith:
                <ol>
                  <li>first name(s) and last name(s),</li>
                  <li>date of birth,</li>
                  <li>gender,</li>
                  <li>citizenship,</li>
                  <li>address of residence,</li>
                  <li>
                    in the case of a minor, also the data of the legal guardian,
                  </li>
                  <li>
                    PESEL number, if assigned, or another registration number
                    (in the absence of a PESEL number),
                  </li>
                  <li>
                    data and documents concerning the Patient's health condition
                    in the services provided to him/her so far, necessary to
                    provide the Online Consultation Service,
                  </li>
                  <li>
                    e-mail address and contact telephone number (excluding
                    landline telephone number).
                  </li>
                </ol>
              </li>
              <li>
                The Patient may send the data referred to in the point above to
                the Administrator's e-mail address or provide it in person when
                the Specialist collects personal information.
              </li>
              <li>
                The provision of Online Consultations provided by the Specialist
                does not include health services requiring personal, direct
                contact with a medical specialist.
              </li>
              <li>
                Online consultations are limited in nature and may not include
                all elements of the examination. The consulting specialist may
                refer the patient for a stationary visit if such action is
                deemed justified and necessary.
              </li>
              <li>
                In medically justified cases, the Specialist may ask the Patient
                for a medical referral for a specific scope of physiotherapy.
              </li>
              <li>
                The Specialist maintains medical records in accordance with the
                guidelines of the National Chamber of Physiotherapists or other
                professional self-government bodies for the relevant medical
                profession.
              </li>
              <li>
                Online consultation may be preceded by a survey concerning,
                among others, the Patient's general health condition, previous
                treatment, test results, medications taken or genetic
                predispositions.
              </li>
              <li>
                The specialist conducts an examination of the Patient, which
                aims to assess the Patient's health condition and select
                appropriate treatment methods.
              </li>
            </ul>
          </li>
          <li>
            Terms of provision of Online Consultation
            <ul>
              <li>Online Consultation services are provided for a fee.</li>
              <li>
                The amount of fees is specified in the Online Services price
                list available on the Service website at{" "}
                <a href="https://jat-med.com/appointment">
                  jat-med.com/appointment
                </a>
                . Medical services are exempt from VAT.
              </li>
              <li>
                Orders for the Service and payment for it can be made via the
                system made available on the Service website in accordance with
                the principles specified in the Service regulations available at{" "}
                <a href="https://jat-med.com/policy/website-terms-of-use">
                  jat-med.com/policy/website-terms-of-use
                </a>
              </li>
              <li>Payment must be made before the consultation.</li>
              <li>
                The Service can only be provided after prior booking of the
                appointment in accordance with the following procedure:
                <ol>
                  <li>
                    booking of the appointment is possible: by phone, to do
                    this, contact the Administrator by calling{" "}
                    <a href="tel:+48518468678">+48518468678</a>; by e-mail, to
                    do this, contact the Administrator by writing to the
                    address:{" "}
                    <a href="mailto:jatmedcom2018@gmail.com">
                      jatmedcom2018@gmail.com
                    </a>{" "}
                    or through the forms of communication specified on the
                    website <a href="https://jat-med.com">jat-med.com</a>.
                  </li>
                  <li>
                    changing the appointment date is possible free of charge
                    until 6 p.m. on the day preceding the day of the
                    appointment, e.g. an appointment for 12 p.m. on July 28,
                    2024, can be canceled free of charge until 6 p.m. on July
                    27, 2024;
                  </li>
                  <li>
                    failure to meet the deadline for canceling the appointment,
                    specified in point b above, results in the necessity to pay
                    50% of the price of the service due to the Specialist's
                    readiness to provide the Service;
                  </li>
                  <li>
                    failure to inform about the fact of cancellation or the
                    desire to postpone the Consultation will result in the
                    necessity to cover 100% of the fee for the booked Online
                    Consultation;
                  </li>
                  <li>
                    any changes in the dates of visits should be reported by
                    phone or email, to the contact details published on the
                    website <a href="https://jat-med.com">jat-med.com</a>.
                  </li>
                </ol>
              </li>
              <li>
                At the appointed time, the Patient must be available via a means
                of communication agreed with the Administrator or Specialist.
              </li>
            </ul>
          </li>
          <li>
            Technical requirements for using Online Consultations
            <ul>
              <li>
                Online Consultations are provided remotely, via the following
                communication channels: telephone, chat, video.
              </li>
              <li>
                Communication can take place on all electronic devices with
                Internet access, which have a working communicator that allows,
                depending on the nature of the consultation, the possibility of
                connecting in the form of a chat, video conference or by phone,
                as well as enabling the playback and transmission of text files,
                photos, video files.
              </li>
              <li>
                Technical requirements. Depending on the form of communication
                adopted:
                <ol>
                  <li>telephone consultation: working telephone;</li>
                  <li>chat: communicator with Internet access;</li>
                  <li>
                    video conference: communicator with Internet access,
                    enabling audio-video connections, working microphone,
                    working speakers, working webcam;
                  </li>
                  <li>
                    preferred communicators: Skype, Zoom, Whatsapp, Facebook
                    Messenger, Google Hangouts.
                  </li>
                </ol>
              </li>
              <li>
                The form of communication is determined by the Specialist during
                the first consultation contact.
              </li>
              <li>
                The Specialist may refuse to provide an Online Consultation if
                he considers that technical conditions or other random
                circumstances prevent the consultation from being carried out
                efficiently.
              </li>
              <li>
                The Administrator is not responsible for technical shortcomings
                that are the patient's fault and prevent the Online Consultation
                from being carried out efficiently.
              </li>
              <li>
                The Patient's interruption of the connection due to technical
                reasons or other random circumstances not attributable to the
                Administrator does not exempt the Patient from paying the fee
                for the Online Consultation.
              </li>
              <li>
                A visit interrupted by the Specialist due to technical reasons
                or unforeseen circumstances attributable to the Administrator or
                the Specialist is considered not to have taken place. The
                patient has the right to reschedule the missed visit to another
                date or to receive a refund of the fee paid for the
                Consultation.
              </li>
            </ul>
          </li>
          <li>
            Principles of liability
            <ul>
              <li>
                The Administrator/Specialist and the User/Patient are obliged to
                repair the damage that the other party to the Agreement has
                suffered as a result of their failure to perform or improper
                performance of their obligations under the Agreement or the
                Regulations, unless their failure to perform or improper
                performance was a consequence of circumstances for which that
                party is not responsible.
              </li>
              <li>
                The Administrator/Specialist is not responsible for the
                User/Patient providing incomplete, false or incorrect
                information, especially in the case of providing data of third
                parties without their knowledge or consent. The User/Patient is
                solely responsible for the consequences of providing incorrect,
                incomplete, false, misleading or otherwise incorrect data.
              </li>
              <li>
                The Administrator/Specialist is not responsible for the
                consequences of the User/Patient using the Services in a manner
                inconsistent with the Regulations.
              </li>
              <li>
                The Administrator/Specialist shall not be liable for damages:
                <ol>
                  <li>
                    caused to third parties, resulting from the use of services
                    by Users/Patients in a manner inconsistent with the
                    Regulations or legal regulations,
                  </li>
                  <li>
                    resulting from the lack of continuity in the provision of
                    Services, resulting from circumstances for which the
                    Administrator/Specialist is not responsible, e.g. as a
                    result of force majeure, actions and omissions of third
                    parties, except for persons for whom the
                    Administrator/Specialist is responsible under the provisions
                    of the law.
                  </li>
                  <li>
                    resulting from the provision by the User/Patient of false or
                    incomplete information during registration.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Withdrawal from the contract for an Online Consultation
            <ul>
              <li>
                A User who is a consumer has the right to withdraw from the
                contract within 14 days from the date of its conclusion, without
                giving a reason in accordance with the principles specified in
                the regulations of the Service available at{" "}
                <a href="https://jat-med.com">jat-med.com</a>
              </li>
              <li>
                In addition, each party may terminate the Contract with
                immediate effect in the event of a material breach by the other
                party of the terms of the Contract, legal regulations or the
                rights of the other party or a third party.
              </li>
            </ul>
          </li>
          <li>
            Complaints
            <ul>
              <li>
                The Patient has the right to submit inquiries, comments and
                complaints in connection with the performance of the Online
                Consultation. Inquiries, comments and complaints regarding the
                operation of the Service and related technical issues should be
                submitted to the e-mail address{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                The deadline for considering the complaint is 14 working days,
                unless the relevant Organizational Regulations provide for a
                shorter period.
              </li>
              <li>
                The content of the complaint should include, at least:
                <ol>
                  <li>
                    data allowing for the identification of the Patient: User
                    login, Patient's name and surname, e-mail address, postal
                    address (for complaints submitted by post);
                  </li>
                  <li>specification of the subject of the complaint,</li>
                  <li>specification of any requests of the Patient,</li>
                  <li>
                    indication of the date of the event indicating the improper
                    performance of the Online Consultation Service.
                  </li>
                </ol>
              </li>
              <li>
                The complaint procedure does not exclude other rights to which
                the User is entitled under the provisions of law.
              </li>
            </ul>
          </li>
          <li>
            Processing of personal data
            <ul>
              <li>
                By ordering an Online Consultation, the User consents to the
                processing of his/her personal data for the purposes of
                performing the Service and settlements related to it, as well as
                for the purposes of the complaint procedure. Detailed principles
                and purposes of processing personal data collected during the
                User's use of the Service have been specified in the Information
                Clause in connection with the medical "online consultation"
                (health service) available at{" "}
                <a href="https://jat-med.com/policy/personal-data-processing">
                  jat-med.com/policy/personal-data-processing
                </a>
              </li>
            </ul>
          </li>
          <li>
            Final provisions
            <ul>
              <li>
                The Regulations are available on the Service website and at the
                Administrator's office. The Regulations - at the User's request
                sent by e-mail to the e-mail address:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>{" "}
                - will be provided to the User free of charge in electronic form
                to the e-mail address provided by the User.
              </li>
              <li>
                The Administrator reserves the right to make changes to the
                Regulations for important reasons, i.e.: changes in legal
                regulations, changes in payment and delivery methods - to the
                extent to which these changes affect the implementation of the
                provisions of these Regulations.
              </li>
              <li>
                The Administrator will inform the User of any change at least 14
                days in advance. Within 14 days of receiving information about
                the change, the User may refuse to accept it - by e-mail sent to{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
                . In such a case, the User Account will be closed on the day the
                change comes into effect.
              </li>
              <li>
                Orders placed on the Website that occurred before the change
                comes into effect are subject to the previous rules.
              </li>
              <li>
                In matters not regulated in the Regulations, the provisions of
                the regulations of the Website available at{" "}
                <a href="https://jat-med.com/policy/website-terms-of-use">
                  jat-med.com/policy/website-terms-of-use
                </a>{" "}
                shall apply, as well as generally applicable provisions of
                Polish law, in particular: the Civil Code; the Act on the
                provision of services by electronic means; the Act on consumer
                rights, the Act on the protection of personal data, the Act and
                the rights of the patient and the patient's advocate, the Act on
                medical activity and other relevant provisions of law.
              </li>
            </ul>
          </li>
        </ol>
      )}
      {i18next.resolvedLanguage === "uk" && (
        <ol>
          <li>
            Загальні положення
            <ul>
              <li>
                Положення стосується правил користування сервісом, що надається
                в електронному вигляді «Онлайн-консультація» (далі «Сервіс»),
                суб’єкта, що надає послугу, оплати праці, порядку розгляду скарг
                та обробки персональних даних особи, яка користується Сервісом.
              </li>
              <li>
                За бажанням Користувача або особи, яка починає користуватися
                Сервісом, Правила можуть бути надіслані в електронному вигляді
                на електронну адресу, вказану зацікавленою особою.
              </li>
            </ul>
          </li>
          <li>
            Визначення
            <ul>
              <li>
                <b>Адміністратор</b> – Jat-med sp. з зареєстрованим офісом у м.
                Бидгощ на вул. Крушинська 17, внесений до реєстру підприємців
                Національного судового реєстру, який веде Окружний суд у
                БИДГОЩІ, XIII КОМЕРЦІЙНИЙ ВІДДІЛ НАЦІОНАЛЬНОГО СУДОВОГО РЕЄСТРУ,
                під номером KRS 0001150821, NIP 9671483929, REGON 540725908.
              </li>
              <li>
                <b>Веб-сайт</b> – веб-сайт, керований Адміністратором
                jat-med.com, який реалізує Продукцію, надає Файли, що містять
                матеріали для лікування та профілактики захворювань
                опорно-рухового апарату, а також надає інші послуги, зазначені в
                Регламенті, в тому числі можливість укладення Договору на
                онлайн-консультації{" "}
                <a href="https://jat-med.com">jat-med.com</a>
              </li>
              <li>
                <b>Користувач</b> – фізична особа, юридична особа та
                організаційна одиниця без статусу юридичної особи, які
                відвідують Веб-сайт або використовують послуги Веб-сайту.
              </li>
              <li>
                <b>Пацієнт</b> – фізична особа, яка безпосередньо користується
                Онлайн-консультацією (в тому числі користувач).
              </li>
              <li>
                <b>Спеціаліст</b> – лікар, медична сестра, фізіотерапевт або
                інша особа, не зазначена вище, яка здійснює діяльність у сфері
                охорони здоров’я, надає медичні, медичні чи фельдшерські
                послуги.
              </li>
              <li>
                <b>Онлайн-консультація, також Сервіс</b> – послуга, яка
                надається Спеціалістом через Веб-сайт (надання консультації
                Спеціаліста) в електронному вигляді на умовах, визначених
                Регламентом.
              </li>
              <li>
                <b>Угода про онлайн-консультацію</b> – договір про надання
                послуг у формі онлайн-консультації, що укладається або
                укладається між Користувачем і Спеціалістом через Веб-сайт.
              </li>
              <li>
                <b>Регламент</b> – Регламент послуги, що надається в
                електронному вигляді «Он-лайн консультація»
              </li>
            </ul>
          </li>
          <li>
            Правила користування Онлайн-консультацією
            <ul>
              <li>
                Використання онлайн-консультації залежить від прийняття
                Пацієнтом цих Правил і згоди на можливість надання послуги
                онлайн-консультації спеціалістом.
              </li>
              <li>
                Види онлайн-консультацій:
                <ol>
                  <li>онлайн консультація;</li>
                  <li>порада або;</li>
                  <li>відомості про стан здоров'я.</li>
                </ol>
              </li>
              <li>
                Форми спілкування пацієнта з фахівцем:
                <ol>
                  <li>
                    чат – обмін короткими текстовими повідомленнями між
                    пацієнтом і спеціалістом,
                  </li>
                  <li>
                    телеконференція – передача голосу між пацієнтом і
                    спеціалістом,
                  </li>
                  <li>
                    відеоконференція – аудіовізуальна передача між пацієнтом і
                    спеціалістом,
                  </li>
                  <li>обмін електронними листами.</li>
                </ol>
              </li>
              <li>
                Вибір конкретного виду онлайн-консультації та форми спілкування
                залежить від індивідуальних домовленостей між пацієнтом та
                спеціалістом. Під час першої консультації. Спеціаліст може
                самостійно прийняти рішення про форму спілкування, враховуючи
                інтереси пацієнта, технічні умови та інші обставини, які можуть
                вплинути на хід і якість консультації.
              </li>
              <li>
                Онлайн-консультація – це медична послуга, яка може бути надана
                за умови надання пацієнтом наступних даних, необхідних для
                надання послуги та ведення медичної документації у зв’язку з
                нею, не пізніше дати запланованої онлайн-консультації:
                <ol>
                  <li>ім'я (імена) та прізвище (імена),</li>
                  <li>дата народження,</li>
                  <li>стать,</li>
                  <li>громадянство,</li>
                  <li>адреса проживання,</li>
                  <li>
                    у випадку неповнолітньої особи також відомості про законного
                    опікуна,
                  </li>
                  <li>
                    Номер PESEL, якщо його присвоєно, або інший реєстраційний
                    номер (за відсутності номера PESEL),
                  </li>
                  <li>
                    дані та документи щодо стану здоров’я Пацієнта та наданих
                    йому/їй послуг, необхідних для надання Послуги
                    онлайн-консультації,
                  </li>
                  <li>
                    адреса електронної пошти та контактний телефон (крім
                    стаціонарного).
                  </li>
                </ol>
              </li>
              <li>
                Дані, зазначені в пункті вище, можуть бути надіслані Пацієнтом
                на електронну адресу Адміністратора або надані особисто під час
                збору особистої інформації Спеціалістом.
              </li>
              <li>
                Надання онлайн-консультації спеціаліста не включає медичні
                послуги, які вимагають особистого прямого контакту з медичним
                спеціалістом.
              </li>
              <li>
                Онлайн-консультації мають обмежений характер і можуть не
                включати всі елементи дослідження. Лікар-консультант може
                направити пацієнта на стаціонарне лікування, якщо вважає це
                виправданим і необхідним.
              </li>
              <li>
                У медично обґрунтованих випадках Спеціаліст може вимагати у
                Пацієнта направлення на певний обсяг фізіотерапії.
              </li>
              <li>
                Спеціаліст веде медичну документацію відповідно до вказівок
                Національної палати фізіотерапевтів або інших органів
                професійного самоврядування відповідної медичної професії.
              </li>
              <li>
                Онлайн-консультації може передувати опитування щодо, зокрема:
                загальний стан здоров’я пацієнта, попередні лікування,
                результати аналізів, прийняті ліки або генетична схильність.
              </li>
              <li>
                Спеціаліст проводить огляд пацієнта, метою якого є оцінка стану
                його здоров'я та вибір відповідних методів лікування.
              </li>
            </ul>
          </li>
          <li>
            Умови надання онлайн-консультації
            <ul>
              <li>Онлайн-консультації надаються за окрему плату.</li>
              <li>
                Розмір плати вказано в прайс-листі Онлайн-послуг, доступному на
                веб-сайті Сервісу за адресою{" "}
                <a href="jat-med.com/appointment">jat-med.com/appointment</a>.
                Медичні послуги звільняються від ПДВ.
              </li>
              <li>
                Замовлення Послуги та оплата за неї можуть здійснюватися через
                систему, доступну на веб-сайті Сервісу, відповідно до принципів,
                викладених у положеннях про Послуги, доступних на сайті{" "}
                <a href="jat-med.com/policy/website-terms-of-use">
                  jat-med.com/policy/website-terms-of-use
                </a>
              </li>
              <li>Оплату необхідно здійснити до консультації.</li>
              <li>
                Послугу можна надати лише після запису на прийом відповідно до
                такої процедури:
                <ol>
                  <li>
                    Ви можете записатися на візит по телефону: для цього
                    зв'яжіться з адміністратором за телефоном{" "}
                    <a href="tel:+48518468678">+48518468678</a>; електронною
                    поштою, для цього зв’яжіться з Адміністратором, написавши на
                    адресу{" "}
                    <a href="mailto:jatmedcom2018@gmail.com">
                      jatmedcom2018@gmail.com
                    </a>{" "}
                    або за допомогою форм зв’язку, зазначених на сайті{" "}
                    <a href="https://jat-med.com">jat-med.com</a>.
                  </li>
                  <li>
                    зміна дати візиту безкоштовна до 18.00, наприклад, запис на
                    28.07.2024.
                  </li>
                  <li>
                    недотримання терміну скасування візиту, зазначеного в п.п b
                    вище, призводить до необхідності сплати 50% вартості послуги
                    у зв’язку з готовністю Спеціаліста надати Послугу;
                  </li>
                  <li>
                    Неповідомлення про скасування або бажання перенести
                    консультацію призведе до необхідності оплати 100% вартості
                    замовленої онлайн-консультації;
                  </li>
                  <li>
                    Про будь-які зміни дат прийому необхідно повідомляти по
                    телефону або електронною поштою за контактними даними,
                    розміщеними на сайті{" "}
                    <a href="https://jat-med.com">jat-med.com</a>.
                  </li>
                </ol>
              </li>
              <li>
                У призначений час Пацієнт повинен бути доступним за допомогою
                засобів зв’язку, погоджених з Адміністратором або Спеціалістом.
              </li>
            </ul>
          </li>
          <li>
            Технічні вимоги до використання Онлайн-консультації
            <ul>
              <li>
                Онлайн консультації надаються дистанційно, за такими каналами
                зв'язку: телефон, чат, відео.
              </li>
              <li>
                Спілкування може відбуватися на всіх електронних пристроях з
                доступом до Інтернету, які мають ефективний месенджер, який,
                залежно від характеру консультації, дозволяє чат,
                відеоконференцію чи телефонний зв’язок, а також дозволяє
                відтворювати та передавати текстові файли, фотографії та
                відеофайли.
              </li>
              <li>
                Технічні вимоги. Залежно від прийнятої форми спілкування:
                <ol>
                  <li>телефонна консультація: робочий телефон;</li>
                  <li>чат: месенджер з доступом до Інтернету;</li>
                  <li>
                    відеоконференція: комунікатор з виходом в Інтернет,
                    можливість аудіо-відео дзвінків, робочий мікрофон, робочі
                    колонки, робоча веб-камера;
                  </li>
                  <li>
                    бажані месенджери: Skype, Zoom, Whatsapp, Facebook
                    Messenger. Google Hangouts.
                  </li>
                </ol>
              </li>
              <li>
                Форма спілкування визначається фахівцем під час першого
                консультаційного контакту.
              </li>
              <li>
                Спеціаліст може відмовити в наданні онлайн-консультації, якщо
                вважає, що технічні умови або інші непередбачені обставини
                перешкоджають проведенню консультації ефективно.
              </li>
              <li>
                Адміністратор не несе відповідальності за будь-які технічні
                дефекти, пов'язані з пацієнтом, які перешкоджають ефективному
                завершенню онлайн-консультації.
              </li>
              <li>
                Якщо Пацієнт перериває з’єднання з технічних причин або інших
                непередбачених обставин, які не залежать від Адміністратора, це
                не звільняє Пацієнта від оплати Онлайн-консультації.
              </li>
              <li>
                Візит, перерваний Спеціалістом через технічні причини або
                непередбачені обставини з вини Адміністратора або Спеціаліста,
                вважається таким, що не відбувся. Пацієнт має право перенести
                пропущений візит на іншу дату або отримати повернення сплаченої
                за Консультацію вартості.
              </li>
            </ul>
          </li>
          <li>
            Принципи відповідальності
            <ul>
              <li>
                Адміністратор/спеціаліст і користувач/пацієнт зобов’язані
                відшкодувати будь-яку шкоду, заподіяну іншою стороною Угоди
                внаслідок невиконання або неналежного виконання ними своїх
                зобов’язань за Угодою чи Правилами, за винятком випадків, коли
                невиконання або неналежне виконання ними було наслідком
                обставин, за які ця сторона не несе відповідальності.
              </li>
              <li>
                Адміністратор/Спеціаліст не несе відповідальності за будь-яку
                неповну, неправдиву або невірну інформацію, надану
                Користувачем/Пацієнтом, особливо у разі надання даних третіх
                осіб без їх відома чи згоди. Користувач/Пацієнт несе виключну
                відповідальність за наслідки надання невірних, неповних,
                неправдивих, оманливих чи інших невірних даних.
              </li>
              <li>
                Адміністратор/Спеціаліст не несе відповідальності за наслідки
                використання Користувачем/Пацієнтом Сервісів у спосіб, що не
                відповідає Правилам.
              </li>
              <li>
                Адміністратор/Спеціаліст не несе відповідальності за будь-які
                збитки:
                <ol>
                  <li>
                    заподіяні третім сторонам у результаті використання послуг
                    Користувачами/Пацієнтами у спосіб, що не відповідає Правилам
                    або правовим положенням,
                  </li>
                  <li>
                    виникають внаслідок відсутності безперервності в наданні
                    Послуг внаслідок обставин, за які Адміністратор/Спеціаліст
                    не несе відповідальності, наприклад, внаслідок форс-мажорних
                    обставин, дій та бездіяльності третіх осіб, за винятком
                    осіб, за яких Адміністратор/Спеціаліст несе відповідальність
                    згідно із законом.
                  </li>
                  <li>
                    внаслідок надання Користувачем/Пацієнтом неправдивої або
                    неповної інформації під час реєстрації.
                  </li>
                </ol>
              </li>
            </ul>
          </li>
          <li>
            Розірвання договору про онлайн-консультацію
            <ul>
              <li>
                Користувач, який є споживачем, має право відмовитися від
                договору протягом 14 днів з дати його укладення без пояснення
                причин відповідно до принципів, викладених у правилах Сервісу,
                доступних на сайті <a href="https://jat-med.com">jat-med.com</a>
                .
              </li>
              <li>
                Крім того, кожна сторона може припинити дію Угоди з негайним
                набранням чинності у разі істотного порушення іншою стороною
                умов Угоди, правових норм або прав іншої сторони чи третьої
                сторони.
              </li>
            </ul>
          </li>
          <li>
            Скарги
            <ul>
              <li>
                Пацієнт має право подавати запити, коментарі та скарги у зв’язку
                з Онлайн-консультацією. Запитання, зауваження та скарги щодо
                роботи Сервісу та пов’язаних з ним технічних питань просимо
                надсилати на електронну адресу{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
              </li>
              <li>
                Строк розгляду скарги становить 14 робочих днів, якщо
                відповідним Положенням про Організацію не встановлено менший
                термін.
              </li>
              <li>
                Зміст скарги має містити щонайменше:
                <ol>
                  <li>
                    дані, що дозволяють ідентифікувати Пацієнта: Логін
                    користувача, ім’я та прізвище Пацієнта, адреса електронної
                    пошти, поштова адреса (для скарг, які надсилаються поштою);
                  </li>
                  <li>опис предмета скарги,</li>
                  <li>визначення будь-яких запитів Пацієнта,</li>
                  <li>
                    зазначення дати події, що свідчить про неналежне виконання
                    Послуги онлайн-консультації.
                  </li>
                </ol>
              </li>
              <li>
                Процедура оскарження не виключає інших прав, які Користувач має
                згідно із законом.
              </li>
            </ul>
          </li>
          <li>
            Обробка персональних даних
            <ul>
              <li>
                Замовляючи онлайн-консультацію, Користувач дає згоду на обробку
                його персональних даних для цілей надання Сервісу та пов’язаних
                з цим розрахунків, а також для цілей розгляду скарг. Детальні
                принципи та цілі обробки персональних даних, зібраних під час
                використання Користувачем Веб-сайту, вказані в Інформаційному
                пункті у зв’язку з медичною «онлайн-консультацією» (послугою
                охорони здоров’я), доступною на{" "}
                <a href=" https://jat-med.com/policy/personal-data-processing">
                  {" "}
                  jat-med.com/policy/personal-data-processing
                </a>
              </li>
            </ul>
          </li>
          <li>
            Прикінцеві положення
            <ul>
              <li>
                З Регламентом можна ознайомитись на Сайті та в офісі
                Адміністратора. Правила – за бажанням Користувача, надісланим
                електронною поштою на електронну адресу:{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>{" "}
                – будуть безоплатно надіслані Користувачеві в електронному
                вигляді на вказану Користувачем адресу електронної пошти.
              </li>
              <li>
                Адміністратор залишає за собою право вносити зміни в Правила з
                важливих причин, наприклад, зміни в правових положеннях, зміни в
                методах оплати та доставки – у тій мірі, в якій ці зміни
                впливають на виконання положень цих Правил.
              </li>
              <li>
                Адміністратор повідомить Користувача про будь-які зміни
                принаймні за 14 днів. Протягом 14 днів з моменту отримання
                інформації про зміну Користувач може відмовитися від її
                прийняття – електронною поштою на адресу{" "}
                <a href="mailto:jatmedcom2018@gmail.com">
                  jatmedcom2018@gmail.com
                </a>
                . У такому випадку обліковий запис користувача буде закрито в
                день, коли зміни набудуть чинності.
              </li>
              <li>
                Замовлення, розміщені на Веб-сайті до набрання чинності змінами,
                регулюються існуючими правилами.
              </li>
              <li>
                У питаннях, не врегульованих Правилами, застосовуються положення
                правил Веб-сайту, доступного на{" "}
                <a href="https://jat-med.com/policy/website-terms-of-use">
                  jat-med.com/policy/website-terms-of-use
                </a>
                , а також загальноприйнятні положення польського законодавства,
                зокрема: Цивільного кодексу; Акт про надання послуг засобами
                електронного зв'язку; Закон про права споживачів, Закон про
                захист персональних даних, Закон про права пацієнтів та
                омбудсмена пацієнтів, Закон про медичну діяльність та інші
                відповідні законодавчі положення.
              </li>
            </ul>
          </li>
        </ol>
      )}
    </article>
  );
};

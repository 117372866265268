import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { ReducerKeys } from "../types";
import { axiosBaseQuery, PathBuilder, RequestTypes } from "../../utils";
import { credential, IPaymentResponse } from "../../shared";

export const paymentApi = createApi({
  reducerPath: ReducerKeys.PAYMENTS_API_REDUCER_KEY,
  baseQuery: axiosBaseQuery({
    baseUrl: credential.apiUrl as string,
  }),
  tagTypes: [ReducerKeys.PAYMENTS_API_REDUCER_KEY],
  endpoints: (build) => ({
    createPayment: build.mutation<IPaymentResponse, { visitId: string }>({
      query: ({ visitId }) => ({
        url: PathBuilder.paymentApi.createPayment(),
        method: RequestTypes.post,
        data: { visitId },
      }),
      invalidatesTags: [ReducerKeys.PAYMENTS_API_REDUCER_KEY],
    }),
  }),
});

import facebook_icon from "./facebook_jat-med.svg";
import linkedin_icon from "./linkedin_jat-med.svg";
import twitter_icon from "./twitter_jat-med.svg";
import youtube_icon from "./youtube_jat-med.svg";
import location_icon from "./location_icon.svg";
import phone_icon from "./phone_icon.svg";
import mail_icon from "./mail_icon.svg";
import clock_icon from "./clock_icon.svg";
import neurosurgical_consultations_icon from "./neurosurgical_consultations_icon.svg";
import default_user from "./default_user.svg";
import logout_icon from "./logout_icon.svg";
import sort_icon from "./sort_icon.svg";
import doctor_small_icon from "./doctor_small_icon.svg";
import rehabilitator from "./rehabilitator.svg";
import rehabilitator_color from "./rehabilitator_color.svg";
import delete_icon from "./delete.svg";
import comment from "./comment.svg";
import visits from "./visits.svg";
import daily_visits from "./daily_visits.svg";
import visits_history from "./visits_history.svg";
import down_arrow from "./down-arrow.svg";
import visits_calendar from "./visits_calendar.svg";
import hamburger from "./hamburger.svg";
import hospital from "./hospital.svg";
import arrow_acc from "./arrow_acc.svg";
import table from "./table.svg";
import edit_pencil from "./edit_pencil.svg";
import schedule from "./schedule.svg";
import view_icon from "./ico-view.svg";
import applied_icon from "./applied_icon.svg";
import unpublished_icon from "./unpublished_icon.svg";
import arrow from "./arrow.svg";
import turn_on from "./turn-on.svg";
import stripe from "./stripe.svg";
import money_cash from "./money-cash.svg";
import credit_card_payment from "./credit-card-payment.svg";
import download from "./download.svg";
import searchIcon from "./search-icon.svg";
import arrowDown from "./arrow-down.svg";
import gift from "./gift-svg.svg";
import copy from "./copy.svg";
import eye from "./eye.svg";
import eye_off from "./eye-off.svg";

export {
  eye,
  eye_off,
  copy,
  gift,
  arrowDown,
  download,
  stripe,
  money_cash,
  credit_card_payment,
  facebook_icon,
  linkedin_icon,
  twitter_icon,
  youtube_icon,
  location_icon,
  phone_icon,
  mail_icon,
  clock_icon,
  neurosurgical_consultations_icon,
  default_user,
  logout_icon,
  sort_icon,
  doctor_small_icon,
  rehabilitator,
  rehabilitator_color,
  delete_icon,
  comment,
  visits,
  daily_visits,
  visits_history,
  down_arrow,
  visits_calendar,
  hamburger,
  hospital,
  arrow_acc,
  arrow,
  table,
  edit_pencil,
  schedule,
  view_icon,
  applied_icon,
  unpublished_icon,
  turn_on,
  searchIcon,
};

export interface IVoucherTable {
  id: string;
  key: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  expiredAt: string;
  currency: string;
  price: number;
  voucherPayment: Record<string, any>;
  actions?: string;
  attached?: string;
}

export const voucherStatuses = {
  active: "status.active",
  expired: "status.expired",
  deactivated: "status.deactivated",
  pending_payment: "status.pending_payment",
  used: "status.used",
};

export enum VOUCHER_STATUSES {
  active = "active",
  expired = "expired",
  deactivated = "deactivated",
  pending_payment = "pending_payment",
  used = "used",
}

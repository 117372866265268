export const visitApi = {
  createVisit: () => `/api/public/visits`,
  fetchPublicVisitList: () => `/api/public/visits`,
  fetchVisitList: () => `/api/portal/staff/visits`,
  fetchVisit: (id: string) => `/api/portal/staff/visits/${id}`,
  updateVisit: (id: string) => `/api/portal/staff/visits/${id}`,
  deleteVisit: (id: string) => `/api/portal/staff/visits/${id}`,
  cancelVisit: (id: string) => `/api/portal/staff/visits/${id}/cancel`,
  completeVisit: (id: string) => `/api/portal/staff/visits/${id}/complete`,
  markAsPaid: (id: string) => `/api/portal/staff/visits/${id}/mark-as-paid`,
  fetchPatientVisitList: () => `/api/portal/patient/visits`,
  fetchPatientVisit: (id: string) => `/api/portal/patient/visits/${id}`,
  cancelPatientVisit: (id: string) => `/api/portal/patient/visits/${id}/cancel`,
  completePatientVisit: (id: string) =>
    `/api/portal/patient/visits/${id}/complete`,
};
